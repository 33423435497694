import React, { useState, useEffect, useRef } from 'react';
import './assets/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';
import '../src/Styles/custom.css'
import '../src/Styles/ui_ux.css'
import '../src/Styles/stt_ui.css'
import '../src/components/sidebar/sidebar.scss'
import '../src/Styles/table.css'
import classNames from 'classnames';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { AppTopbar } from './AppTopbar';
import { AppConfig } from './AppConfig';
import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import { useDispatch, useSelector } from "react-redux";

import Login from './pages/Login';
import DCFInputEntryApproval from './pages/client/form/DCFInputEntryApproval';
import RFSubmittedPreview from './pages/RFSubmittedPreview';
import { API } from './components/constants/api_url';
import PasswordReset from './pages/passwordReset';
import cf_preview_view from './pages/cf_preview_view';
import cf_input_rf_entry from './pages/cf_input_rf_entry';

import APIServices from './service/APIService';

import DCFInputEntryPreview from './pages/client/form/DCFInputEntryPreview';
import RFInputEntryPreview from './pages/client/form/RFInputEntryPreview';
import DCFInputEntrySubmission from './pages/client/form/DCFInputEntrySubmission';
import DCFInputEntrySubmissionPreview from './pages/client/form/DCFInputEntrySubmissionPreview';
import RFInputEntrySubmission from './pages/client/form/RFInputEntrySubmission';
import SRFInputEntrySubmission from './pages/client/form/SRFInputEntrySubmission';
import SRFInputEntryPreview from './pages/client/form/SRFInputEntryPreview';
import SRFInputEntryApproval from './pages/client/form/SRFInputEntryApproval';
import SRFInputEntrySubmissionPreview from './pages/client/form/SRFInputEntrySubmissionPreview';
import ReporterOverdue from './STT/pages/user/ReporterOverdue';
import ReporterDueNow from './STT/pages/user/ReporterDueNow';
import ApproverOverdue from './STT/pages/user/ApproverOverdue';
import ReporterHistoric from './STT/pages/user/ReporterHistoric';
import ApproverHistoric from './STT/pages/user/ApproverHistoric';
import ApproverDueNow from './STT/pages/user/ApproverDueNow';
import UserHome from './STT/pages/user/UserDashboard';
import RFInputEntrySubmissionPreview from './pages/client/form/RFInputEntrySubmissionPreview';
import Sidebar from './components/sidebar/sidebar';
import { StickyContainer } from 'react-sticky';
import performance from './STT/pages/dashboard/performance';
import approver from './pages/approver/approver';
import QualitativeHistoric from './STT/pages/user/QualitativeHistoric';
import DCFInputEntryApprovalById from './pages/client/form/DCFInputEntryApprovalById';
import UserDashboard from './STT/pages/user/UserDashboard';
import QuantitativeReporterOverdue from './STT/pages/user/Reporter/QuantitativeReporterOverdue';
import QuantitativeReporterHistoric from './STT/pages/user/Reporter/QuantitativeReporterHistoric';
import QuantitativeReviewerOverall from './STT/pages/user/Reviewer/QuantitativeReviewerOverall';
import QuantitativeReviewerHistoric from './STT/pages/user/Reviewer/QuantitativeReviewerHistoric';
import QuantitativeNewSubmission from './pages/client/form/Reporter/QuantitativeNewSubmission';
import QuantitativePastSubmission from './pages/client/form/Reporter/QuantitativePastSubmission';
import QuantitativeReviewer from './pages/client/form/Reviewer/QuantitativeReviewer';
import QuantitativeApprover from './pages/client/form/Approver/QuantitativeApprover';
import QuantitativeReporterOverall from './STT/pages/user/Reporter/QuantitativeReporterOverall';
import { resetLoggedUserDetail } from './RTK/Login/userProfile';
import { resetOverallPendingData } from './RTK/Background/pendingData';
import overallSubmissionPreview from './STT/pages/user/overallSubmissionPreview';
import BugReport from './pages/BugReport';
import Snow from './STT/pages/user/Others/Snow';
import ApproverDB from './STT/pages/dashboard/ApproverDB';
import Certification from './STT/pages/dashboard/Certification';


import awsconfig from '../src/aws-export'; // Make sure this path is correct
import QuantitativeReporterOverallCopy from './STT/pages/user/Reporter/QuantitativeReporterOverall copy';
import STTInitiative from './STT/pages/dashboard/STTInitiative';
import BulkEntityImport from './STT/pages/user/Reporter/BulkEntityImport';
import ApproverDB_B4 from './STT/pages/dashboard/ApproverDB_B4';




const App = () => {
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(true);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const copyTooltipRef = useRef();
    const location = useLocation();
    const history = useHistory()
    const config = useSelector(state => state.user.userdetail)
    const dispatch = useDispatch()
    PrimeReact.ripple = true;


    const [stdlist, setSTDList] = useState([])
    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {

        if (localStorage.getItem('token') === null && !location.pathname.match('/reset-password-finish')) {
            history.push('/')
            sessionStorage.removeItem('temp')
        }
    }, [])

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
    }, [mobileMenuActive]);

    useEffect(() => {
        if (localStorage.getItem('token') !== null) {
            APIServices.get(API.Report_Name_Ones + `?filter=${encodeURIComponent(JSON.stringify({ 'include': ['reportNameTwos'] }))}`).then((res) => {
                setSTDList(res.data.filter((i) => { return i.reportNameTwos !== undefined && i.reportNameTwos.length !== 0 }))
            })
        }

        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }

    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        event.preventDefault();
    }

    const onMenuItemClick = async (event) => {
        dispatch(resetOverallPendingData())
        dispatch(resetLoggedUserDetail());
        localStorage.clear()
        sessionStorage.clear()
        //     const session = await Auth.currentSession();
        //  Auth.signOut().then(async(res)=>{
        //     const session = await Auth.currentSession();
        //     console.log(session)
        //  })
        history.push('/')

    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });
    let user_menu = [{ label: 'Home', to: '/client_user/homescreen', icon: 'pi pi-fw pi-home' },
    // {  label: 'Certification',icon:'material-icons' , gicon: 'card_membership',  to: '/certificate'},
    { label: 'Report an Issue', icon: 'mdi mdi-ladybug', to: '/report_issue', mdi: true },
    { label: 'Logout', icon: 'pi pi-sign-out ', to: '/logout', exit: true },
    ]
    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            {localStorage.getItem('token') &&
                <>
                    <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />
                    <AppTopbar layoutColorMode={layoutColorMode}
                        mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

                    <Sidebar logout={onMenuItemClick} onToggleMenuClick={onToggleMenuClick} menuitems={user_menu} />

                </>
            }
            <div className={localStorage.getItem('token') && "layout-main-container bg-smoke"}>
                <div className="layout-main" style={{ marginTop: 10 }}>

                    {/* <Route path="/" exact render={() => <Dashboard colorMode={layoutColorMode} location={location} />} /> */}
                    <Route exact path="/" component={Login} />
                    <Route path='/reset-password-finish' component={PasswordReset} />


                    {localStorage.getItem('token') &&
                        <>
                            <Route path='/client_user/homescreen' component={UserDashboard} />

                            <Route path='/approver' component={approver} />

                            {/* DCF */}
                            <Route path='/data_input_new/:id' component={DCFInputEntrySubmission} />
                            <Route path='/data_input_approve/:id' component={DCFInputEntryApproval} />
                            <Route path='/submission_preview/:id' component={DCFInputEntrySubmissionPreview} />
                            <Route path='/data_input_approve_new/:id' component={DCFInputEntryApprovalById} />

                            <Route path='/dcf_preview/:id' component={DCFInputEntryPreview} />
                            {/* RF */}
                            <Route path='/rf_input_entry/:id' component={RFInputEntrySubmission} />

                            <Route path='/rf_submission_preview/:id' component={RFInputEntrySubmissionPreview} />
                            <Route path='/rf_preview/:id' component={RFInputEntryPreview} />
                            {/* CF */}
                            <Route path='/cf_data_input/:id' component={SRFInputEntrySubmission} />
                            <Route path='/cf_preview/:id' component={SRFInputEntryPreview} />
                            <Route path='/cf_input_approve/:id' component={SRFInputEntryApproval} />
                            <Route path='/cf_submission_preview/:id' component={SRFInputEntrySubmissionPreview} />
                            <Route path='/cf_preview_view' component={cf_preview_view} />
                            <Route path='/cf_rf_input_entry/:id' component={cf_input_rf_entry} />

                            {/* STT */}
                            <Route path='/reporter_overdue/:id' component={ReporterOverdue} />
                            <Route path='/reporter_duenow/:id' component={ReporterDueNow} />
                            <Route path='/approver_duenow/:id' component={ApproverDueNow} />
                            <Route path='/approver_overdue/:id' component={ApproverOverdue} />
                            <Route path='/reporter_historic/:id' component={ReporterHistoric} />
                            <Route path='/approver_historic/:id' component={ApproverHistoric} />
                            <Route path='/qualitative_historic' component={QualitativeHistoric} />


                            {/* New Qunatitative Form */}
                            <Route path='/quantitative_reporter_overdue_old' component={QuantitativeReporterOverdue} />
                            <Route path='/quantitative_reporter_overdue' component={QuantitativeReporterOverallCopy} />
                            <Route path='/quantitative_bulk_import' component={BulkEntityImport} />

                            <Route path='/quantitative_reporter_historic' component={QuantitativeReporterHistoric} />
                            <Route path='/quantitative_reviewer_overdue' component={QuantitativeReviewerOverall} />

                            <Route path='/quantitative_reviewer_historic' component={QuantitativeReviewerHistoric} />
                            <Route path='/data_input_newer/:id' component={QuantitativeNewSubmission} />
                            <Route path='/data_input_past/:id/:id2' component={QuantitativePastSubmission} />
                            <Route path='/data_input_reviewer/:id/:id2' component={QuantitativeReviewer} />
                            <Route path='/data_input_view/:id/:id2' component={overallSubmissionPreview} />
                            <Route path='/report_issue' component={Snow} />
                            <Route path='/approver_indicator' component={ApproverDB} />
                            <Route path='/approver_db' component={ApproverDB_B4} />

                            <Route path='/certificate' component={Certification} />
                            <Route path='/initiatives' component={STTInitiative} />
                        </>
                    }



                    {/* {localStorage.getItem('token') !== null && <AppFooter layoutColorMode={layoutColorMode} />} */}
                </div>
            </div>

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>

        </div>
    );

}

export default App;

