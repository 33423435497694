import Axios from "axios";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { API } from "../../../components/constants/api_url";

import { useHistory } from "react-router-dom";
import { TabMenu } from 'primereact/tabmenu';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column"
import { Tooltip } from "primereact/tooltip";
import Swal from 'sweetalert2'
import APIServices from "../../../service/APIService";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { MultiSelect } from "primereact/multiselect";
import { getLocationData } from "../../../components/BGHF/helper";
const { DateTime } = require('luxon')


const UserDashboard = () => {

    const login_data = useSelector((state) => state.user.userdetail)
    const admin_data = useSelector((state) => state.user.admindetail)
    const allRoles = useSelector((state) => state.user.allRoles)

    const [certcount,setCertCount] = useState(0)
    const navigate = useHistory()
    const [submittedQuantitative, setSubmittedQuantitative] = useState([])
    const [reviewQuantitative, setReviewQuantitative] = useState([])
    const [approveQuantitative, setApproveQuantitative] = useState([])
    const [rawsitelist, setRawSitelist] = useState([])
    const [present, setPresent] = useState([])
    const [past, setPast] = useState([])
    const [future, setFuture] = useState([])
    const [newsfeed, setNewsFeed] = useState([])
    const forceUpdate = useForceUpdate()
    const frequency_list = [{ name: 'Monthly', id: 1 }, { name: 'Bi-Monthly', id: 2 }, { name: 'Quartely', id: 3 }, { name: 'Annually', id: 4 }, { name: 'Bi-Annually', id: 5 }, { name: 'Undefined', id: 6 }]
    useEffect(() => {

        let uriString = {
            "include": [{ "relation": "locationTwos", "scope": { "include": [{ "relation": "locationThrees" }] } }]

        }
        let uriString2 = {
            "include": [{ "relation": "newTopics", "scope": { "include": [{ "relation": "newMetrics", "scope": { "include": [{ "relation": "newDataPoints" }] } }] } }]


        }
        let Overall = API.Categories + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`;
        const promise0 = APIServices.get(API.DCF)
        const promise1 = APIServices.get(API.QN_Submit_UP(admin_data.id))
        const promise2 = APIServices.get(API.LocationOne_UP(admin_data.id) + `?filter=${encodeURIComponent(JSON.stringify(uriString))}`)
        const promise3 = APIServices.get(API.DCF_Entity_UP(admin_data.id))
        const promise4 = APIServices.get(API.DF_Entity_UP(admin_data.id))
        const promise5 = APIServices.get(API.SRF_Entity_UP(admin_data.id))
        const promise6 = APIServices.get(API.DCF_Entity_User_UP(admin_data.id))
        const promise7 = APIServices.get(API.DF_Entity_User_UP(admin_data.id))
        const promise8 = APIServices.get(API.SRF_Entity_User_UP(admin_data.id))
        const promise9 = APIServices.get(
            API.AssignDCFClient_UP(admin_data.id)
        );
        const promise10 = APIServices.get(
            Overall
        );
        const promise11 = APIServices.get(API.NewsCirculate_UP(admin_data.id))
        const promise12 = APIServices.get(API.Client_Certification_UP(admin_data.id)+`?filter%5Bwhere%5D%5Bcreated_by%5D=${login_data.id}`)
        const promise13 = APIServices.get(API.GetRole_Up(admin_data.id))
        Promise.all([promise0, promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8, promise9, promise10, promise11,promise12,promise13]).then((values) => {
            let newsData = values[11].data.filter(i => i.status === 2)
           let  shapedSite_ = getLocationData(values[2].data, values[13].data.filter(i => i.user_id === login_data.id), [17]).map((item) => {
                if (item.locationTwos) {
                    item.locationTwos = item.locationTwos.filter(
                        (locationTwo) =>
                            locationTwo.locationThrees &&
                            locationTwo.locationThrees.length > 0
                    );
                }
                return item;
            })
                .filter((item) => item.locationTwos && item.locationTwos.length > 0);
            setCertCount(values[12].data.filter(i => getCoverageText_(i.applicability,shapedSite_)).length)
            newsData.forEach((nws) => {
                if (nws.expiryDate) {
                    nws.expired =  isSameOrFutureLocalDate(nws.expiryDate) ? false : true
                } else {
                    nws.expired = false
                }
            })

            setNewsFeed(values[11].data)

            let quantitative_submitted = values[1].data, qn_submit_list = [], qn_review_list = [], qn_approve_list = [], dcf_list = values[0].data, quantitative_ass_list = values[6].data, qualitative_ass_list = values[7].data, srf_ass_list = values[8].data
            let quantitative_entity_list = values[3].data, qualitative_entity_list = values[4].data, srf_entity_list = values[5].data

            let curated_dcf_ids = []
            if (values[9].data && values[9].data.length > 0) {
                values[10].data.forEach((cat) => {
                    if (cat.newTopics !== undefined) {

                        cat.newTopics.forEach((topic) => {

                            if (topic.newMetrics !== undefined && values[9].data[0].topic_ids.includes(topic.id) && (topic.tag === null || parseFloat(topic.tag) === admin_data.id)) {

                                topic.newMetrics.forEach((metric) => {
                                    if (Array.isArray(metric.data1) && values[9].data[0].metric_ids.includes(metric.id) && metric.data1[0].type !== undefined && metric.data1[0].type === 0 && (metric.tag === null || metric.tag === admin_data.id)) {
                                        if (metric.newDataPoints !== undefined) {
                                            metric.newDataPoints.forEach((dp) => {
                                                if (Array.isArray(dp.data1) && dp.data1.length !== 0 && dp.data1[0].datasource !== null && typeof dp.data1[0].datasource === 'number') {
                                                    let dcf_index = values[0].data.findIndex(i => i.id === dp.data1[0].datasource)
                                                    if (!curated_dcf_ids.includes(dp.data1[0].datasource) && dcf_index !== -1) {

                                                        if (values[0].data[dcf_index].tags === null || !values[0].data[dcf_index].tags.length || values[0].data[dcf_index].tags.includes(admin_data.id)) {
                                                            curated_dcf_ids.push(dp.data1[0].datasource)
                                                        }

                                                    }
                                                }
                                            })
                                        }
                                    }
                                })
                            }
                        })
                    }


                })
            }

            const shapedSite = values[2].data.map(item => {
                if (item.locationTwos) {
                    item.locationTwos = item.locationTwos.filter(locationTwo =>
                        locationTwo.locationThrees && locationTwo.locationThrees.length > 0
                    );
                }
                return item;
            }).filter(item => item.locationTwos && item.locationTwos.length > 0)
            
            let filtered_qn_ass = quantitative_ass_list.filter(i => dcf_list.map(j => j.id).includes(i.dcfId) && curated_dcf_ids.includes(i.dcfId) && checkEnity(i, quantitative_entity_list, shapedSite, 'dcfId'))
            console.log(filtered_qn_ass)
            // Quantitative
            filtered_qn_ass.forEach((item) => {
                if (item.reporter_ids.includes(login_data.id)) {
                    item.dcf_ = dcf_list.filter((k) => { return k.id === item.dcfId })[0]
                    item.frequency_ = frequency_list.filter((k) => { return k.id === item.frequency })[0]
                    let st_date = DateTime.fromISO(item.start_date, { zone: 'utc' }).toLocal(), ed_date = DateTime.utc().toLocal()
                    if (typeof item.end_date === 'string') {
                        ed_date = DateTime.fromISO(item.end_date, { zone: 'utc' }).toLocal()
                    }
                    getQuantitativeSubmissionData(st_date, ed_date, item.frequency, item, quantitative_submitted)
                }
            })
            console.log(quantitative_submitted)
            quantitative_submitted.forEach((item) => {
                let filtered_qn_ass_index = filtered_qn_ass.findIndex(i => { console.log(i.id, item.entityUserAssId, i.entityAssId, item.entityAssId); return (i.id === item.entityUserAssId && i.entityAssId === item.entityAssId) })

                if (filtered_qn_ass_index !== -1) {
                    console.log(filtered_qn_ass[filtered_qn_ass_index], filtered_qn_ass[filtered_qn_ass_index].reporter_ids, login_data.id, filtered_qn_ass[filtered_qn_ass_index].reporter_ids.includes(login_data.id))
                    if (filtered_qn_ass[filtered_qn_ass_index].reporter_ids.includes(login_data.id)) {
                        console.log(item)
                        qn_submit_list.push(item)
                    }
                    if (filtered_qn_ass[filtered_qn_ass_index].reviewer_ids.includes(login_data.id)) {
                        qn_review_list.push(item)
                    }
                    if (filtered_qn_ass[filtered_qn_ass_index].approver_ids.includes(login_data.id)) {
                        qn_approve_list.push(item)
                    }
                }
            })
            setSubmittedQuantitative(qn_submit_list)
            setReviewQuantitative(qn_review_list)
            setApproveQuantitative(qn_approve_list)
        })

    }, [])
    const isSameOrFutureLocalDate = (utcDateString) => {
     // Parse the given UTC date string
     const givenDate = DateTime.fromISO(utcDateString, { zone: 'utc' }).toLocal();
console.log(givenDate.toFormat('dd-LLL-yyyy'))
     // Get the current local date
     const currentLocalDate = DateTime.local();
 
     // Compare the dates ignoring the time part
     return givenDate.startOf('day') >= currentLocalDate.startOf('day');
    };
    const checkEnity = (rowData, entity_list, rawsite, obj) => {
        let index = entity_list.findIndex(k => k[obj] === rowData[obj])
        if (index !== -1) {
            let entity = entity_list[index]
            console.log(entity)
            if (rowData.level === 0) {
                return entity.tier0_ids.includes(0)
            } else if (rowData.level === 1) {
                return entity.tier1_ids.includes(rowData.locationId) && getCoverageText(rowData, rawsite)
            } else if (rowData.level === 2) {
                return entity.tier2_ids.includes(rowData.locationId) && getCoverageText(rowData, rawsite)
            } else if (rowData.level === 3) {
                return entity.tier3_ids.includes(rowData.locationId) && getCoverageText(rowData, rawsite)
            }
        } else {
            return false
        }

    }
    const getCoverageText_ = (rowData,rawsitelist) => {
        console.log(rowData)
        let text = ''
        if (rowData.country === 0 && rowData.city === 0 && rowData.location === 0) {
            text = 'Corporate'
        } else if (rowData.country !== 0 && rowData.city === 0 && rowData.location === 0) {
            let country_index = rawsitelist.findIndex(i => i.id === rowData.country)
            console.log(country_index)
            if (country_index !== -1) {
                text = rawsitelist[country_index].name
            }
        } else if (rowData.country !== 0 && rowData.city !== 0 && rowData.location === 0) {
            let city_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.city_id === rowData.city })
            if (city_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[city_index].city_name
            }
        } else {
            let site_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.site_id === rowData.location })
            if (site_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[site_index].site_name
            }
        }


        return text
    }
    const getCoverageText = (rowData, rawsitelist) => {
        let text = ''
        console.log(rowData)
        if (rowData.level === 0) {
            text = 'Corporate'
        } else if (rowData.level === 1) {
            let country_index = rawsitelist.findIndex(i => i.id === rowData.locationId)
            if (country_index !== -1) {
                text = rawsitelist[country_index].name
            }
        } else if (rowData.level === 2) {
            let city_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.city_id === rowData.locationId })
            if (city_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[city_index].city_name
            }

        } else if (rowData.level === 3) {
            let site_index = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } }))).findIndex((i) => { return i.site_id === rowData.locationId })
            if (site_index !== -1) {
                text = rawsitelist.flatMap(i => i.locationTwos.flatMap(j => j.locationThrees.map((k) => { return { site_id: k.id, site_name: k.name, city_id: j.id, city_name: j.name, country_id: i.id, country_name: i.name } })))[site_index].site_name
            }
        }
        return text
    }
    const compareArrays = (a, b) => {


        return JSON.stringify(a) === JSON.stringify(b);
    };
    const splitArray = (array, chunkSize) => {


        return array.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / chunkSize)

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
            }

            resultArray[chunkIndex].push(item)

            return resultArray
        }, [])
    }
    const getRPLuxon = (months) => {
        if (months.includes('to')) {
            let startDate = DateTime.fromFormat(months.split('to')[0].trim(), 'LLL-yyyy')
            let endDate = DateTime.fromFormat(months.split('to')[1].trim(), 'LLL-yyyy')
            let rp = []
            while (startDate <= endDate) {
                rp.push(startDate.toFormat('LL-yyyy'));
                startDate = startDate.plus({ months: 1 })
            }
            return rp
        } else {
            return [DateTime.fromFormat(months, 'LLL-yyyy').toFormat('LL-yyyy')]
        }
    }
    const checkSubmission = (dcf, level, locationId, rp, old) => {
        // let rps = getRP(rp)
        let rps = getRPLuxon(rp)

        let result = {}
        let loc = JSON.parse(JSON.stringify(old))

        let index = loc.findIndex((i) => {
            return compareArrays(i.reporting_period, rps) && i.level === level && i.locationId === locationId && i.dcfId === dcf.id && i.type === 0
        })
        let index2 = loc.findIndex((i) => {
            return compareArrays(i.reporting_period, rps) && i.level === level && i.locationId === locationId && i.dcfId === dcf.id && (i.type !== 0)
        })

        if (index === -1) {

            result = { result: false, data: index2 === -1 ? true : false }
        } else {
            result = {
                result: true, data: loc[index], list: loc.filter((i) => {
                    return compareArrays(i.reporting_period, rps) && i.level === level && i.locationId === locationId && i.dcfId === dcf.id && i.type === 0
                })
            }
        }

        return result
    }
    function updateImgTags(htmlString) {
        // Regular expression to match <p> tags containing <img> tags
        const regex = /<p>(\s*<img[^>]*>\s*)<\/p>/g;
    
        // Replace matched <p> tags with the desired class and update <img> tag width
        return htmlString.replace(regex, (match, imgTag) => {
            // Update the <img> tag with width="30%"
            const updatedImgTag = imgTag.replace(/<img([^>]*)>/, '<img$1 width="30%">');
    
            // Return the updated <p> tag with class and updated <img> tag
            return `<p class="flex justify-content-center">${updatedImgTag}</p>`;
        });
    }
    const getQuantitativeSubmissionData = (startDate, endDate, frequency, item, old) => {

        var betweenMonths = [];
        let type = 0, past_ = [], future_ = []

        past_ = JSON.parse(JSON.stringify(past))

        // future_ = JSON.parse(JSON.stringify(future))

        if (Math.round(startDate.diff(DateTime.utc().startOf('month'), 'days').days).toFixed(0) === 0) {
            let endDate_ = startDate

            let endDate__ = DateTime.utc().toLocal()
            if (frequency === 1) {

                let check = checkSubmission(item.dcf_, item.level, item.locationId, endDate__.toFormat('LLL-yyyy'), old)

                if (check.result) {

                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: 0, level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                } else {
                    if (check.data) {

                        future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: 0, level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })
                    }

                }
            } else if (frequency === 6) {
                let check = checkSubmission(item.dcf_, item.level, item.locationId, endDate__.toFormat('LLL-yyyy'), old)

                if (check.result) {


                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(endDate__, 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                } else {
                    if (check.data) {

                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: endDate__.toFormat('LLL-yyyy'), duedate: endDate__.toFormat('LLL-yyyy'), overdue: 0, level: item.level, locationId: item.locationId, type: 6, company_id: admin_data.id, frequency })
                    }

                }


            }

        }
        else if (Math.round(startDate.diff(DateTime.utc().startOf('month'), 'days').days) < 31) {
            var date = startDate
            console.log(startDate.diff(endDate.startOf('month'), 'days').days)


            let endDate__ = DateTime.utc().toLocal()
            while (date <= endDate) {

                betweenMonths.push(date.toFormat('LLL-yyyy'));
                date = date.plus({ months: 1 })

            }
            console.log(betweenMonths, item.id)
            if (frequency === 1) {
                console.log(splitArray(betweenMonths, 1), 'SP')
                splitArray(betweenMonths, 1).forEach((months, ind) => {
                    console.log(endDate__.month === DateTime.fromFormat(months[0], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[0], 'LLL-yyyy').year)
                    if (endDate__.month === DateTime.fromFormat(months[0], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[0], 'LLL-yyyy').year) {
                        let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0], old)

                        if (check.result) {

                            future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                        } else {
                            if (check.data) {
                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                            }

                        }


                    } else {

                        if (Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'months').months) === 1) {

                            // if (endDate__ <= moment(months[0]).add(1, 'month').add(4, 'days')) {
                            let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0], old)


                            if (check.result) {


                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                }

                            }


                        } else {
                            let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0], old)
                            console.log(check, months[0])
                            if (check.result) {


                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                }

                            }
                        }
                    }

                })
            } else if (frequency === 2) {

                splitArray(betweenMonths, 2).forEach((months) => {
                    if (months.length === 2) {
                        if (endDate__.month === DateTime.fromFormat(months[1], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[1], 'LLL-yyyy').year) {
                            let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[1], old)
                            if (check.result) {

                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })


                                }

                            }


                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[1]).add(1, 'month').add(4, 'days')) {
                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[1], old)
                                if (check.result) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })


                                    }

                                }


                            } else {
                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[1], old)

                                if (check.result) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[1], duedate: DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[1], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 3) {

                splitArray(betweenMonths, 3).forEach((months) => {
                    if (months.length === 3) {

                        if (endDate__.month === DateTime.fromFormat(months[2], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[2], 'LLL-yyyy').year) {
                            let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[2], old)

                            if (check.result) {

                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                }

                            }


                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[2]).add(1, 'month').add(4, 'days')) {
                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[2], old)

                                if (check.result) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                    }

                                }



                            } else {

                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[2], old)

                                if (check.result) {
                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        console.log(item.site, item.dcf_, months[0] + ' to ' + months[2])
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[2], duedate: DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[2], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 4) {
                splitArray(betweenMonths, 12).forEach((months) => {
                    if (months.length === 12) {
                        if (endDate__.month === DateTime.fromFormat(months[11], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[11], 'LLL-yyyy').year) {
                            let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[11], old)

                            if (check.result) {

                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                            } else {
                                if (check.data) {
                                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                }

                            }


                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[11]).add(1, 'month').add(4, 'days')) {

                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[11], old)

                                if (check.result) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                    }

                                }


                            } else {
                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[11], old)

                                if (check.result) {

                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })

                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[11], duedate: DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[11], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 5) {
                splitArray(betweenMonths, 6).forEach((months) => {
                    if (months.length === 6) {
                        if (endDate__.month === DateTime.fromFormat(months[5], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[5], 'LLL-yyyy').year) {
                            let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[5], old)

                            if (check.result) {


                                future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                            } else {
                                if (check.data) {
                                    future_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                }

                            }


                        } else {
                            if (Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy'), 'months').months) === 1) {

                                // if (endDate__ <= moment(months[5]).add(1, 'month').add(4, 'days')) {
                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[5], old)

                                if (check.result) {


                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                    }

                                }


                                // } else {
                                //     let check = checkSubmission(item.dcf_, item.level,item.locationId, moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), old)

                                //     if (check.result) {


                                //         past_.push({ dcf: item.dcf_,entityAssId:item.entityAssId,entityUserAssId:item.id, reporting_period: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), level:item.level,locationId:item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                //     } else {
                                //         if (check.data) {
                                //             past_.push({ dcf: item.dcf_,entityAssId:item.entityAssId,entityUserAssId:item.id, reporting_period: moment(months[0]).format('MMM-YYYY') + ' to ' + moment(months[5]).format('MMM-YYYY'), duedate: moment(months[5]).add(1, 'month').format('MMM-YYYY'), overdue: endDate__.diff(moment(months[5]).add(1, 'month'), 'days'), level:item.level,locationId:item.locationId, company_id: admin_data.id, frequency })

                                //         }

                                //     }
                                // }
                            } else {
                                let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0] + ' to ' + months[5], old)

                                if (check.result) {


                                    past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: check.data.response, id: check.data.id, reject: check.data.reject, draft: check.data })
                                } else {
                                    if (check.data) {
                                        past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0] + ' to ' + months[5], duedate: DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[5], 'LLL-yyyy').plus({ months: 1 }), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                                    }

                                }
                            }
                        }
                    }
                })
            } else if (frequency === 6) {
                splitArray(betweenMonths, 1).forEach((months, ind) => {

                    if (endDate__.month === DateTime.fromFormat(months[0], 'LLL-yyyy').month && endDate__.year === DateTime.fromFormat(months[0], 'LLL-yyyy').year) {
                        let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0], old)

                        if (check.result) {
                            past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                            check.list.forEach((list) => {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: list.response, id: list.id, reject: list.reject, draft: list })

                            })

                        } else {
                            if (check.data) {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                            }

                        }


                    } else {
                        let check = checkSubmission(item.dcf_, item.level, item.locationId, months[0], old)

                        if (check.result) {

                            check.list.forEach((list) => {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: DateTime.fromFormat(months[0], 'LLL-yyyy').plus({ months: 1 }).toFormat('LLL-yyyy'), overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency, response: list.response, id: list.id })

                            })


                        } else {
                            if (check.data) {
                                past_.push({ standard: item.standard === null ? 0 : item.standard, dcf: item.dcf_, entityAssId: item.entityAssId, entityUserAssId: item.id, reporting_period: months[0], duedate: months[0], overdue: Math.round(endDate__.diff(DateTime.fromFormat(months[0], 'LLL-yyyy'), 'days').days), level: item.level, locationId: item.locationId, company_id: admin_data.id, frequency })

                            }

                        }


                    }

                })
            }

        }
        console.log(past_)

        setPast((prev) => ([...prev, ...past_]))

        return { type, months: betweenMonths }
    }
    let notification = [{ title: 'STT GDC Issues S$450M sustainability-linked perpetual securities to drive sustainability initiatives and fuel global expansion', time: '01:45 PM', date: '11-11-2023' },
    { title: 'STT GDC Issues S$450M sustainability-linked perpetual securities to drive sustainability initiatives and fuel global expansion', time: '01:45 PM', date: '11-11-2023' },
    { title: 'STT GDC Issues S$450M sustainability-linked perpetual securities to drive sustainability initiatives and fuel global expansion', time: '01:45 PM', date: '11-11-2023' },
    { title: 'STT GDC Issues S$450M sustainability-linked perpetual securities to drive sustainability initiatives and fuel global expansion', time: '01:45 PM', date: '11-11-2023' },
    { title: 'STT GDC Issues S$450M sustainability-linked perpetual securities to drive sustainability initiatives and fuel global expansion', time: '01:45 PM', date: '11-11-2023' }
    ]
    function toggleReadMore() {
        const contentDiv = document.getElementById('readmore');
        const button = document.getElementById('toggleButton');
        if (contentDiv.classList.contains('expanded')) {
            contentDiv.classList.remove('expanded');
            button.textContent = 'Read More';
        } else {
            contentDiv.classList.add('expanded');
            button.textContent = 'Read Less';
        }
    }
    const getRoles = (info) => {
        if (info.role !== undefined || info.role !== null) {
            return Object.entries(info.role).filter(i => i[1] === true).map(i => i[0]).join('/ ')
        } else {
            return 'Unknown'
        }
    }
    return (
        <div className="bg-smoke font-lato" >
            <div className="col-12" >

                <div>
                    <div className="col-12 " style={{
                        justifyContent: 'flex-start'
                    }}>
                        <div className="flex align-items-center">  <span className="text-big-one"> Welcome {login_data.information.empname} !   </span> <span className="ml-1">{`<${login_data.email}>`} </span>   <Tag className="ml-3 p-tag-blue" > {getRoles(login_data.information)} </Tag> </div>
                        <label className="text-big-one clr-navy flex fw-7 flex justify-content-start"> My Actions
                        </label>
                        <label className="text-micro clr-navy flex  justify-content-start">Your one-stop portal to view and take action on all your sustainability data submission and approvals.</label>

                    </div>
                    {
                        <div className="col-12  grid ">
                            <div className='col-3  user-dashboard-card p-card' onClick={() => { navigate.push({ pathname: '/quantitative_reporter_overdue' }) }} style={{ borderBottom: '5px solid red' }} >
                                <div>   <label className="fs-30 fw-7">{past.length}</label> </div>
                                <div> <label className="fs-18 fw-5 ">Quantitative Submissions </label>
                                </div><div>
                                    <label className="fs-12 clr-gray"> Listing of all quantitative submissions from areas under your control</label> </div>
                                <div>   <label className="fs-14 fw-7"> Go to Quantitative Submissions <i className="pi pi-arrow-up-right fs-14" /> </label> </div>
                            </div>
                            <div className='col-3  user-dashboard-card p-card' onClick={() => { navigate.push({ pathname: '/quantitative_reviewer_overdue' }) }} style={{ borderBottom: '5px solid skyblue' }} >
                                <div>   <label className="fs-30 fw-7">{reviewQuantitative.filter(i => i.type === 1).length}</label> </div>
                                <div> <label className="fs-18 fw-5 ">Quantitative Reviews </label>
                                </div><div>
                                    <label className="fs-12 clr-gray"> Quantitative submissions requiring your review</label> </div>
                                <div>   <label className="fs-14 fw-7 "> Go to Quantitative Review <i className="pi pi-arrow-up-right fs-14" /> </label> </div>
                            </div>
                            <div className='col-3  user-dashboard-card p-card' onClick={() => { navigate.push({ pathname: '/approver_indicator' }) }} style={{ borderBottom: '5px solid #FFC300' }} >
                                <div>   <label className="fs-30 fw-7">0</label> </div>
                                <div> <label className="fs-18 fw-5 ">Quantitative Approvals</label>
                                </div><div>
                                    <label className="fs-12 clr-gray"> Quantitative submissions requiring your approval</label> </div>
                                <div>   <label className="fs-14 fw-7 "> Go to Quantitative Approval <i className="pi pi-arrow-up-right fs-14" /> </label> </div>
                            </div>
                          {allRoles.includes(17) &&  <div className='col-3  user-dashboard-card p-card' onClick={() => { navigate.push({ pathname: '/certificate' }) }} style={{ borderBottom: '5px solid #FFC300' }} >
                                <div>   <label className="fs-30 fw-7">{certcount}</label> </div>
                                <div> <label className="fs-18 fw-5 ">Certification</label>
                                </div><div>
                                    <label className="fs-12 clr-gray"> Certification Record </label> </div>
                                <div>   <label className="fs-14 fw-7 "> Go to Certification <i className="pi pi-arrow-up-right fs-14" /> </label> </div>
                            </div>}
                            {allRoles.includes(18) &&  <div className='col-3  user-dashboard-card p-card' onClick={() => { navigate.push({ pathname: '/initiatives' }) }} style={{ borderBottom: '5px solid #FFC300' }} >
                                <div>   <label className="fs-30 fw-7">{}</label> </div>
                                <div> <label className="fs-18 fw-5 ">Initiative</label>
                                </div><div>
                                    <label className="fs-12 clr-gray">Manage Environment ,Social and R&D Initiative </label> </div>
                                <div>   <label className="fs-14 fw-7 "> Go to Initiative <i className="pi pi-arrow-up-right fs-14" /> </label> </div>
                            </div>}
                        </div>
                    }
                </div>
            </div>
            <div className="col-12">

                <div style={{ background: '#41AA950D' }}>
               
                <label className="fw-7 fs-16 clr-gray-900 flex p-3" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }} >Recent updates</label>
                 {/* <div className="col-12 grid m-0" style={{ boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}>
                    <div className="col-8">
                    <label className="fw-7 fs-16 clr-gray-900 flex p-3"  >Recent updates</label>

                    </div>
                 <div className="col-4 grid m-0 ">
                 <div className="col-6 flex justify-content-center" >Active</div>
                 <div className="col-6 flex justify-content-center" >Archive</div>
                 </div>
                 

                 </div> */}
                 
                    
                    <div style={{ overflow: 'auto', height: 450, marginTop: 5 }}>
                        {newsfeed.sort((a,b)=>{
                                    let dateA = DateTime.fromISO(a.created_on,{zone:'utc'});
                                    let dateB = DateTime.fromISO(b.created_on,{zone:'utc'})
                                    if (dateA > dateB) return -1;
                                    if (dateA < dateB) return 1;
                                return 0;
                         }).sort((a, b) => {
                            if (a.pin === true && !b.pin ) return -1; 
                            if (b.pin === true && !a.pin !== true) return 1;  
                            if (!a.pin  && !b.pin ) return 1;
                            if (!b.pin  && !a.pin ) return -1;
                            return 0; 
                        }).map((i) => {
                            return (
                                <div >
                                    <div className="p-1 flex m-1" style={{ flexDirection: 'column', border: '1px solid #00000050',borderRadius:10}} >
                                        <div className="col-12 grid m-0"  style={{borderRadius:10}} onClick={()=>{i.expanded = (i.expanded ? !i.expanded : true ); forceUpdate()}}>
                                        <label className="fs-18 fw-7  col-11 flex" style={{color:'#00000090'}}>{i.title}</label>
                                        <div className="col-1 flex justify-content-end">
                                        { i.pin && <svg  xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px" fill="#EA3323"><path d="m640-480 80 80v80H520v240l-40 40-40-40v-240H240v-80l80-80v-280h-40v-80h400v80h-40v280Zm-286 80h252l-46-46v-314H400v314l-46 46Zm126 0Z"/></svg> }
                                        <i className="material-icons fs-16 ml-2 flex justify-content-end align-items-center">{i.expanded ? "arrow_upward" : "arrow_downward"}</i>
                                        </div>
                                        </div>
                                        {/* <label className="fs-18 fw-5 clr-gray-900"  style={{marginBottom:10 }}>{i.title}</label> */}
                                        {i.expanded && <div className="col-12" style={{padding:10,borderTop:'1px solid #e0e0e0',borderBottom:'1px solid #e0e0e0' }} > <div dangerouslySetInnerHTML={{__html:updateImgTags(i.message)}} style={{maxHeight:200,overflow:'auto'}} /> 
                                   
                                        </div>}
                                    
                                        <div classname='fw-4 fs-18' style={{ width: '100%', color: '#828282', padding: '0.5rem' }}>{DateTime.fromISO(i.modified_on).toLocal().toFormat('dd-M-yyyy')}
                                            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" style={{ marginLeft: 5, marginRight: 5 }} xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#828282" />
                                            </svg> {DateTime.fromISO(i.modified_on).toLocal().toFormat('HH:mm a')}
                                        </div>

                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

            </div>
        </div>
    )

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(UserDashboard, comparisonFn);