import XlsxPopulate from "xlsx-populate";
import IATACodes from "../../assets/dummy/IATACodes";


const exportTemplate = (guidance,dataEntry,title,type) => {

    XlsxPopulate.fromBlankAsync()
        .then(workbook => {
            // Add and style the first worksheet
            const sheet1 = workbook.addSheet("Guidance");
            guidance.forEach((row, rowIndex) => {
                row.forEach((value, colIndex) => {
                    const cell = sheet1.cell(rowIndex + 1, colIndex + 1);
                    cell.value(value);

                    // Apply background color based on prefix
                    if (value.startsWith("$L$")) {
                        const cleanValue = value.replace(/^(\$[A-Za-z]+\$)/, '');

                        // Set the clean value to the cell
                        cell.value(cleanValue);
                        cell.style("fill", "D3D3D3"); // Light Gray
                    } else if (value.startsWith("$B$")) {
                        const cleanValue = value.replace(/^(\$[A-Za-z]+\$)/, '');

                        // Set the clean value to the cell
                        cell.value(cleanValue);
                        cell.style("fill", "ADD8E6"); // Light Blue
                    } else if (value.startsWith("$G$")) {
                        const cleanValue = value === '$G$1' ? 1 : value.replace(/^(\$[A-Za-z]+\$)/, '');

                        cell.value(cleanValue);
                        cell.style("fill", "90EE90"); // Light Green
                    } else if (value.startsWith("$H$")) {
                        const cleanValue = value.replace(/^(\$[A-Za-z]+\$)/, '');

                        cell.value(cleanValue);
                        cell.style("fill", "fec8c8"); // Light Green
                    } else if (value.startsWith("$R$")) {
                        const cleanValue = value.replace(/^(\$[A-Za-z]+\$)/, '');

                        cell.value(cleanValue);
                        cell.style("fill", "e81123"); // Light Green
                    }



                    cell.style("border", true); // Black border



                });
            });
console.log(`\" ${IATACodes.map(i=>i.value).join(',')},\"`)
            // Add and populate the second worksheet
            const sheet2 = workbook.addSheet("Data Entry");
            dataEntry.forEach((row, rowIndex) => {
                row.forEach((value, colIndex) => {
                    // if(type === 'air' && (colIndex === 1 || colIndex === 2) && rowIndex > 1){
                       
                            sheet2.cell(rowIndex+1,colIndex+1).dataValidation({
                                type: 'list',
                                allowBlank: false,
                                showInputMessage: false,
                                prompt: false,
                                promptTitle: 'String1',
                                showErrorMessage: true ,
                                error: 'Select IATA Code',
                                errorTitle: 'Alert',
                                operator: 'String4',
                                formula1:`\"Aarhus/Denmark/AAR,Abadan/Iran/ABD,Abeche/Chad/AEH,Aberdeen/United Kingdom/ABZ,Aberdeen (SD)/USA/ABR,Abidjan/Cote d'Ivoire/ABJ,Abilene (TX)/USA/ABI,Abu Dhabi - Abu Dhabi International/United Arab Emirates/AUH,Abuja - Nnamdi Azikiwe International Airport/Nigeria/ABV,Abu Rudeis/Egypt/AUE,Abu Simbel/Egypt/ABS,Acapulco/Mexico/ACA,Accra - Kotoka International Airport/Ghana/ACC,Adana/Turkey/ADA,Addis Ababa - Bole International Airport/Ethiopia/ADD,Adelaide/Australia/ADL,Antigua- V.C. Bird International/Antigua and Barbuda/ANU,\"`
                            });
                        
                    // }
                    sheet2.cell(rowIndex + 1, colIndex + 1).value(value);

                });
            });

            // sheet2.range("F2:F10").dataValidation('Yes')

            // Remove the default "Sheet1" if necessary
            workbook.deleteSheet("Sheet1");
            return workbook.outputAsync("blob");
            // Write the workbook to a file

        })
        .then((blob) => {
            // Trigger download
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = title + ".xlsx";
            a.click();
            URL.revokeObjectURL(url); // Clean up
        })
        .catch(error => {
            console.error('Error creating Excel file:', error);
        });
}
export {exportTemplate}