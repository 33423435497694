import Axios from "axios";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import useForceUpdate from "use-force-update";
import { API } from "../../../../components/constants/api_url";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useHistory } from "react-router-dom";
import { MultiSelect } from 'primereact/multiselect'
import { TabMenu } from "primereact/tabmenu";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import Swal from "sweetalert2";
import APIServices from "../../../../service/APIService";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { hardcoded } from "../../../../pages/constants/hardcodedid";
import { array, object } from "yup";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { Divider } from "primereact/divider";
import { BlockUI } from 'primereact/blockui';
import { checkRoleAccessByRoleIds } from "../../../../components/BGHF/helper";
import { ReadMoreComponent } from "../../../../components/Forms/ReadMoreComponent";

const { DateTime } = require("luxon");


const QuantitativeReviewerOverall = () => {
    const dpnamerefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null),useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
   
    const formref = useRef(null)
    const entityref = useRef(null)
    // const userList = useSelector(state => state.userlist.userList)
    const userList = [ {id:94,information:{empname:'Enterprise Admin'}} , ...JSON.parse(JSON.stringify(useSelector(state => state.userlist.userList)))]

    const [assigneedialog, setAssigneeDialog] = useState(false)
    const [assigneeobj, setAssigneeObj] = useState({ reviewer_ids: [], reporter_ids: [], approver_ids: [] })
    const [tempload, setTempLoad] = useState(false)
    const [stdlist, setStdList] = useState([])
    const [subcat1, setSubCat1] = useState([])
    const [subcat2, setSubCat2] = useState([])
    const [subcat3, setSubCat3] = useState([])
    const [subcat4, setSubCat4] = useState([])
    const statusref = useRef(null)
    const login_data = useSelector((state) => state.user.userdetail);
    const admin_data = useSelector((state) => state.user.admindetail);

    const [entityoption, setEntityOption] = useState([])
    const [formoption, setFormOption] = useState([])
    const [yearoption, setYearOption] = useState([])
    const [statusoption, setStatusOption] = useState([])
    const navigate = useHistory();
    const months = [
        "January",
        "Febraury",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const [submittedQuantitative, setSubmittedQuantitative] = useState([]);
    const [overallsubmittedQuantitative, setOverallSubmittedQuantitative] =
        useState([]);
    const [rawsitelist, setRawSitelist] = useState([]);
    const [filter, setFilter] = useState({ year: null, entity: [], form: [], status: [] });
    const [assignedlist, setAssignedList] = useState([])
    const [quantitativesubmission, setQuantiativeSubmission] = useState([])
    const [refineddata, setRefinedData] = useState([]);
    const [refineddatabk, setRefinedDataBk] = useState([]);
    const [dcflist, setDcfList] = useState([]);
    const [dplist, setDPList] = useState([]);
    const [load, setLoading] = useState(false);
    const forceUpdate = useForceUpdate();
    const [remarksdialog, setRemarksDialog] = useState(false)
    const [remarksdata, setRemarksData] = useState([])
    const frequency_list = [
        { name: "Monthly", id: 1 },
        { name: "Bi-Monthly", id: 2 },
        { name: "Quartely", id: 3 },
        { name: "Annually", id: 4 },
        { name: "Bi-Annually", id: 5 },
        { name: "Undefined", id: 6 },
    ];

    useEffect(() => {

        let uriString = {
            include: [
                {
                    relation: "locationTwos",
                    scope: { include: [{ relation: "locationThrees" }] },
                },
            ],
        };
        let efstd = {
            include: [
                {
                    relation: "newEfCategories",
                    scope: {
                        include: [
                            {
                                relation: "newEfSubcategory1s",
                                scope: {
                                    include: [
                                        {
                                            relation: "newEfSubcategory2s",
                                            scope: {
                                                include: [
                                                    {
                                                        relation: "newEfSubcategory3s",
                                                        scope: {
                                                            include: [{ relation: "newEfSubcategory4s" }],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        };
        let uriString2 = {
            include: [
                {
                    relation: "newTopics",
                    scope: {
                        include: [
                            {
                                relation: "newMetrics",
                                scope: { include: [{ relation: "newDataPoints" }] },
                            },
                        ],
                    },
                },
            ],
        };
        let Overall = API.Categories + `?filter=${encodeURIComponent(JSON.stringify(uriString2))}`;
        const promise0 = APIServices.get(API.DCF);
        const promise1 = APIServices.get(
            API.QN_Submit_UP(admin_data.id)
        );
        const promise2 = APIServices.get(
            API.LocationOne_UP(admin_data.id) +
            `?filter=${encodeURIComponent(JSON.stringify(uriString))}`
        );
        const promise3 = APIServices.get(
            API.DCF_Entity_UP(admin_data.id)
        );
        const promise4 = APIServices.get(
            API.DF_Entity_UP(admin_data.id)
        );
        const promise5 = APIServices.get(
            API.SRF_Entity_UP(admin_data.id)
        );
        const promise6 = APIServices.get(
            API.DCF_Entity_User_UP(admin_data.id)
        );
        const promise7 = APIServices.get(
            API.DF_Entity_User_UP(admin_data.id)
        );
        const promise8 = APIServices.get(
            API.SRF_Entity_User_UP(admin_data.id)
        );
        const promise9 = APIServices.get(API.DataPoint);
        const promise10 = APIServices.get(API.EF_Std + `?filter=${encodeURIComponent(JSON.stringify(efstd))}`)
        const promise11 = APIServices.get(API.EF_SC1);
        const promise12 = APIServices.get(API.EF_SC2);
        const promise13 = APIServices.get(API.EF_SC3);
        const promise14 = APIServices.get(API.EF_SC4);
        const promise15 = APIServices.get(
            API.AssignDCFClient_UP(admin_data.id)
        );
        const promise16 = APIServices.get(
            Overall
        );
        const promise17 = APIServices.get(
            API.GetRoleByUserId(admin_data.id, login_data.id)
        );
        Promise.all([
            promise0,
            promise1,
            promise2,
            promise3,
            promise4,
            promise5,
            promise6,
            promise7,
            promise8,
            promise9, promise10, promise11, promise12, promise13, promise14, promise15, promise16,promise17
        ]).then((values) => {
            let curated_dcf_ids = []
            if (values[15].data && values[15].data.length > 0) {
                values[16].data.forEach((cat) => {
                    if (cat.newTopics !== undefined) {

                        cat.newTopics.forEach((topic) => {

                            if (topic.newMetrics !== undefined && values[15].data[0].topic_ids.includes(topic.id) && (topic.tag === null || parseFloat(topic.tag) === admin_data.id)) {

                                topic.newMetrics.forEach((metric) => {
                                    if (Array.isArray(metric.data1)  && values[15].data[0].metric_ids.includes(metric.id)  && metric.data1[0].type !== undefined && metric.data1[0].type === 0 && (metric.tag === null || metric.tag === admin_data.id)) {
                                        if (metric.newDataPoints !== undefined) {
                                            metric.newDataPoints.forEach((dp) => {
                                                if (Array.isArray(dp.data1) &&  dp.data1.length !== 0 && dp.data1[0].datasource !== null && typeof dp.data1[0].datasource === 'number') {
                                                    let dcf_index = values[0].data.findIndex(i => i.id === dp.data1[0].datasource)
                                                    if (!curated_dcf_ids.includes(dp.data1[0].datasource) && dcf_index !== -1) {

                                                        if (values[0].data[dcf_index].tags === null || !values[0].data[dcf_index].tags.length || values[0].data[dcf_index].tags.includes(admin_data.id)) {
                                                            curated_dcf_ids.push(dp.data1[0].datasource)
                                                        }

                                                    }
                                                }
                                            })
                                        }
                                    }
                                })
                            }
                        })
                    }


                })
            }
            setSubCat1(values[11].data)
            setSubCat2(values[12].data)
            setSubCat3(values[13].data)
            setSubCat4(values[14].data)

            let stdList = values[10].data
            let quantitative_submitted = values[1].data,
                qn_submit_list = [],
                overall_qn_submit_list = [],
                dcf_list = values[0].data,
                quantitative_ass_list = values[6].data,
                qualitative_ass_list = values[7].data,
                srf_ass_list = values[8].data;
            let quantitative_entity_list = values[3].data,
                qualitative_entity_list = values[4].data,
                srf_entity_list = values[5].data;
            const shapedSite = values[2].data
                .map((item) => {
                    if (item.locationTwos) {
                        item.locationTwos = item.locationTwos.filter(
                            (locationTwo) =>
                                locationTwo.locationThrees &&
                                locationTwo.locationThrees.length > 0
                        );
                    }
                    return item;
                })
                .filter((item) => item.locationTwos && item.locationTwos.length > 0);
   
            let filtered_qn_ass = quantitative_ass_list.filter(
                (i) =>{   
                    return     (dcf_list.map((j) => j.id).includes(i.dcfId) && curated_dcf_ids.includes(i.dcfId) &&
                    (login_data.role === 'clientadmin' ||  checkRoleAccessByRoleIds([login_data.id],[4],i.level,i[`tier${i.level}_id`], values[17].data.filter(i => i.user_id === login_data.id && i.userProfileId === admin_data.id),shapedSite).includes(login_data.id) ))
                }
            );
            console.log(
                filtered_qn_ass.filter((i) => i.reviewer_ids.includes(login_data.id))
            );

            setDcfList(dcf_list);
            setDPList(values[9].data);
            setRawSitelist(shapedSite);
            setStdList(values[10].data)
            quantitative_submitted.forEach((item) => {
                let filtered_qn_ass_index = filtered_qn_ass.findIndex((i) => {
                    console.log(
                        i.id,
                        item.entityUserAssId,
                        i.entityAssId,
                        item.entityAssId
                    );
                    return (
                        i.id === item.entityUserAssId && i.entityAssId === item.entityAssId
                    );
                });
                if (filtered_qn_ass_index !== -1) {
                    overall_qn_submit_list.push(item);
                    if (
                        filtered_qn_ass[filtered_qn_ass_index].reviewer_ids.includes(
                            login_data.id
                        )
                    ) {
                        qn_submit_list.push(item);
                    }
                }
            });
            // getReviewAssignment(
            //     filtered_qn_ass.filter((i) => i.reviewer_ids.includes(login_data.id)),
            //     values[0].data,
            //     qn_submit_list
            // );
            setQuantiativeSubmission(quantitative_submitted)
            setAssignedList(filtered_qn_ass.filter((i) => i.reviewer_ids.includes(login_data.id)))
            console.log(filtered_qn_ass.filter((i) => i.reviewer_ids.includes(login_data.id)))
            calculateAndCreateYearData(
                filtered_qn_ass.filter((i) => i.reviewer_ids.includes(login_data.id)),
                values[0].data,
                qn_submit_list, stdList, { cat1: values[11].data, cat2: values[12].data, cat3: values[13].data, cat4: values[14].data }
            ).then((res) => {
                console.log(res);
                let entity_option = Array.from(new Set(res.map(i => getCoverageTextById(i, shapedSite)))).map(i => ({ title: i, id: i }))

                setFormOption(dcf_list.filter(i => res.map(i => i.dcfId).includes(i.id)))
                setEntityOption(entity_option)
                setYearOption(Array.from(new Set(res.map(i => i.year))).map(i => ({ value: i, title: i })))
                if (res.length) {
                    onSelectedChangeIntial('year', 2024, res)
                } else {
                    setLoading(true);
                }
                // setRefinedData(res);
                setRefinedDataBk(res);

            });
            setOverallSubmittedQuantitative(
                overall_qn_submit_list.filter((i) => i.type !== 0)
            );
            setSubmittedQuantitative(qn_submit_list.filter((i) => i.type === 1));
            extractDPFromForms(qn_submit_list.filter((i) => i.type === 1));

        });
    }, []);

    function getMonthsBetween_(start_date, end_date, frequency) {
        const startDate = DateTime.fromISO(start_date);
        const endDate = end_date ? DateTime.fromISO(end_date) : DateTime.utc();

        const months = [];
        let currentMonth = startDate;

        while (currentMonth <= endDate) {
            if (frequency === 1) {
                console.log(currentMonth.toLocal().startOf('month').diff(DateTime.utc().toLocal(), 'days').days)
                if (currentMonth.toLocal().startOf('month').diff(DateTime.utc().toLocal(), 'days').days <= 0) {
                    months.push(currentMonth.toFormat("LLL-yyyy"));
                }
            } else {

                if (currentMonth.toLocal().startOf('month').diff(DateTime.utc().toLocal(), 'days').days <= 0) {
                    console.log(currentMonth.plus({ months: frequency - 1 }).toFormat("LLL-yyyy"), currentMonth.plus({ months: frequency - 1 }).toLocal().startOf('month').diff(endDate.toLocal(), 'days').days)
                    months.push(
                        currentMonth.toFormat("LLL-yyyy") +
                        " to " +
                        currentMonth.plus({ months: frequency - 1 }).toFormat("LLL-yyyy")
                    );
                }


            }

            currentMonth = currentMonth.plus({ months: frequency });
        }

        return months;
    }
    function getMonthsBetween(start_date, end_date, frequency) {
        const startDate = DateTime.fromISO(start_date, { zone: 'utc' }).plus({ months: 1 });
        const endDate = end_date ? DateTime.fromISO(end_date, { zone: 'utc' }).plus({ months: 1 }) : DateTime.utc().plus({ months: 1 });

        const months = [];
        let currentMonth = startDate;

        while (currentMonth <= endDate) {
            if (frequency === 1) {
                console.log(currentMonth.startOf('month').diff(DateTime.utc(), 'days').days, currentMonth.toFormat("LLL-yyyy"))
                if (currentMonth.startOf('month').diff(DateTime.utc(), 'days').days <= 0) {
                    months.push(currentMonth.toFormat("LLL-yyyy"));
                }
            } else {

                if (currentMonth.startOf('month').diff(DateTime.utc(), 'days').days <= 0) {
                    console.log(currentMonth.plus({ months: frequency - 1 }).toFormat("LLL-yyyy"), currentMonth.plus({ months: frequency - 1 }).toLocal().startOf('month').diff(endDate.toLocal(), 'days').days)
                    months.push(
                        currentMonth.toFormat("LLL-yyyy") +
                        " to " +
                        currentMonth.plus({ months: frequency - 1 }).toFormat("LLL-yyyy")
                    );
                }


            }

            currentMonth = currentMonth.plus({ months: frequency });
        }

        return months;
    }
    function getOverdueDays(monthString) {
        console.log(monthString)
        const [startMonth, endMonth] = monthString.split(' to ');

        const month = endMonth ? endMonth : startMonth;
        const [monthValue, year] = month.split('-');
        const endOfMonth = DateTime.fromObject({ year: parseInt(year), month: DateTime.fromFormat(monthValue, 'LLL').month }).endOf('month');
        const currentDate = DateTime.local();
        console.log(month, endOfMonth.diff(currentDate, 'days').days)
        return endOfMonth.diff(currentDate, 'days').days;
    }
    const getStatusCode = (arr) => {
        console.log(arr)
        return new Promise((resolve, err) => {
            let codes = []
            arr.forEach((i, inx) => {
                Object.entries(i).forEach((j) => {
                    if (!j[0].includes('name')) {
                        console.log(j)
                        if (j[1].length) {
                            if (j[1][0].id !== undefined) {
                                if (j[1][0].status === 5) {

                                    if (!codes.includes(7)) {
                                        codes.push(7)

                                    }
                                } else if (j[1][0].status === 4) {

                                    if (!codes.includes(6)) {
                                        codes.push(6)

                                    }
                                } else if (j[1][0].status === 3) {

                                    if (!codes.includes(5)) {
                                        codes.push(5)

                                    }
                                } else if (j[1][0].status === 2) {

                                    if (!codes.includes(8)) {
                                        codes.push(8)

                                    }
                                }
                                else if (j[1][0].status === 1) {

                                    if (!codes.includes(1)) {
                                        codes.push(1)

                                    }
                                } else if (getOverdueDays(j[0]) <= -15 && (j[1][0].status === null || j[1][0].status === 0)) {

                                    if (!codes.includes(2)) {
                                        codes.push(2)

                                    }
                                } else if (getOverdueDays(j[0]) <= 0 && (j[1][0].status === null || j[1][0].status === 0)) {

                                    if (!codes.includes(3)) {
                                        codes.push(3)

                                    }
                                } else if (getOverdueDays(j[0]) > 0 && (j[1][0].status === null || j[1][0].status === 0)) {

                                    if (!codes.includes(4)) {
                                        codes.push(4)

                                    }
                                }
                            } else {

                                if (getOverdueDays(j[0]) <= -15) {

                                    if (!codes.includes(2)) {
                                        codes.push(2)

                                    }
                                } else if (getOverdueDays(j[0]) <= 0) {

                                    if (!codes.includes(3)) {
                                        codes.push(3)

                                    }
                                } else if (getOverdueDays(j[0]) > 0) {

                                    if (!codes.includes(4)) {
                                        codes.push(4)

                                    }
                                }
                            }
                        } else {
                            console.log(j, i)
                            if (getOverdueDays(j[0]) <= -15) {


                                if (!codes.includes(2)) {
                                    codes.push(2)

                                }
                            } else if (getOverdueDays(j[0]) <= 0) {

                                if (!codes.includes(3)) {
                                    codes.push(3)

                                }
                            } else if (getOverdueDays(j[0]) > 0) {

                                if (!codes.includes(4)) {
                                    codes.push(4)

                                }
                            }

                        }
                    }
                })
            })
            if (!codes.length) {
                codes.push(0)
            }
            resolve(codes)
        })



    }
    const attachObj = (arr, refobj) => {
        for (let i = 0; i < arr.length; i++) {
            arr[i] = { ...arr[i], refobj: refobj.refobj }
        }
        return arr
    }
    const getDPNameHC = (arr) => {
        let result = [], count = 0
        console.log(arr)
        arr.forEach((i) => {
            Object.values(i).forEach((j) => {
                j.forEach((k) => {
                    if (result.findIndex(x => x.label === k.dp) === -1) {
                        count++
                        result.push({ label: k.dp, order: count })
                    }
                })

            })
        })
        return result
    }
    async function calculateAndCreateYearData(
        assignment,
        dcf_list,
        submission_list, efstdlist, subcat
    ) {
        const yearData = [];
        const actualData = [];
        const uniqueCombinations = [];
        return new Promise((resolve, reject) => {
            assignment.forEach((item) => {
                console.log(item)
                // Extract necessary information
                const {
                    start_date,
                    end_date,
                    tier0_id,
                    tier1_id,
                    tier2_id,
                    tier3_id,
                    dcfId,
                    frequency,
                    standard
                } = item;

                // Convert start_date and end_date to Luxon DateTime objects
                const startDate = DateTime.fromISO(start_date);
                const endDate = end_date ? DateTime.fromISO(end_date) : DateTime.utc();

                // Generate year array based on start_date and end_date
                const startDateYear = startDate.year;
                const endDateYear = endDate.year;
                const yearArray = Array.from(
                    { length: endDateYear - startDateYear + 1 },
                    (_, index) => startDateYear + index
                );
                console.log(
                    frequency,
                    getMonthsBetween(
                        start_date,
                        end_date,
                        frequency === 4 ? 12 : frequency === 5 ? 6 : frequency
                    )
                );
                // Check if the combination already exists
                const existingCombinationIndex = uniqueCombinations.findIndex(
                    (combination) => {
                        return (
                            combination.tier0_id === tier0_id &&
                            combination.tier1_id === tier1_id &&
                            combination.tier2_id === tier2_id &&
                            combination.tier3_id === tier3_id && combination.standard === standard &&
                            combination.dcfId === dcfId
                        );
                    }
                );

                if (existingCombinationIndex !== -1) {
                    const existingYears = new Set(
                        uniqueCombinations[existingCombinationIndex].year
                    );

                    // Add new years from yearArray to the existingYears Set
                    yearArray.forEach((year) => {
                        existingYears.add(year);
                    });
                    actualData.push({
                        ...item,
                        year: yearArray,
                        dataobject: getMonthsBetween(
                            start_date,
                            end_date,
                            frequency === 4 ? 12 : frequency === 5 ? 6 : frequency
                        )
                    })
                    uniqueCombinations[existingCombinationIndex].year =
                        Array.from(existingYears);
                    // Convert the Set back to an array and update the uniqueCombinations entry
                    uniqueCombinations[existingCombinationIndex].dataobject = [
                        ...uniqueCombinations[existingCombinationIndex].dataobject,
                        ...getMonthsBetween(
                            start_date,
                            end_date,
                            frequency === 4 ? 12 : frequency === 5 ? 6 : frequency
                        ),
                    ];
                } else {
                    // If the combination doesn't exist, create a new object
                    uniqueCombinations.push({
                        ...item,
                        year: yearArray,
                        dataobject: getMonthsBetween(
                            start_date,
                            end_date,
                            frequency === 4 ? 12 : frequency === 5 ? 6 : frequency
                        ),
                    });
                    actualData.push({
                        ...item,
                        year: yearArray,
                        dataobject: getMonthsBetween(
                            start_date,
                            end_date,
                            frequency === 4 ? 12 : frequency === 5 ? 6 : frequency
                        )
                    })
                }
            });
            uniqueCombinations.forEach((item) => {
                const {
                    start_date,
                    end_date,
                    tier0_id,
                    tier1_id,
                    tier2_id,
                    tier3_id,
                    dcfId, locationId, entityAssId, frequency, level,
                    standard,
                    dataobject,
                } = item;

                console.log(start_date, end_date);
                const dcfItem = dcf_list.find((item) => item.id === dcfId);

                console.log(dataobject);
                if (dcfItem) {
                    let data1ForYear = [],
                        msn = [
                            {
                                name: JSON.parse(
                                    JSON.stringify(
                                        dcfItem.data1.filter((obj) => obj.type === "number" || (obj.name === 'DPAN1109' && dcfId === 268))

                                    )
                                ),
                            },
                        ];
                    data1ForYear = JSON.parse(
                        JSON.stringify(dcfItem.data1.filter((obj) => obj.type === "number" || (obj.name === 'DPAN1109' && dcfId === 268)))
                    );
                    item.year.forEach((y) => {
                        console.log(
                            item.dataobject.filter((i) => i.includes(y.toString())).length,
                            y
                        );
                        item.dataobject
                            .filter((i) => i.includes(y.toString()))
                            .forEach((xy, xindx) => {
                                let act = actualData.filter(i => i.dcfId === item.dcfId && i.tier0_id === tier0_id && i.tier1_id === tier1_id && i.tier2_id === tier2_id && i.tier3_id === tier3_id && i.standard === standard && i.dataobject.includes(xy))
                                let newObj2 = { company_id: admin_data.id, level, locationId, entityAssId }
                                newObj2['self'] = act[0].reviewer_ids.length === 0 ? true : false
                                newObj2['refobj'] = item
                                newObj2['frequency'] = act[0].frequency
                                newObj2['entityUserAssId'] = act[0].id
                                newObj2['standard'] = act[0].standard
                                if (hardcoded.dcf.includes(dcfId.toString()) || hardcoded.dcf2.includes(dcfId.toString())) {
                                    if (dcfId === 304) {
                                        msn.push(getValueByMonthlyHC3Sub(dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,
                                            [xy],
                                            dcfId === 304 ? ['DPA0131', 'DPA0132', 'DPA0336'] : ['DPAN096', 'DPAN099', 'DPAN098'],
                                            submission_list, efstdlist, subcat, standard, '', newObj2.self, newObj2))
                                    } else if (dcfId === 287) {
                                        msn.push(getValueByMonthlyElectricity287(dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,
                                            [xy],
                                            ['DPAN095', 'DPAN095A', 'DPAN095B', 'energyval'],
                                            submission_list, efstdlist, subcat, standard, '', newObj2.self, newObj2))

                                    } else if (dcfId === 305 || dcfId === 16) {
                                        msn.push(getValueByMonthlyHC1Sub(dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,
                                            [xy],
                                            dcfId === 305 ? ['DPA0136', 'DPA0138'] : ['DPA0287', 'DPA0289'],
                                            submission_list, efstdlist, subcat, standard, dcfId === 305 ? 'kg' : 'USD', newObj2.self, newObj2))

                                    } else if (dcfId === 286) {
                                        msn.push(getValueByMonthlyWaterD286(dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,
                                            [xy],
                                            submission_list, 'm3', newObj2.self, newObj2))
                                    } else if (dcfId === 285) {
                                        msn.push(getValueByMonthlyWaterW285(dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,
                                            [xy],
                                            submission_list, 'm3', newObj2.self, newObj2))
                                    } else if (dcfId === 300) {
                                        msn.push(getValueByMonthlyBTAir(dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,
                                            [xy],
                                            submission_list, 'km', newObj2.self, newObj2))

                                    }
                                    else if (dcfId === 302) {
                                        console.log(xy)
                                        msn.push(getValueByMonthlyBTRail(dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,
                                            [xy],
                                            submission_list, 'km', newObj2.self, newObj2))

                                    }
                                    else if (dcfId === 301) {
                                        console.log(xy)
                                        msn.push(getValueByMonthlyBTLand(dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,
                                            [xy],
                                            submission_list, 'km', newObj2.self, newObj2))

                                    }
                                    else if (dcfId === 297) {
                                        console.log(xy)
                                        msn.push(getValueByMonthlyHazWaste297(dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,
                                            [xy],
                                            submission_list, 'km', newObj2.self, newObj2))

                                    }
                                    else if (dcfId === 307) {
                                        console.log(xy)
                                        msn.push(getValueByMonthlyNonHazWaste307(dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,
                                            [xy],
                                            submission_list, 'km', newObj2.self, newObj2))

                                    }
                                    else if (dcfId === 275) {
                                        console.log(xy)
                                        msn.push(getValueByMonthlyEmpCat275(dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,
                                            [xy],
                                            submission_list, 'nos', newObj2.self, newObj2))

                                    } else if (dcfId === 284) {
                                        console.log(xy)
                                        msn.push(getValueByMonthlyEmpNewTurn284(dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,
                                            [xy],
                                            submission_list, 'nos', newObj2.self, newObj2))

                                    }else if (dcfId === 295) {
                                        console.log(xy)
                                        msn.push(getValueByMonthlyEmpTrainingHrs295(dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,
                                            [xy],
                                            submission_list, 'hrs', newObj2.self, newObj2))

                                    }
                                    else if (dcfId === 311) {
                                        
                                        console.log(xy)
                                        msn.push(getValueByMonthlyCommunicationAnti311(dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,
                                            [xy],
                                            submission_list, 'nos', newObj2.self, newObj2))

                                    } else if (dcfId === 310) {
                                        console.log(xy)
                                        msn.push(getValueByMonthlyCarbonCredits310(dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,
                                            [xy],
                                            submission_list, 'tco2e', newObj2.self, newObj2))

                                    } else if (dcfId === 294) {
                                        console.log(xy)
                                        msn.push(getValueByMonthlyParentalLeave294(dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,
                                            [xy],
                                            submission_list, 'nos', newObj2.self, newObj2))

                                    } else if (dcfId === 298) {
                                        msn.push(getValueByMonthlyProportionSpend298(dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,
                                            [xy],
                                            ['DPAH0010', 'DPAH0012'] ,
                                            submission_list, efstdlist,  JSON.parse(JSON.stringify(subcat)), standard, '%', newObj2.self, newObj2))

                                    } 
                                    else {
                                        msn.push(getValueByMonthlyHC(
                                            dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,
                                            [xy],
                                            data1ForYear.map((i) => i.name),
                                            submission_list, newObj2.self, newObj2
                                        ))
                                    }


                                } else {
                                    msn.push(
                                        getValueByMonthly(
                                            dcfId,
                                            tier0_id,
                                            tier1_id,
                                            tier2_id,
                                            tier3_id,
                                            [xy],
                                            data1ForYear.map((i) => i.name),
                                            submission_list, newObj2.self, newObj2
                                        )
                                    );
                                }

                            });
                    });
                    console.log(msn, item.dataobject);
                    item.year.forEach(async (y) => {
                        if (dcfId === 304 || dcfId === 305 || dcfId === 16 || dcfId === 286 || dcfId === 285 || dcfId === 300 || dcfId === 301 || dcfId === 302 || dcfId === 297 || dcfId === 307 || dcfId === 275 || dcfId === 284 || dcfId === 287 || dcfId === 310 || dcfId === 311 || dcfId === 294 || dcfId === 298|| dcfId === 295) {
                            let msn_ = msn.filter((i) => {
                                return (Object.keys(i)[0].includes(y.toString())
                                );
                            })
                            let names_ = getDPNameHC(msn_)
                            msn_.forEach((i) => {
                                Object.values(i).forEach((j) => {
                                    let oldObj = {}
                                    let oldObjInd = j.findIndex(l => { return (l.id !== null && l.id !== undefined) })
                                    if (oldObjInd !== -1) {
                                        console.log(j[oldObjInd])
                                        oldObj = { id: j[oldObjInd].id, type: j[oldObjInd].type, status: j[oldObjInd].status, remarks: j[oldObjInd].remarks === undefined ? null : j[oldObjInd].remarks }
                                    }
                                    names_.forEach((k) => {
                                        let nameindex = j.findIndex(l => l.dp === k.label)
                                        if (nameindex === -1 && k.label !== null) {
                                            j.push({ ...oldObj, category: null, diff: false, form_type: 2, current: '-', dp: k.label, order: k.order })
                                        } else if (k.label !== null) {
                                            j[nameindex].order = k.order
                                        }
                                    })
                                    j.sort((a, b) => { return a.order - b.order }, 0)
                                })
                            })
                            console.log(msn_, dcfId)
                            msn_.unshift({ name: names_.filter(i => { return i.label !== null }) })

                            yearData.push({
                                year: y,
                                tier0_id,
                                tier1_id,
                                tier2_id,
                                tier3_id,
                                dcfId,
                                form_type: hardcoded.dcf.includes(dcfId.toString()) || hardcoded.dcf2.includes(dcfId.toString()) ? 2 : 1,
                                status: await getStatusCode(msn_.filter((i) => {
                                    return (
                                        Object.keys(i)[0] === "name" ||
                                        Object.keys(i)[0].includes(y.toString())
                                    );
                                }),),
                                dp_array: msn_.filter((i) => {
                                    return (
                                        Object.keys(i)[0] === "name" ||
                                        Object.keys(i)[0].includes(y.toString())
                                    );
                                }),
                            });
                        } else {
                            yearData.push({
                                year: y,
                                tier0_id,
                                tier1_id,
                                tier2_id,
                                tier3_id,
                                dcfId,
                                form_type: hardcoded.dcf.includes(dcfId.toString()) || hardcoded.dcf2.includes(dcfId.toString()) ? 2 : 1,
                                status: await getStatusCode(msn.filter((i) => {
                                    return (
                                        Object.keys(i)[0] === "name" ||
                                        Object.keys(i)[0].includes(y.toString())
                                    );
                                }),),
                                dp_array: msn.filter((i) => {
                                    return (
                                        Object.keys(i)[0] === "name" ||
                                        Object.keys(i)[0].includes(y.toString())
                                    );
                                }),
                            });
                        }


                    });
                }
                // yearData.forEach((i) => {
                //     i.dp_array.forEach((j,indx) => {
                //         Object.keys(j).forEach(async (k) => {
                //             if (k.includes('-'+i.year)) {

                //                 i.dp_array[indx][k]  =  await getValueByMonthly(i.dcfId,i.tier0_id,i.tier1_id,i.tier2_id,i.tier3_id,k,j.name,submission_list )

                //             }
                //         })
                //     })

                // })
                console.log(yearData);
            });

            resolve(yearData);
        });
    }

    function getKey(frequency, year) {
        const months = Array.from({ length: 12 }, (_, i) =>
            DateTime.local(year, i + 1, 1).toFormat("LLL")
        );
        const keys = [];
        console.log(months);
        switch (frequency) {
            case 1:
                months.forEach((month) => {
                    const key = month + "-" + year;
                    keys.push({ [key]: {} });
                });
                break;
            case 2:
                for (let i = 0; i < months.length; i += 2) {
                    const startMonthIndex = i;
                    const endMonthIndex = Math.min(
                        startMonthIndex + 1,
                        months.length - 1
                    );
                    const key =
                        months[startMonthIndex] +
                        "-" +
                        year +
                        " to " +
                        months[endMonthIndex] +
                        "-" +
                        year;
                    keys.push({ [key]: {} });
                }
                break;
            case 3:
                for (let i = 0; i < months.length; i += 3) {
                    const startMonthIndex = i;
                    const endMonthIndex = Math.min(
                        startMonthIndex + 2,
                        months.length - 1
                    );
                    const key =
                        months[startMonthIndex] +
                        "-" +
                        year +
                        " to " +
                        months[endMonthIndex] +
                        "-" +
                        year;
                    keys.push({ [key]: {} });
                }
                break;
            case 4:
                keys.push({
                    [`${months[0]}-${year} to ${months[months.length - 1]}-${year}`]: {},
                });
                break;
            case 5:
                const midIndex = Math.ceil(months.length / 2);
                const firstHalf = months.slice(0, midIndex);
                const secondHalf = months.slice(midIndex);
                const key1 =
                    firstHalf[0] +
                    "-" +
                    year +
                    " to " +
                    firstHalf[firstHalf.length - 1] +
                    "-" +
                    year;
                const key2 =
                    secondHalf[0] +
                    "-" +
                    year +
                    " to " +
                    secondHalf[secondHalf.length - 1] +
                    "-" +
                    year;
                keys.push({ [key1]: {} }, { [key2]: {} });
                break;
            default:
                break;
        }

        // Create the final object
        const result = {};
        keys.forEach((keyObj) => {
            Object.assign(result, keyObj);
        });
        console.log(result);
        return result;
    }
    function getPreviousPeriod(frequency, key) {
        if (frequency === 1) {
            const [month, year] = key.split("-");
            const previousDate = DateTime.fromFormat(
                `${month}-1-${year}`,
                "LLL-d-yyyy"
            ).minus({ months: 1 });
            const previousMonth = previousDate.toFormat("LLL");
            const previousYear = previousDate.year;
            return previousMonth + "-" + previousYear;
        } else {
            const endMonth = key.split(" to ")[0].split("-")[0].trim();
            const endYear = key.split(" to ")[0].split("-")[1].trim();
            const previousDate = DateTime.fromFormat(
                `${endMonth}-1-${endYear}`,
                "LLL-d-yyyy"
            ).minus({ months: 1 });
            const previousMonth = previousDate.toFormat("LLL");
            const previousYear = previousDate.year;
            return previousMonth + "-" + previousYear;
        }
    }
    const getRPTextFormat = (item) => {
        if (item.length !== 0) {
            if (item.length >= 2) {
                const startDate = DateTime.fromFormat(item[0], "MM-yyyy").toFormat(
                    "LLL-yyyy"
                );
                const endDate = DateTime.fromFormat(
                    item[item.length - 1],
                    "MM-yyyy"
                ).toFormat("LLL-yyyy");
                return `${startDate} to ${endDate}`;
            } else {
                return DateTime.fromFormat(item[0], "MM-yyyy").toFormat("LLL-yyyy");
            }
        }
    };
    function calculatePercentage(value1, value2) {
        if ((value1 === null || value1 === 0) && (value2 === null || value2 === 0)) {
            return '-'
        }
        if (value2 === 0) {
            return '100 %'
        }
        console.log(value1, value2)
        return parseInt(Math.abs(((value1 - value2) / value2) * 100)) + '%'
    }

    const getValueByMonthly = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr,
        dpnames,
        qn_submit_list, self, refobj
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]
        let final_result = {};
let hideDeviation = 0

        console.log(dpnames);
        arr.forEach((obj) => {
            console.log(obj)
            let result = {
                [obj]: dpnames.map((i) => ({
                    ...refobj,
                    remarks: null,
                    category: null,
                    form_type: 1,
                    current: "",
                    percentage: "",
                    text: "Pending Submission",
                    col: getNumberOfColumn(obj),
                    status: null,
                })),
            };
            let result_arr = [];
            let index = qn_submit_list.findIndex((i) => {
                console.log(getRPTextFormat(i.reporting_period), obj);
                return (
                    i.dcfId === dcfId &&
                    
                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(i.reporting_period) === obj
                );
            });
            console.log(index);
            if (index !== -1) {
                let index2 = qn_submit_list.findIndex((i) => {
                    console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                        i.reporting_period[i.reporting_period.length - 1]
                    ), obj)
                    return (
                        i.dcfId === dcfId &&
                        (i.type == 0 ? i.reject === 1 : true) &&
                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(
                            [i.reporting_period[i.reporting_period.length - 1]]
                        ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                    );
                });

                if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                    const { type1, reject1 } = qn_submit_list[index2];
                    const { type, reject } = qn_submit_list[index];
                    let value = null,
                        value2 = null;
                    result_arr = [];
                    dpnames.forEach((dpname) => {
                        let dp_index = qn_submit_list[index].response.findIndex(
                            (item) => item.name === dpname
                        );
                        if (dp_index !== -1 && typeof qn_submit_list[index].response[dp_index].value === 'string') {
                        
                            hideDeviation = 1
                            value = qn_submit_list[index].response[dp_index].value
                        } else if (dp_index !== -1) {
                            value = (qn_submit_list[index].response[dp_index].value !== undefined && qn_submit_list[index].response[dp_index].value !== null) ? parseFloat(
                                qn_submit_list[index].response[dp_index].value
                            ) : 0
                        } else {
                            value = 0
                        }
                        let dp_index2 = qn_submit_list[index2].response.findIndex(
                            (item) => item.name === dpname
                        );
                        if (dp_index2 !== -1 && typeof qn_submit_list[index2].response[dp_index2].value === 'string') {
                            hideDeviation = 1
                            value2 = qn_submit_list[index2].response[dp_index2].value
                        } else if (dp_index2 !== -1) {
                            value2 = (qn_submit_list[index2].response[dp_index2].value !== undefined && qn_submit_list[index2].response[dp_index2].value !== null) ? parseFloat(
                                qn_submit_list[index2].response[dp_index2].value
                            ) : 0
                        } else {
                            value = 0
                        }

                        result_arr.push({
                            remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            category: 2,
                            form_type: 1,
                            diff:  (hideDeviation || (value === value2)) ? null : value > value2 ? false : true,
                            current: value,
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: calculatePercentage(value, value2),
                            type,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? "Requires Resubmission"
                                        : type === 1 && reject === 1
                                            ? "Requires Re-Review"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submssion",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? 1
                                        : type === 1 && reject === 1
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        });
                    });
                    result = { [obj]: attachObj(result_arr, refobj) };
                } else {
                    const { type, reject } = qn_submit_list[index];
                    let value = null;
                    result_arr = [];

                    dpnames.forEach((dpname) => {
                        let dp_index = qn_submit_list[index].response.findIndex(
                            (item) => item.name === dpname
                        );
                        if (dp_index !== -1 && typeof qn_submit_list[index].response[dp_index].value === 'string') {

                            value = qn_submit_list[index].response[dp_index].value
                        }else if (dp_index !== -1) {
                            value = (qn_submit_list[index].response[dp_index].value !== undefined && qn_submit_list[index].response[dp_index].value !== null) ? parseFloat(
                                qn_submit_list[index].response[dp_index].value
                            ) : '-'
                        } else {
                            value = '-'
                        }
                        result_arr.push({
                            remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            category: 1,
                            form_type: 1,
                            diff: false,
                            current: value,
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: "-",
                            type,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? "Requires Resubmission"
                                        : type === 1 && reject === 1
                                            ? "Requires Re-Review"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submission",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? 1
                                        : type === 1 && reject === 1
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        });
                    });
                    result = { [obj]: attachObj(result_arr, refobj) };
                }
            }
            final_result = { ...final_result, ...result };
        });
        console.log(final_result, arr);
        return final_result;
    };
    const getValueByMonthlyEmpCat275 = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr, qn_submit_list, unit, self, refobj
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]
        let land_veh_list = ['Standard Taxi / Sedan', 'Hybrid Vehicle', 'Executive Taxi', 'Dual Purpose 4 x 4 (SUV)', 'Electric Vehicle (EV)', 'Van/ Coach']
        const value_arr = ['a1', 'b1', 'c1', 'd1', 'e1', 'f1']
        let final_result = {};

        console.log(tier0, tier1, tier2, tier3)
        arr.forEach((obj) => {
            console.log(obj)
            let result = {
                [obj]: [refobj],
            };
            let result_arr = [];
            let index = qn_submit_list.findIndex((i) => {
                console.log(getRPTextFormat(i.reporting_period) === obj, dcfId === i.dcfId, i.tier0_id === tier0,
                    i.tier1_id === tier1,
                    i.tier2_id === tier2,
                    i.tier3_id === tier3, i.id, i.tier3_id, tier3);
                return (
                    i.dcfId === dcfId &&
                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(i.reporting_period) === obj
                );
            });
            console.log(index, obj);
            if (index !== -1) {
                let index2 = qn_submit_list.findIndex((i) => {
                    console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                        i.reporting_period[i.reporting_period.length - 1]
                    ), obj)
                    return (
                        i.dcfId === dcfId &&
                        (i.type == 0 ? i.reject === 1 : true) &&
                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(
                            [i.reporting_period[i.reporting_period.length - 1]]
                        ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                    );
                });
                console.log(index2, obj)
                if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                    const { type1, reject1 } = qn_submit_list[index2];
                    const { type, reject } = qn_submit_list[index];
                    let value = null,
                        value2 = null;
                    result_arr = [];
                    let list_arr = []
                    let list_arr2 = []
                    qn_submit_list[index2].response.forEach((i) => {
                        let abc = {}

                        abc['a1'] = i['DPAN613'];
                        abc['b1'] = i['DPAN614'];
                        abc['c1'] = i['DPAN615'];
                        abc['d1'] = i['DPAN616'];
                        abc['e1'] = i['DPAN617'];
                        abc['f1'] = i['DPAN618'];
                        value_arr.forEach((x) => {
                            list_arr2.push({
                                ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                col: getNumberOfColumn(obj),
                                id: qn_submit_list[index].id,
                                category: 1,
                                form_type: 2,
                                diff: false,
                                current: abc[x],
                                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                percentage: '-',
                                type, self,
                                text:
                                    type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? "Unlocked for Resubmission"
                                            : type === 1 && reject === 2
                                                ? "Unlocked for Review Again"
                                                : type === 1
                                                    ? "Pending Review"
                                                    : type === 2
                                                        ? "Pending Approval"
                                                        : type === 3
                                                            ? "Approved & Locked"
                                                            : "Pending Submission",
                                status:
                                    type === 0 && (reject === 0 || reject === null) ? 0 :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? 1
                                            : type === 1 && reject === 2
                                                ? 2
                                                : type === 1
                                                    ? 3
                                                    : type === 2
                                                        ? 4
                                                        : type === 3
                                                            ? 5
                                                            : null
                            })
                        })


                    })
                    qn_submit_list[index].response.forEach((i) => {
                        let abc = {}
                        abc['a1'] = i['DPAN613'];
                        abc['b1'] = i['DPAN614'];
                        abc['c1'] = i['DPAN615'];
                        abc['d1'] = i['DPAN616'];
                        abc['e1'] = i['DPAN617'];
                        abc['f1'] = i['DPAN618'];

                        value_arr.forEach((x) => {
                            console.log(list_arr2)
                            let oldIndex = list_arr2.findIndex((j) => j[x] !== undefined)
                            if (oldIndex !== -1) {
                                value2 = list_arr2[oldIndex][x]

                                list_arr.push({
                                    ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 2,
                                    form_type: 2,
                                    diff: abc[x] === value2 ? null : abc[x] > value2 ? false : true,
                                    current: abc[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: calculatePercentage(abc[x], value2),
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })

                            } else {

                                list_arr.push({
                                    ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 2,
                                    form_type: 2,
                                    diff: false,
                                    current: abc[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: '100%',
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            }
                        })





                    })


                    result = { [obj]: attachObj(list_arr, refobj) };
                } else {

                    const { type, reject } = qn_submit_list[index];
                    let value = null;
                    result_arr = [];
                    let list_arr = []
                    console.log(qn_submit_list[index])
                    if (qn_submit_list[index].response.length) {

                        qn_submit_list[index].response.forEach((i) => {
                            let abc = {}

                            abc['a1'] = i['DPAN613'];
                            abc['b1'] = i['DPAN614'];
                            abc['c1'] = i['DPAN615'];
                            abc['d1'] = i['DPAN616'];
                            abc['e1'] = i['DPAN617'];
                            abc['f1'] = i['DPAN618'];
                            value_arr.forEach((x) => {
                                list_arr.push({
                                    ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    form_type: 2,
                                    category: 1,
                                    diff: false,
                                    current: abc[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: "-",
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            })




                        })
                    }
                    else {
                        list_arr.push({
                            edit: 1,
                            remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            form_type: 2,
                            category: 1,
                            diff: false,
                            current: '-',
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: "-",
                            type, self,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? "Unlocked for Resubmission"
                                        : type === 1 && reject === 2
                                            ? "Unlocked for Review Again"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submission",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? 1
                                        : type === 1 && reject === 2
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        })
                    }



                    result = { [obj]: attachObj(list_arr, refobj) };
                }
            }

            final_result = { ...final_result, ...result };
        });

        Object.values(final_result).forEach((j) => {
            console.log(j, arr)
            let find = Object.values(j).filter(i => (i['a1'] !== undefined));

            Object.values(j).forEach((k) => {
                console.log(k)
                if (k.category !== null && find.length) {
                    let name1 = 'NA'
                    if (k['a1'] || k['a1'] === 0) {
                        name1 = 'Senior Management (Male)'
                    } else if (k['b1'] || k['b1'] === 0) {
                        console.log('xyz')
                        name1 = 'Senior Management (Female)'
                    } else if (k['c1'] || k['c1'] === 0) {
                        name1 = 'Middle Management (Male)'
                    } else if (k['d1'] || k['d1'] === 0) {
                        name1 = 'Middle Management (Female)'
                    }
                    else if (k['e1'] || k['e1'] === 0) {
                        name1 = 'Non-Management (Male)'
                    }
                    else if (k['f1'] || k['f1'] === 0) {
                        name1 = 'Non-Management (Female)'
                    }


                    k.dp = (name1 === undefined ? 'NA' : name1 + ' ^ ' + unit)



                } else {
                    k.dp = null
                }
            })
        })
        console.log(final_result)
        return final_result;
    };
    const getValueByMonthlyEmpNewTurn284 = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr, qn_submit_list, unit, self, refobj
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]
        let land_veh_list = ['Standard Taxi / Sedan', 'Hybrid Vehicle', 'Executive Taxi', 'Dual Purpose 4 x 4 (SUV)', 'Electric Vehicle (EV)', 'Van/ Coach']
        const value_arr = ['a1', 'b1', 'c1', 'd1']
        let final_result = {};

        console.log(tier0, tier1, tier2, tier3)
        arr.forEach((obj) => {
            console.log(obj)
            let result = {
                [obj]: [refobj],
            };
            let result_arr = [];
            let index = qn_submit_list.findIndex((i) => {
                console.log(getRPTextFormat(i.reporting_period) === obj, dcfId === i.dcfId, i.tier0_id === tier0,
                    i.tier1_id === tier1,
                    i.tier2_id === tier2,
                    i.tier3_id === tier3, i.id, i.tier3_id, tier3);
                return (
                    i.dcfId === dcfId &&
                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(i.reporting_period) === obj
                );
            });
            console.log(index, obj);
            if (index !== -1) {
                let index2 = qn_submit_list.findIndex((i) => {
                    console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                        i.reporting_period[i.reporting_period.length - 1]
                    ), obj)
                    return (
                        i.dcfId === dcfId &&
                        (i.type == 0 ? i.reject === 1 : true) &&
                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(
                            [i.reporting_period[i.reporting_period.length - 1]]
                        ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                    );
                });
                console.log(index2, obj)
                if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                    const { type1, reject1 } = qn_submit_list[index2];
                    const { type, reject } = qn_submit_list[index];
                    let value = null,
                        value2 = null;
                    result_arr = [];
                    let list_arr = []
                    let list_arr2 = []
                    qn_submit_list[index2].response.forEach((i) => {
                        let abc = {}

                        abc['a1'] = i['DPAN641'] + i['DPAN642'] + i['DPAN643'];
                        abc['b1'] = i['DPAN644'] + i['DPAN645'] + i['DPAN646'];
                        abc['c1'] = i['DPAN672'] + i['DPAN673'] + i['DPAN674']
                        abc['d1'] = i['DPAN675'] + i['DPAN676'] + i['DPAN677'];

                        value_arr.forEach((x) => {
                            list_arr2.push({
                                ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                col: getNumberOfColumn(obj),
                                id: qn_submit_list[index].id,
                                category: 1,
                                form_type: 2,
                                diff: false,
                                current: abc[x],
                                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                percentage: '-',
                                type, self,
                                text:
                                    type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? "Unlocked for Resubmission"
                                            : type === 1 && reject === 2
                                                ? "Unlocked for Review Again"
                                                : type === 1
                                                    ? "Pending Review"
                                                    : type === 2
                                                        ? "Pending Approval"
                                                        : type === 3
                                                            ? "Approved & Locked"
                                                            : "Pending Submission",
                                status:
                                    type === 0 && (reject === 0 || reject === null) ? 0 :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? 1
                                            : type === 1 && reject === 2
                                                ? 2
                                                : type === 1
                                                    ? 3
                                                    : type === 2
                                                        ? 4
                                                        : type === 3
                                                            ? 5
                                                            : null
                            })
                        })


                    })
                    qn_submit_list[index].response.forEach((i) => {
                        let abc = {}
                        abc['a1'] = i['DPAN641'] + i['DPAN642'] + i['DPAN643'];
                        abc['b1'] = i['DPAN644'] + i['DPAN645'] + i['DPAN646'];
                        abc['c1'] = i['DPAN672'] + i['DPAN673'] + i['DPAN674']
                        abc['d1'] = i['DPAN675'] + i['DPAN676'] + i['DPAN677'];

                        value_arr.forEach((x) => {
                            console.log(list_arr2)
                            let oldIndex = list_arr2.findIndex((j) => j[x] !== undefined)
                            if (oldIndex !== -1) {
                                value2 = list_arr2[oldIndex][x]

                                list_arr.push({
                                    ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 2,
                                    form_type: 2,
                                    diff: abc[x] === value2 ? null : abc[x] > value2 ? false : true,
                                    current: abc[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: calculatePercentage(abc[x], value2),
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })

                            } else {

                                list_arr.push({
                                    ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 2,
                                    form_type: 2,
                                    diff: false,
                                    current: abc[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: '100%',
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            }
                        })





                    })


                    result = { [obj]: attachObj(list_arr, refobj) };
                } else {

                    const { type, reject } = qn_submit_list[index];
                    let value = null;
                    result_arr = [];
                    let list_arr = []
                    console.log(qn_submit_list[index])
                    if (qn_submit_list[index].response.length) {

                        qn_submit_list[index].response.forEach((i) => {
                            let abc = {}

                            abc['a1'] = i['DPAN641'] + i['DPAN642'] + i['DPAN643'];
                            abc['b1'] = i['DPAN644'] + i['DPAN645'] + i['DPAN646'];
                            abc['c1'] = i['DPAN672'] + i['DPAN673'] + i['DPAN674']
                            abc['d1'] = i['DPAN675'] + i['DPAN676'] + i['DPAN677'];
                            value_arr.forEach((x) => {
                                list_arr.push({
                                    ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    form_type: 2,
                                    category: 1,
                                    diff: false,
                                    current: abc[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: "-",
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            })




                        })
                    }
                    else {
                        list_arr.push({
                            edit: 1,
                            remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            form_type: 2,
                            category: 1,
                            diff: false,
                            current: '-',
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: "-",
                            type, self,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? "Unlocked for Resubmission"
                                        : type === 1 && reject === 2
                                            ? "Unlocked for Review Again"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submission",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? 1
                                        : type === 1 && reject === 2
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        })
                    }



                    result = { [obj]: attachObj(list_arr, refobj) };
                }
            }

            final_result = { ...final_result, ...result };
        });

        Object.values(final_result).forEach((j) => {
            console.log(j, arr)
            let find = Object.values(j).filter(i => (i['a1'] !== undefined));

            Object.values(j).forEach((k) => {
                console.log(k)
                if (k.category !== null && find.length) {
                    let name1 = 'NA'
                    if (k['a1'] || k['a1'] === 0) {
                        name1 = 'Total Number of Hires (Male)'
                    } else if (k['b1'] || k['b1'] === 0) {
                        console.log('xyz')
                        name1 = 'Total Number of Hires (Female)'
                    } else if (k['c1'] || k['c1'] === 0) {
                        name1 = 'Total Employee Turnover (Male)'
                    } else if (k['d1'] || k['d1'] === 0) {
                        name1 = 'Total Employee Turnover (Female)'
                    }


                    k.dp = (name1 === undefined ? 'NA' : name1 + ' ^ ' + unit)



                } else {
                    k.dp = null
                }
            })
        })
        // Object.values(final_result).forEach((y)=>{
        //     y.splice(1, 0, {dp:'Male'})
        //     y.splice(5, 0, {dp:'Female'})
        // })
        console.log(final_result)
        return final_result;
    };
    const getValueByMonthlyHazWaste297 = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr, qn_submit_list, unit, self, refobj
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]


        const [a, b, c, x] = ['DPAH0013', 'DPAH0017', 'DPAH0014', 'DPAH0015']
        const DPAH0013Options = [
            { name: 'Used Engine Oil', id: 1 },
            { name: 'Used Lubricating Oil', id: 2 },
            { name: 'Used Oil Filters', id: 3 },
            { name: 'Used Coolant', id: 4 },
            { name: 'Contaminated PPE', id: 5 },
            { name: 'Others', id: 6 }
        ]
        const DPAH0014Options = [
            { name: 'kg', id: 1 },
            { name: 'litre', id: 2 },
            { name: 'number', id: 3 }
        ]
        const DPAH0017Options = [
            { name: 'Incineration (w/o Energy Recovery)', id: 1 },
            { name: 'Incineration (with Energy Recovery)', id: 2 },
            { name: 'Landfill', id: 3 },
            { name: 'Recycle', id: 4 },
            { name: 'Composting', id: 5 },
            { name: 'Waste Recovery', id: 6 },
            { name: 'Unknown', id: 7 },
            { name: 'Others', id: 8 }
        ]
        let final_result = {};

        arr.forEach((obj) => {
            console.log(obj)
            let result = {
                [obj]: [refobj],
            };
            let result_arr = [];
            let index = qn_submit_list.findIndex((i) => {
                console.log(getRPTextFormat(i.reporting_period), obj);
                return (
                    i.dcfId === dcfId &&

                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(i.reporting_period) === obj
                );
            });
            console.log(index);
            if (index !== -1) {
                let index2 = qn_submit_list.findIndex((i) => {
                    console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                        i.reporting_period[i.reporting_period.length - 1]
                    ), obj)
                    return (
                        i.dcfId === dcfId &&
                        (i.type == 0 ? i.reject === 1 : true) &&
                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(
                            [i.reporting_period[i.reporting_period.length - 1]]
                        ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                    );
                });
                console.log(index2, obj)
                if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                    const { type1, reject1 } = qn_submit_list[index2];
                    const { type, reject } = qn_submit_list[index];
                    let value = null,
                        value2 = null;
                    result_arr = [];
                    let list_arr = []
                    let list_arr2 = []
                    qn_submit_list[index2].response.forEach((i) => {
                        if (i[a] === 6) {
                            i[a] = i['DPAH0013B']
                        }
                        if (i[b] === 8) {
                            i[b] = i['DPAH0017B']
                        }
                        let ind = list_arr2.findIndex((j) => j[a] === i[a] && j[b] === i[b] && j[c] === i[c])
                        if (ind !== -1) {
                            list_arr2[ind][x] += i[x]
                        } else {
                            list_arr2.push(i)
                        }
                    })
                    qn_submit_list[index].response.forEach((i) => {
                        if (i[a] === 6) {
                            i[a] = i['DPAH0013B']
                        }
                        if (i[b] === 8) {
                            i[b] = i['DPAH0017B']
                        }
                        let ind = list_arr.findIndex((j) => j[a] === i[a] && j[b] === i[b] && j[c] === i[c])
                        if (ind !== -1) {
                            list_arr[ind][x] += i[x]
                            list_arr[ind].current = list_arr[ind][x]
                            let value = list_arr[ind][x], value2 = null
                            let oldIndex = list_arr2.findIndex((j) => j[a] === i[a] && j[b] === i[b] && j[c] === i[c])
                            if (oldIndex !== -1) {
                                value2 = list_arr2[oldIndex][x]
                                list_arr[ind].percentage = calculatePercentage(value, value2)
                                list_arr[ind].diff = value === value2 ? null : value > value2 ? false : true
                                list_arr[ind].category = 2
                            }

                        } else {
                            let value = i[x], value2 = null

                            let oldIndex = list_arr2.findIndex((j) => j[a] === i[a] && j[b] === i[b] && j[c] === i[c])
                            if (oldIndex !== -1) {
                                value2 = list_arr2[oldIndex][x]
                                list_arr.push({
                                    ...i, remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 2,
                                    form_type: 2,
                                    diff: value === value2 ? null : value > value2 ? false : true,
                                    current: i[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: calculatePercentage(value, value2),
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            } else {
                                list_arr.push({
                                    ...i, remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 1,
                                    form_type: 2,
                                    diff: false,
                                    current: i[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: '-',
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            }

                        }
                    })
                    console.log(qn_submit_list[index], qn_submit_list[index2])
                    result = { [obj]: attachObj(list_arr, refobj) };
                } else {
                    const { type, reject } = qn_submit_list[index];
                    let value = null;
                    result_arr = [];
                    let list_arr = []
                    if (qn_submit_list[index].response.length) {
                        qn_submit_list[index].response.forEach((i) => {
                            if (i[a] === 6) {
                                i[a] = i['DPAH0013B']
                            }
                            if (i[b] === 8) {
                                i[b] = i['DPAH0017B']
                            }
                            let ind = list_arr.findIndex((j) => j[a] === i[a] && j[b] === i[b] && j[c] === i[c])
                            if (ind !== -1) {
                                list_arr[ind][x] += i[x]
                                list_arr[ind].current = list_arr[ind][x]
                            } else {
                                list_arr.push(
                                    {
                                        ...i, remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        form_type: 2,
                                        category: 1,
                                        diff: false,
                                        current: i[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: "-",
                                        type, self,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                            }
                        })
                    } else {
                        list_arr.push({
                            edit: 1,
                            remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            form_type: 2,
                            category: 1,
                            diff: false,
                            current: '-',
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: "-",
                            type, self,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? "Unlocked for Resubmission"
                                        : type === 1 && reject === 2
                                            ? "Unlocked for Review Again"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submission",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? 1
                                        : type === 1 && reject === 2
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        })
                    }

                    result = { [obj]: attachObj(list_arr, refobj) };
                }
            }

            final_result = { ...final_result, ...result };
        });

        Object.values(final_result).forEach((j) => {
            let find = Object.values(j).filter(i => i[a] !== undefined && i[b] !== undefined && i[c] !== undefined)
            console.log(find, j)
            Object.values(j).forEach((k) => {
                if (k.category !== null && find.length) {
                    let name1 = k[a]
                    let name2 = k[b]
                    if (typeof k[a] === 'number') {
                        name1 = DPAH0013Options.find(i => i.id === k[a]).name
                    }
                    if (typeof k[b] === 'number') {
                        name2 = DPAH0017Options.find(i => i.id === k[b]).name
                    }

                    let name3 = DPAH0014Options.find(i => i.id === k[c]).name
                    k.dp = (name1 === undefined ? 'NA' : name1) + ' / ' + (name2 === undefined ? 'NA' : name2) + ' ^ ' + name3
                } else {
                    k.dp = null
                }
            })
        })
        return final_result;
    };
    const getValueByMonthlyNonHazWaste307 = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr, qn_submit_list, unit, self, refobj
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]


        const [a, b, c, x] = ['DPAH500', 'DPAH504', 'DPAH501', 'DPAH502']
        let DPAH500Options = [
            { name: "Paper - all types", id: 1 },
            { name: "Plastic - all types", id: 2 },
            { name: "Metals - all types", id: 3 },
            { name: "Glass - all types", id: 11 },
            { name: "Wood - all types", id: 13 },
            { name: "Electrical items - WEEE - (ex. printers)", id: 4 },
            { name: "Electrical items - Batteries", id: 5 },
            { name: "General Waste - Mixed Commecial and industrial waste", id: 6 },
            { name: "General Waste - Organic: mixed food and garden waste", id: 7 },
            { name: "General Waste - Organic: garden waste", id: 8 },
            { name: "General Waste - Organic: food and drink waste", id: 9 },
            { name: "General Waste - Household residual waste", id: 10 },
            { name: "Others ( Please include remarks )", id: 12 },
        ];
        const DPAH501Options = [
            { name: 'kg', id: 1 },
            { name: 'litre', id: 2 },
            { name: 'number', id: 3 }
        ]
        const DPAH504Options = [
            { name: 'Incineration (w/o Energy Recovery)', id: 1 },
            { name: 'Incineration (with Energy Recovery)', id: 2 },
            { name: 'Landfill', id: 3 },
            { name: 'Recycle', id: 4 },
            { name: 'Composting', id: 5 },
            { name: 'Waste Recovery', id: 6 },
            { name: 'Unknown', id: 7 },
            { name: 'Others ( Please include remarks )', id: 8 }
        ]
        let final_result = {};

        arr.forEach((obj) => {
            console.log(obj)
            let result = {
                [obj]: [refobj],
            };
            let result_arr = [];
            let index = qn_submit_list.findIndex((i) => {
                console.log(getRPTextFormat(i.reporting_period), obj);
                return (
                    i.dcfId === dcfId &&

                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(i.reporting_period) === obj
                );
            });
            console.log(index);
            if (index !== -1) {
                let index2 = qn_submit_list.findIndex((i) => {
                    console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                        i.reporting_period[i.reporting_period.length - 1]
                    ), obj)
                    return (
                        i.dcfId === dcfId &&
                        (i.type == 0 ? i.reject === 1 : true) &&
                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(
                            [i.reporting_period[i.reporting_period.length - 1]]
                        ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                    );
                });
                console.log(index2, obj)
                if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                    const { type1, reject1 } = qn_submit_list[index2];
                    const { type, reject } = qn_submit_list[index];
                    let value = null,
                        value2 = null;
                    result_arr = [];
                    let list_arr = []
                    let list_arr2 = []
                    qn_submit_list[index2].response.forEach((i) => {
                        if (i[a] === 12) {
                            i[a] = i['DPAH500B']
                        }
                        if (i[b] === 8) {
                            i[b] = i['DPAH504B']
                        }
                        let ind = list_arr2.findIndex((j) => j[a] === i[a] && j[b] === i[b] && j[c] === i[c])
                        if (ind !== -1) {
                            list_arr2[ind][x] += i[x]
                        } else {
                            list_arr2.push(i)
                        }
                    })
                    qn_submit_list[index].response.forEach((i) => {
                        if (i[a] === 12) {
                            i[a] = i['DPAH500B']
                        }
                        if (i[b] === 8) {
                            i[b] = i['DPAH504B']
                        }
                        let ind = list_arr.findIndex((j) => j[a] === i[a] && j[b] === i[b] && j[c] === i[c])
                        if (ind !== -1) {
                            list_arr[ind][x] += i[x]
                            list_arr[ind].current = list_arr[ind][x]
                            let value = list_arr[ind][x], value2 = null
                            let oldIndex = list_arr2.findIndex((j) => j[a] === i[a] && j[b] === i[b] && j[c] === i[c])
                            if (oldIndex !== -1) {
                                value2 = list_arr2[oldIndex][x]
                                list_arr[ind].percentage = calculatePercentage(value, value2)
                                list_arr[ind].diff = value === value2 ? null : value > value2 ? false : true
                                list_arr[ind].category = 2
                            }

                        } else {
                            let value = i[x], value2 = null

                            let oldIndex = list_arr2.findIndex((j) => j[a] === i[a] && j[b] === i[b] && j[c] === i[c])
                            if (oldIndex !== -1) {
                                value2 = list_arr2[oldIndex][x]
                                list_arr.push({
                                    ...i, remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 2,
                                    form_type: 2,
                                    diff: value === value2 ? null : value > value2 ? false : true,
                                    current: i[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: calculatePercentage(value, value2),
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            } else {
                                list_arr.push({
                                    ...i, remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 1,
                                    form_type: 2,
                                    diff: false,
                                    current: i[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: '-',
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            }

                        }
                    })
                    console.log(qn_submit_list[index], qn_submit_list[index2])
                    result = { [obj]: attachObj(list_arr, refobj) };
                } else {
                    const { type, reject } = qn_submit_list[index];
                    let value = null;
                    result_arr = [];
                    let list_arr = []
                    if (qn_submit_list[index].response.length) {
                        qn_submit_list[index].response.forEach((i) => {
                            if (i[a] === 12) {
                                i[a] = i['DPAH500B']
                            }
                            if (i[b] === 8) {
                                i[b] = i['DPAH504B']
                            }
                            let ind = list_arr.findIndex((j) => j[a] === i[a] && j[b] === i[b] && j[c] === i[c])
                            if (ind !== -1) {
                                list_arr[ind][x] += i[x]
                                list_arr[ind].current = list_arr[ind][x]
                            } else {
                                list_arr.push(
                                    {
                                        ...i, remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        form_type: 2,
                                        category: 1,
                                        diff: false,
                                        current: i[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: "-",
                                        type, self,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                            }
                        })
                    } else {
                        list_arr.push({
                            edit: 1,
                            remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            form_type: 2,
                            category: 1,
                            diff: false,
                            current: '-',
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: "-",
                            type, self,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? "Unlocked for Resubmission"
                                        : type === 1 && reject === 2
                                            ? "Unlocked for Review Again"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submission",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? 1
                                        : type === 1 && reject === 2
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        })
                    }

                    result = { [obj]: attachObj(list_arr, refobj) };
                }
            }

            final_result = { ...final_result, ...result };
        });

        Object.values(final_result).forEach((j) => {
            let find = Object.values(j).filter(i => i[a] !== undefined && i[b] !== undefined && i[c] !== undefined)
            console.log(find, j)
            Object.values(j).forEach((k) => {
                if (k.category !== null && find.length) {
                    let name1 = k[a]
                    let name2 = k[b]
                    if (typeof k[a] === 'number') {
                        name1 = DPAH500Options.find(i => i.id === k[a]).name
                    }
                    if (typeof k[b] === 'number') {
                        name2 = DPAH504Options.find(i => i.id === k[b]).name
                    }

                    let name3 = DPAH501Options.find(i => i.id === k[c]).name
                    k.dp = (name1 === undefined ? 'NA' : name1) + ' / ' + (name2 === undefined ? 'NA' : name2) + ' ^ ' + name3
                } else {
                    k.dp = null
                }
            })
        })
        return final_result;
    };
    const getValueByMonthlyCommunicationAnti311 = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr, qn_submit_list, unit, self, refobj
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]
        let land_veh_list = ['Standard Taxi / Sedan', 'Hybrid Vehicle', 'Executive Taxi', 'Dual Purpose 4 x 4 (SUV)', 'Electric Vehicle (EV)', 'Van/ Coach']
        const value_arr = ['a1', 'b1', 'c1', 'd1']
        let final_result = {};

        console.log(tier0, tier1, tier2, tier3)
        arr.forEach((obj) => {
            console.log(obj)
            let result = {
                [obj]: [refobj],
            };
            let result_arr = [];
            let index = qn_submit_list.findIndex((i) => {
                console.log(getRPTextFormat(i.reporting_period) === obj, dcfId === i.dcfId, i.tier0_id === tier0,
                    i.tier1_id === tier1,
                    i.tier2_id === tier2,
                    i.tier3_id === tier3, i.id, i.tier3_id, tier3);
                return (
                    i.dcfId === dcfId &&
                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(i.reporting_period) === obj
                );
            });
            console.log(index, obj);
            if (index !== -1) {
                let index2 = qn_submit_list.findIndex((i) => {
                    console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                        i.reporting_period[i.reporting_period.length - 1]
                    ), obj)
                    return (
                        i.dcfId === dcfId &&
                        (i.type == 0 ? i.reject === 1 : true) &&
                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(
                            [i.reporting_period[i.reporting_period.length - 1]]
                        ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                    );
                });
                console.log(index2, obj)
                if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                    const { type1, reject1 } = qn_submit_list[index2];
                    const { type, reject } = qn_submit_list[index];
                    let value = null,
                        value2 = null;
                    result_arr = [];
                    let list_arr = []
                    let list_arr2 = []
                    qn_submit_list[index2].response.forEach((i) => {
                        let abc = {}

                        abc['a1'] = i['DPAT001'] + i['DPAT002'] + i['DPAT003'];
                        abc['b1'] = i['DPAT004'] + i['DPAT005'] + i['DPAT006'];
                        abc['c1'] = i['DPAT007'] + i['DPAT008'] + i['DPAT009'];
                        abc['d1'] = i['DPAT010'] + i['DPAT011'] + i['DPAT012'];

                        value_arr.forEach((x) => {
                            list_arr2.push({
                                ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                col: getNumberOfColumn(obj),
                                id: qn_submit_list[index].id,
                                category: 1,
                                form_type: 2,
                                diff: false,
                                current: abc[x],
                                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                percentage: '-',
                                type, self,
                                text:
                                    type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? "Unlocked for Resubmission"
                                            : type === 1 && reject === 2
                                                ? "Unlocked for Review Again"
                                                : type === 1
                                                    ? "Pending Review"
                                                    : type === 2
                                                        ? "Pending Approval"
                                                        : type === 3
                                                            ? "Approved & Locked"
                                                            : "Pending Submission",
                                status:
                                    type === 0 && (reject === 0 || reject === null) ? 0 :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? 1
                                            : type === 1 && reject === 2
                                                ? 2
                                                : type === 1
                                                    ? 3
                                                    : type === 2
                                                        ? 4
                                                        : type === 3
                                                            ? 5
                                                            : null
                            })
                        })


                    })
                    qn_submit_list[index].response.forEach((i) => {
                        let abc = {}

                        abc['a1'] = i['DPAT001'] + i['DPAT002'] + i['DPAT003'];
                        abc['b1'] = i['DPAT004'] + i['DPAT005'] + i['DPAT006'];
                        abc['c1'] = i['DPAT007'] + i['DPAT008'] + i['DPAT009'];
                        abc['d1'] = i['DPAT010'] + i['DPAT011'] + i['DPAT012'];

                        value_arr.forEach((x) => {
                            console.log(list_arr2)
                            let oldIndex = list_arr2.findIndex((j) => j[x] !== undefined)
                            if (oldIndex !== -1) {
                                value2 = list_arr2[oldIndex][x]

                                list_arr.push({
                                    ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 2,
                                    form_type: 2,
                                    diff: abc[x] === value2 ? null : abc[x] > value2 ? false : true,
                                    current: abc[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: calculatePercentage(abc[x], value2),
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })

                            } else {

                                list_arr.push({
                                    ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 2,
                                    form_type: 2,
                                    diff: false,
                                    current: abc[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: '100%',
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            }
                        })





                    })


                    result = { [obj]: attachObj(list_arr, refobj) };
                } else {

                    const { type, reject } = qn_submit_list[index];
                    let value = null;
                    result_arr = [];
                    let list_arr = []
                    console.log(qn_submit_list[index])
                    if (qn_submit_list[index].response.length) {

                        qn_submit_list[index].response.forEach((i) => {
                            let abc = {}

                            abc['a1'] = i['DPAT001'] + i['DPAT002'] + i['DPAT003'];
                            abc['b1'] = i['DPAT004'] + i['DPAT005'] + i['DPAT006'];
                            abc['c1'] = i['DPAT007'] + i['DPAT008'] + i['DPAT009'];
                            abc['d1'] = i['DPAT010'] + i['DPAT011'] + i['DPAT012'];
                            value_arr.forEach((x) => {
                                list_arr.push({
                                    ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    form_type: 2,
                                    category: 1,
                                    diff: false,
                                    current: abc[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: "-",
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            })




                        })
                    }
                    else {
                        list_arr.push({
                            edit: 1,
                            remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            form_type: 2,
                            category: 1,
                            diff: false,
                            current: '-',
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: "-",
                            type, self,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? "Unlocked for Resubmission"
                                        : type === 1 && reject === 2
                                            ? "Unlocked for Review Again"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submission",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? 1
                                        : type === 1 && reject === 2
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        })
                    }



                    result = { [obj]: attachObj(list_arr, refobj) };
                }
            }

            final_result = { ...final_result, ...result };
        });

        Object.values(final_result).forEach((j) => {
            console.log(j, arr)
            let find = Object.values(j).filter(i => (i['a1'] !== undefined));

            Object.values(j).forEach((k) => {
                console.log(k)
                if (k.category !== null && find.length) {
                    let name1 = 'NA'
                    if (k['a1'] || k['a1'] === 0) {
                        name1 = 'Total number of employee that the organisation’s anti-corruption policies and procedures have been communicated to.'
                    } else if (k['b1'] || k['b1'] === 0) {
                        console.log('xyz')
                        name1 = 'Total number of employee that have received training on anti-corruption.'
                    } else if (k['c1'] || k['c1'] === 0) {
                        name1 = 'Total number of business partners that the organisation’s anti-corruption policies and procedures have been communicated to.'
                    } else if (k['d1'] || k['d1'] === 0) {
                        name1 = 'Total number of business partners in the country.'
                    }



                    k.dp = (name1 === undefined ? 'NA' : name1 + ' ^ ' + unit)



                } else {
                    k.dp = null
                }
            })
        })
        console.log(final_result)
        return final_result;
    };
    const getValueByMonthlyCarbonCredits310 = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr, qn_submit_list, unit, self, refobj
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]

        const DPAN1115Options = [
            { name: "Nature Based Solution Project (NBS)", id: 1 },
            { name: "Technology Based Solution Project (TBS)", id: 2 }
        ];
        const DPAN1116Options = [
            { name: "VERRA/ VCS", id: 1 },
            { name: "Gold Standard", id: 2 },
            { name: "Global Carbon Council (GCC)", id: 3 },
            { name: "Others", id: 4 }
        ]


        let final_result = {};

        arr.forEach((obj) => {
            console.log(obj)
            let result = {
                [obj]: [refobj],
            };
            let result_arr = [];
            let index = qn_submit_list.findIndex((i) => {
                console.log(getRPTextFormat(i.reporting_period), obj);
                return (
                    i.dcfId === dcfId &&

                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(i.reporting_period) === obj
                );
            });
            console.log(index);
            if (index !== -1) {
                let index2 = qn_submit_list.findIndex((i) => {
                    console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                        i.reporting_period[i.reporting_period.length - 1]
                    ), obj)
                    return (
                        i.dcfId === dcfId &&
                        (i.type == 0 ? i.reject === 1 : true) &&
                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(
                            [i.reporting_period[i.reporting_period.length - 1]]
                        ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                    );
                });
                console.log(index2, obj)
                if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                    const { type1, reject1 } = qn_submit_list[index2];
                    const { type, reject } = qn_submit_list[index];
                    let value = null,
                        value2 = null;
                    result_arr = [];
                    let list_arr = []
                    let list_arr2 = []
                    qn_submit_list[index2].response.forEach((i) => {

                        let ind = list_arr2.findIndex((j) => j['DPAN1115'] === i['DPAN1115'] && j['DPAN1116'] === j['DPAN1116'])

                        if (ind !== -1) {
                            list_arr2[ind]['DPAN1119'] += i['DPAN1119']
                        } else {
                            list_arr2.push(i)
                        }


                    })
                    qn_submit_list[index].response.forEach((i) => {

                        let ind = list_arr.findIndex((j) => j['DPAN1115'] === i['DPAN1115'] && j['DPAN1116'] === j['DPAN1116'])
                        if (ind !== -1) {
                            list_arr[ind]['DPAN1119'] += i['DPAN1119']
                            list_arr[ind].current = list_arr[ind]['DPAN1119']
                            let value = list_arr[ind]['DPAN1119'], value2 = null
                            let oldIndex = list_arr2.findIndex((j) => j['DPAN1115'] === i['DPAN1115'] && j['DPAN1116'] === j['DPAN1116'])
                            if (oldIndex !== -1) {
                                value2 = list_arr2[oldIndex]['DPAN1119']
                                list_arr[ind].percentage = calculatePercentage(value, value2)
                                list_arr[ind].diff = value === value2 ? null : value > value2 ? false : true
                                list_arr[ind].category = 2
                            }

                        } else {
                            let value = i['DPAN1119'], value2 = null
                            let oldIndex = list_arr2.findIndex((j) => j['DPAN1115'] === i['DPAN1115'] && j['DPAN1116'] === j['DPAN1116'])
                            if (oldIndex !== -1) {
                                value2 = list_arr2[oldIndex]['DPAN1119']
                                list_arr.push({
                                    ...i, remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 2,
                                    form_type: 2,
                                    diff: value === value2 ? null : value > value2 ? false : true,
                                    current: i['DPAN1119'],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: calculatePercentage(value, value2),
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            } else {
                                list_arr.push({
                                    ...i, remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 1,
                                    form_type: 2,
                                    diff: false,
                                    current: i['DPAN1119'],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: '-',
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            }

                        }


                    })
                    console.log(qn_submit_list[index], qn_submit_list[index2])
                    result = { [obj]: attachObj(list_arr, refobj) };
                } else {
                    const { type, reject } = qn_submit_list[index];
                    let value = null;
                    result_arr = [];
                    let list_arr = []
                    if (qn_submit_list[index].response.length) {
                        qn_submit_list[index].response.forEach((i) => {

                            let ind = list_arr.findIndex((j) => j['DPAN1115'] === i['DPAN1115'] && j['DPAN1116'] === j['DPAN1116'])
                            if (ind !== -1) {
                                list_arr[ind]['DPAN1119'] += i['DPAN1119']
                                list_arr[ind].current = list_arr[ind]['DPAN1119']
                            } else {
                                list_arr.push({
                                    ...i, remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    form_type: 2,
                                    category: 1,
                                    diff: false,
                                    current: i['DPAN1119'],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: "-",
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            }


                        })
                    }
                    else {
                        list_arr.push({
                            edit: 1,
                            remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            form_type: 2,
                            category: 1,
                            diff: false,
                            current: '-',
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: "-",
                            type, self,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? "Unlocked for Resubmission"
                                        : type === 1 && reject === 2
                                            ? "Unlocked for Review Again"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submission",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? 1
                                        : type === 1 && reject === 2
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        })
                    }



                    result = { [obj]: attachObj(list_arr, refobj) };
                }
            }

            final_result = { ...final_result, ...result };
        });

        Object.values(final_result).forEach((j) => {
            console.log(j)
            let find = Object.values(j).filter(i => (i['DPAN1115'] !== undefined));

            Object.values(j).forEach((k) => {
                if (k.category !== null && find.length) {

                    let name1 = DPAN1115Options.find(i => i.id === k['DPAN1115'])
                    let name2 = DPAN1116Options.find(i => i.id === k['DPAN1116'])

                    k.dp = ((!name1 ? 'NA' : name1.name) + "/" + (!name2 ? 'NA' : name2.name)  + ' ^  tco2e')



                } else {
                    k.dp = null
                }
            })
        })
        console.log(final_result)
        return final_result;
    };
    const getValueByMonthlyParentalLeave294 = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr, qn_submit_list, unit, self, refobj
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]
        let land_veh_list = ['Standard Taxi / Sedan', 'Hybrid Vehicle', 'Executive Taxi', 'Dual Purpose 4 x 4 (SUV)', 'Electric Vehicle (EV)', 'Van/ Coach']
        const value_arr = ['a1', 'b1', 'c1', 'd1']
        let final_result = {};

        console.log(tier0, tier1, tier2, tier3)
        arr.forEach((obj) => {
            console.log(obj)
            let result = {
                [obj]: [refobj],
            };
            let result_arr = [];
            let index = qn_submit_list.findIndex((i) => {
                console.log(getRPTextFormat(i.reporting_period) === obj, dcfId === i.dcfId, i.tier0_id === tier0,
                    i.tier1_id === tier1,
                    i.tier2_id === tier2,
                    i.tier3_id === tier3, i.id, i.tier3_id, tier3);
                return (
                    i.dcfId === dcfId &&
                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(i.reporting_period) === obj
                );
            });
            console.log(index, obj);
            if (index !== -1) {
                let index2 = qn_submit_list.findIndex((i) => {
                    console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                        i.reporting_period[i.reporting_period.length - 1]
                    ), obj)
                    return (
                        i.dcfId === dcfId &&
                        (i.type == 0 ? i.reject === 1 : true) &&
                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(
                            [i.reporting_period[i.reporting_period.length - 1]]
                        ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                    );
                });
                console.log(index2, obj)
                if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                    const { type1, reject1 } = qn_submit_list[index2];
                    const { type, reject } = qn_submit_list[index];
                    let value = null,
                        value2 = null;
                    result_arr = [];
                    let list_arr = []
                    let list_arr2 = []
                    qn_submit_list[index2].response.forEach((i) => {
                        let abc = {}

                        abc['a1'] = i['DPAN1216'] + i['DPAN1217'] ;
                        abc['b1'] = i['DPAN1218'] + i['DPAN1219'] ;
                        abc['c1'] = i['DPAN1220'] + i['DPAN1221'] ;
                        abc['d1'] = i['DPAN1222'] + i['DPAN1223'] ;

                        value_arr.forEach((x) => {
                            list_arr2.push({
                                ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                col: getNumberOfColumn(obj),
                                id: qn_submit_list[index].id,
                                category: 1,
                                form_type: 2,
                                diff: false,
                                current: abc[x],
                                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                percentage: '-',
                                type, self,
                                text:
                                    type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? "Unlocked for Resubmission"
                                            : type === 1 && reject === 2
                                                ? "Unlocked for Review Again"
                                                : type === 1
                                                    ? "Pending Review"
                                                    : type === 2
                                                        ? "Pending Approval"
                                                        : type === 3
                                                            ? "Approved & Locked"
                                                            : "Pending Submission",
                                status:
                                    type === 0 && (reject === 0 || reject === null) ? 0 :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? 1
                                            : type === 1 && reject === 2
                                                ? 2
                                                : type === 1
                                                    ? 3
                                                    : type === 2
                                                        ? 4
                                                        : type === 3
                                                            ? 5
                                                            : null
                            })
                        })


                    })
                    qn_submit_list[index].response.forEach((i) => {
                        let abc = {}

                        abc['a1'] = i['DPAN1216'] + i['DPAN1217'] ;
                        abc['b1'] = i['DPAN1218'] + i['DPAN1219'] ;
                        abc['c1'] = i['DPAN1220'] + i['DPAN1221'] ;
                        abc['d1'] = i['DPAN1222'] + i['DPAN1223'] ;

                        value_arr.forEach((x) => {
                            console.log(list_arr2)
                            let oldIndex = list_arr2.findIndex((j) => j[x] !== undefined)
                            if (oldIndex !== -1) {
                                value2 = list_arr2[oldIndex][x]

                                list_arr.push({
                                    ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 2,
                                    form_type: 2,
                                    diff: abc[x] === value2 ? null : abc[x] > value2 ? false : true,
                                    current: abc[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: calculatePercentage(abc[x], value2),
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })

                            } else {

                                list_arr.push({
                                    ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 2,
                                    form_type: 2,
                                    diff: false,
                                    current: abc[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: '100%',
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            }
                        })





                    })


                    result = { [obj]: attachObj(list_arr, refobj) };
                } else {

                    const { type, reject } = qn_submit_list[index];
                    let value = null;
                    result_arr = [];
                    let list_arr = []
                    console.log(qn_submit_list[index])
                    if (qn_submit_list[index].response.length) {

                        qn_submit_list[index].response.forEach((i) => {
                            let abc = {}
                            abc['a1'] = i['DPAN1216'] + i['DPAN1217'] ;
                            abc['b1'] = i['DPAN1218'] + i['DPAN1219'] ;
                            abc['c1'] = i['DPAN1220'] + i['DPAN1221'] ;
                            abc['d1'] = i['DPAN1222'] + i['DPAN1223'] ;
                            value_arr.forEach((x) => {
                                list_arr.push({
                                    ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    form_type: 2,
                                    category: 1,
                                    diff: false,
                                    current: abc[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: "-",
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            })




                        })
                    }
                    else {
                        list_arr.push({
                            edit: 1,
                            remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            form_type: 2,
                            category: 1,
                            diff: false,
                            current: '-',
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: "-",
                            type, self,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? "Unlocked for Resubmission"
                                        : type === 1 && reject === 2
                                            ? "Unlocked for Review Again"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submission",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? 1
                                        : type === 1 && reject === 2
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        })
                    }



                    result = { [obj]: attachObj(list_arr, refobj) };
                }
            }

            final_result = { ...final_result, ...result };
        });

        Object.values(final_result).forEach((j) => {
            console.log(j, arr)
            let find = Object.values(j).filter(i => (i['a1'] !== undefined));

            Object.values(j).forEach((k) => {
                console.log(k)
                if (k.category !== null && find.length) {
                    let name1 = 'NA'
                    if (k['a1'] || k['a1'] === 0) {
                        name1 = 'Total number of employees that were entitled to parental leave'
                    } else if (k['b1'] || k['b1'] === 0) {
                        console.log('xyz')
                        name1 = 'Total number of employees that took parental leave'
                    } else if (k['c1'] || k['c1'] === 0) {
                        name1 = 'Total number of employees that returned to work in the reporting period after parental leave ended'
                    } else if (k['d1'] || k['d1'] === 0) {
                        name1 = 'Total number of employees that returned to work after parental leave ended that were still employed 12 months after their return to work'
                    }



                    k.dp = (name1 === undefined ? 'NA' : name1 + ' ^ ' + unit)



                } else {
                    k.dp = null
                }
            })
        })
        console.log(final_result)
        return final_result;
    };
    const getValueByMonthlyEmpTrainingHrs295 = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr, qn_submit_list, unit, self, refobj
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]
        let land_veh_list = ['Standard Taxi / Sedan', 'Hybrid Vehicle', 'Executive Taxi', 'Dual Purpose 4 x 4 (SUV)', 'Electric Vehicle (EV)', 'Van/ Coach']
        const value_arr = ['a1', 'b1', 'c1']
        let final_result = {};

        console.log(tier0, tier1, tier2, tier3)
        arr.forEach((obj) => {
            console.log(obj)
            let result = {
                [obj]: [refobj],
            };
            let result_arr = [];
            let index = qn_submit_list.findIndex((i) => {
                console.log(getRPTextFormat(i.reporting_period) === obj, dcfId === i.dcfId, i.tier0_id === tier0,
                    i.tier1_id === tier1,
                    i.tier2_id === tier2,
                    i.tier3_id === tier3, i.id, i.tier3_id, tier3);
                return (
                    i.dcfId === dcfId &&
                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(i.reporting_period) === obj
                );
            });
            console.log(index, obj);
            if (index !== -1) {
                let index2 = qn_submit_list.findIndex((i) => {
                    console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                        i.reporting_period[i.reporting_period.length - 1]
                    ), obj)
                    return (
                        i.dcfId === dcfId &&
                        (i.type == 0 ? i.reject === 1 : true) &&
                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(
                            [i.reporting_period[i.reporting_period.length - 1]]
                        ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                    );
                });
                console.log(index2, obj)
                if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                    const { type1, reject1 } = qn_submit_list[index2];
                    const { type, reject } = qn_submit_list[index];
                    let value = null,
                        value2 = null;
                    result_arr = [];
                    let list_arr = []
                    let list_arr2 = []
                    qn_submit_list[index2].response.forEach((i) => {
                        let abc = {}

                        abc['a1'] = i['DPKN048'] + i['DPKN049']
                        abc['b1'] = i['DPKN050'] + i['DPKN051']
                        abc['c1'] = i['DPKN052'] + i['DPKN053']

                        value_arr.forEach((x) => {
                            list_arr2.push({
                                ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                col: getNumberOfColumn(obj),
                                id: qn_submit_list[index].id,
                                category: 1,
                                form_type: 2,
                                diff: false,
                                current: abc[x],
                                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                percentage: '-',
                                type, self,
                                text:
                                    type === 0 && (reject === 0 || !reject) ? "Drafted" :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? "Unlocked for Resubmission"
                                            : type === 1 && reject === 2
                                                ? "Unlocked for Review Again"
                                                : type === 1
                                                    ? "Pending Review"
                                                    : type === 2
                                                        ? "Pending Approval"
                                                        : type === 3
                                                            ? "Approved & Locked"
                                                            : "Pending Submission",
                                status:
                                    type === 0 && (reject === 0 || !reject) ? 0 :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? 1
                                            : type === 1 && reject === 2
                                                ? 2
                                                : type === 1
                                                    ? 3
                                                    : type === 2
                                                        ? 4
                                                        : type === 3
                                                            ? 5
                                                            : null
                            })
                        })


                    })
                    qn_submit_list[index].response.forEach((i) => {
                        let abc = {}
                        abc['a1'] = i['DPKN048'] + i['DPKN049']
                        abc['b1'] = i['DPKN050'] + i['DPKN051']
                        abc['c1'] = i['DPKN052'] + i['DPKN053']

                        value_arr.forEach((x) => {
                            console.log(list_arr2)
                            let oldIndex = list_arr2.findIndex((j) => j[x] !== undefined)
                            if (oldIndex !== -1) {
                                value2 = list_arr2[oldIndex][x]

                                list_arr.push({
                                    ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 2,
                                    form_type: 2,
                                    diff: abc[x] === value2 ? null : abc[x] > value2 ? false : true,
                                    current: abc[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: calculatePercentage(abc[x], value2),
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || !reject) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || !reject) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })

                            } else {

                                list_arr.push({
                                    ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 2,
                                    form_type: 2,
                                    diff: false,
                                    current: abc[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: '100%',
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || !reject) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || !reject) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            }
                        })





                    })


                    result = { [obj]: attachObj(list_arr, refobj) };
                } else {

                    const { type, reject } = qn_submit_list[index];
                    let value = null;
                    result_arr = [];
                    let list_arr = []
                    console.log(qn_submit_list[index])
                    if (qn_submit_list[index].response.length) {

                        qn_submit_list[index].response.forEach((i) => {
                            let abc = {}

                            abc['a1'] = i['DPKN048'] + i['DPKN049']
                            abc['b1'] = i['DPKN050'] + i['DPKN051']
                            abc['c1'] = i['DPKN052'] + i['DPKN053']
                            value_arr.forEach((x) => {
                                list_arr.push({
                                    ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    form_type: 2,
                                    category: 1,
                                    diff: false,
                                    current: abc[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: "-",
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || !reject) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || !reject) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            })




                        })
                    }
                    else {
                        list_arr.push({
                            edit: 1,
                            remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            form_type: 2,
                            category: 1,
                            diff: false,
                            current: '-',
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: "-",
                            type, self,
                            text:
                                type === 0 && (reject === 0 || !reject) ? "Drafted" :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? "Unlocked for Resubmission"
                                        : type === 1 && reject === 2
                                            ? "Unlocked for Review Again"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submission",
                            status:
                                type === 0 && (reject === 0 || !reject) ? 0 :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? 1
                                        : type === 1 && reject === 2
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        })
                    }



                    result = { [obj]: attachObj(list_arr, refobj) };
                }
            }

            final_result = { ...final_result, ...result };
        });

        Object.values(final_result).forEach((j) => {
            console.log(j, arr)
            let find = Object.values(j).filter(i => (i['a1'] !== undefined));

            Object.values(j).forEach((k) => {
                console.log(k)
                if (k.category !== null && find.length) {
                    let name1 = 'NA'
                    if (k['a1'] || k['a1'] === 0) {
                        name1 = 'Senior Management (Male&Female)'
                    } else if (k['b1'] || k['b1'] === 0) {
                        console.log('xyz')
                        name1 = 'Middle Management (Male&Female)'
                    } else if (k['c1'] || k['c1'] === 0) {
                        name1 = 'Non-management (Male&Female)'
                    }


                    k.dp = (name1 === undefined ? 'NA' : name1 + ' ^ ' + unit)



                } else {
                    k.dp = null
                }
            })
        })
        console.log(final_result)
        return final_result;
    };
    const getValueByMonthlyProportionSpend298 = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr,
        dpnames,
        qn_submit_list, efstdlist, subcat, standardId, unit, self, refobj
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]

  
        subcat['cat1'] =  [
            { name: 'General Contractor', id: 1 },
            { name: 'Long Lead Equipment Vendor', id: 2 },
            { name: 'Consultancy Services', id: 3 },
            { name: 'Others', id: 4 }
        ]
        const [a, x] = dpnames

        let final_result = {};
    
            arr.forEach((obj) => {
                console.log(obj)
                let result = {
                    [obj]: [refobj],
                };
                let result_arr = [];
                let index = qn_submit_list.findIndex((i) => {
                    console.log(getRPTextFormat(i.reporting_period), obj);
                    return (
                        i.dcfId === dcfId && i.standard === standardId &&

                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(i.reporting_period) === obj
                    );
                });
                console.log(index);
                if (index !== -1) {
                    let index2 = qn_submit_list.findIndex((i) => {
                        console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                            i.reporting_period[i.reporting_period.length - 1]
                        ), obj)
                        return (
                            i.dcfId === dcfId && i.standard === standardId &&
                            (i.type == 0 ? i.reject === 1 : true) &&
                            i.tier0_id === tier0 &&
                            i.tier1_id === tier1 &&
                            i.tier2_id === tier2 &&
                            i.tier3_id === tier3 &&
                            getRPTextFormat(
                                [i.reporting_period[i.reporting_period.length - 1]]
                            ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                        );
                    });
                    console.log(index2, obj)
                    if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                        const { type1, reject1 } = qn_submit_list[index2];
                        const { type, reject } = qn_submit_list[index];
                        let value = null,
                            value2 = null;
                        result_arr = [];
                        let list_arr = []
                        let list_arr2 = []
                        qn_submit_list[index2].response.forEach((i) => {
                            let ind = list_arr2.findIndex((j) => j[a] === i[a])
                            if (ind !== -1) {
                                list_arr2[ind][x] += i[x]
                            } else {
                                list_arr2.push(i)
                            }
                        })
                        qn_submit_list[index].response.forEach((i) => {
                            let ind = list_arr.findIndex((j) => j[a] === i[a])
                            if (ind !== -1) {
                                list_arr[ind][x] += i[x]
                                list_arr[ind].current = list_arr[ind][x]
                                let value = list_arr[ind][x], value2 = null
                                let oldIndex = list_arr2.findIndex((j) => j[a] === i[a])
                                if (oldIndex !== -1) {
                                    value2 = list_arr2[oldIndex][x]
                                    list_arr[ind].percentage = calculatePercentage(value, value2)
                                    list_arr[ind].diff = value === value2 ? null : value > value2 ? false : true
                                    list_arr[ind].category = 2
                                }

                            } else {
                                let value = i[x], value2 = null
                                let oldIndex = list_arr2.findIndex((j) => j[a] === i[a])
                                if (oldIndex !== -1) {
                                    value2 = list_arr2[oldIndex][x]
                                    list_arr.push({
                                        ...i, remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        category: 2,
                                        form_type: 2,
                                        diff: value === value2 ? null : value > value2 ? false : true,
                                        current: i[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: calculatePercentage(value, value2),
                                        type, self,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                } else {
                                    list_arr.push({
                                        ...i, remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        category: 1,
                                        form_type: 2,
                                        diff: false,
                                        current: i[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: '-',
                                        type, self,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                }

                            }
                        })
                        console.log(qn_submit_list[index], qn_submit_list[index2])
                        result = { [obj]: attachObj(list_arr, refobj) };
                    } else {
                        const { type, reject } = qn_submit_list[index];
                        let value = null;
                        result_arr = [];
                        let list_arr = []
                        if (qn_submit_list[index].response.length) {
                            qn_submit_list[index].response.forEach((i) => {
                                let ind = list_arr.findIndex((j) => j[a] === i[a])
                                if (ind !== -1) {
                                    list_arr[ind][x] += i[x]
                                    list_arr[ind].current = list_arr[ind][x]
                                } else {
                                    list_arr.push({
                                        ...i, remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        form_type: 2,
                                        category: 1,
                                        diff: false,
                                        current: i[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: "-",
                                        type, self,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                }
                            })
                        }
                        else {
                            list_arr.push({
                                edit: 1,
                                remarks: qn_submit_list[index].return_remarks,
                                col: getNumberOfColumn(obj),
                                id: qn_submit_list[index].id,
                                form_type: 2,
                                category: 1,
                                diff: false,
                                current: '-',
                                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                percentage: "-",
                                type, self,
                                text:
                                    type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? "Unlocked for Resubmission"
                                            : type === 1 && reject === 2
                                                ? "Unlocked for Review Again"
                                                : type === 1
                                                    ? "Pending Review"
                                                    : type === 2
                                                        ? "Pending Approval"
                                                        : type === 3
                                                            ? "Approved & Locked"
                                                            : "Pending Submission",
                                status:
                                    type === 0 && (reject === 0 || reject === null) ? 0 :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? 1
                                            : type === 1 && reject === 2
                                                ? 2
                                                : type === 1
                                                    ? 3
                                                    : type === 2
                                                        ? 4
                                                        : type === 3
                                                            ? 5
                                                            : null
                            })
                        }



                        result = { [obj]: attachObj(list_arr, refobj) };
                    }
                }

                final_result = { ...final_result, ...result };
            });
    
        Object.values(final_result).forEach((j) => {
            let find = Object.values(j).filter(i => i[a] !== undefined)
            Object.values(j).forEach((k) => {
                if (k.category !== null && find.length) {
                    let name1 = subcat['cat1'].find(i => i.id === k[a])

                    k.dp = (name1 === undefined ? 'NA' : name1.name + ' ^ ' + unit)
                } else {
                    k.dp = null
                }
            })
        })
        return final_result;
    };

    const getValueByMonthlyHC = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr,
        dpnames,
        qn_submit_list, self, refobj
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]
        let final_result = {};
        console.log(dpnames);
        arr.forEach((obj) => {
            console.log(obj)
            let result = {
                [obj]: [{
                    ...refobj,
                    remarks: null,
                    category: null,
                    form_type: 3,
                    current: "",
                    percentage: "",
                    text: "Pending Submission",
                    col: getNumberOfColumn(obj),
                    status: null,
                }]
            };
            let result_arr = [];
            let index = qn_submit_list.findIndex((i) => {
                console.log(getRPTextFormat(i.reporting_period), obj);
                return (
                    i.dcfId === dcfId &&
                    (i.type == 0 ? i.reject === 1 : true) &&
                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(i.reporting_period) === obj
                );
            });

            if (index !== -1) {


                const { type, reject } = qn_submit_list[index];
                let value = null;
                result_arr = [];
                let list_arr = [{
                    remarks: qn_submit_list[index].return_remarks,
                    form_type: 3,
                    col: getNumberOfColumn(obj),
                    id: qn_submit_list[index].id,
                    category: 1,
                    diff: false,
                    current: '',
                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                    percentage: "-",
                    type,
                    text:
                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                            (type === 0 && (reject === 1 || reject === 2))
                                ? "Unlocked for Resubmission"
                                : type === 1 && reject === 1
                                    ? "Unlocked for Review Again"
                                    : type === 1
                                        ? "Pending Review"
                                        : type === 2
                                            ? "Pending Approval"
                                            : type === 3
                                                ? "Approved & Locked"
                                                : "Pending Submission",
                    status:
                        type === 0 && (reject === 0 || reject === null) ? 0 :
                            (type === 0 && (reject === 1 || reject === 2))
                                ? 1
                                : type === 1 && reject === 1
                                    ? 2
                                    : type === 1
                                        ? 3
                                        : type === 2
                                            ? 4
                                            : type === 3
                                                ? 5
                                                : null
                }
                ]





                result = { [obj]: attachObj(list_arr, refobj) };

            }
            final_result = { ...final_result, ...result };
        });
        console.log(final_result, arr);
        return final_result;
    };

    const recall = async (dcfId, _id, index1, index2, year) => {
        let qnloc = JSON.parse(JSON.stringify(quantitativesubmission))
        let qnindex = qnloc.findIndex(i => i.id === _id)
        if (qnindex !== -1) {
            setTempLoad(true)
            const { value: accept } = await Swal.fire({
                title: `<div style="overflow:visible;font-size:20px;font-weight:600;margin-top:0px">Recall Submission</div>`,
                html: `<div style="overflow:auto;max-height:200px" >Are you sure want to recall this submission ?</div>`,

                confirmButtonColor: 'red',
                showCancelButton: true,
                denyButtonText: 'No',
                confirmButtonText:
                    'Yes',

            })
            if (accept) {
                let newObj = { type: 1, last_modified_on: DateTime.utc(), last_modified_by: login_data.id }
                APIServices.patch(API.QN_Submission_Edit(_id), newObj).then(async (res) => {
                    qnloc[qnindex] = { ...qnloc[qnindex], ...newObj }
                    setQuantiativeSubmission(qnloc)
                    calculateAndCreateYearData(assignedlist, dcflist, qnloc, stdlist, { cat1: subcat1, cat2: subcat2, cat3: subcat3, cat4: subcat4 }).then((res) => {
                        setRefinedDataBk(res)
                        let expanded_indexs = refineddata.map((i, j) => (i.expanded === true) ? j : null).filter(i => i !== null).map((i) => i)
                        console.log(expanded_indexs)
                        let filteredData = res.filter((i) => { return (i.year === filter.year || filter.year === null) && (filter.entity.includes(getCoverageTextById(i, rawsitelist)) || filter.entity.length === 0) && (filter.form.includes(i.dcfId) || filter.form.length === 0) && (filter.status.includes(i.status) || filter.status.length === 0) })
                        filteredData.forEach((i, j) => {
                            if (expanded_indexs.includes(j)) {
                                i.expanded = true
                            }
                        })
                        setRefinedData(filteredData);
                        setTempLoad(false)
                    }

                    )
                }).catch((e) => {
                    setTempLoad(false)
                })
            } else {
                setTempLoad(false)
            }
        }
    }
    const reviewAction = async (dcfId, _id, index1, index2, year) => {
        let qnloc = JSON.parse(JSON.stringify(quantitativesubmission))
        let qnindex = qnloc.findIndex(i => i.id === _id)
        let dt = DateTime.utc()
        if (qnindex !== -1) {
            setTempLoad(true)
            Swal.fire({
                title: "Are you sure want to submit this for approval?",
                showDenyButton: true,
                confirmButtonText: "Yes",
                confirmButtonColor: 'green',
                denyButtonText: `No`,
                denyButtonColor: 'red'
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    let newObj = { type: 2, reject: 0, reviewer_modified_on: DateTime.utc(), reviewer_modified_by: login_data.id, last_modified_on: DateTime.utc(), last_modified_by: login_data.id }
                    APIServices.patch(API.QN_Submission_Edit(_id), { type: 2, reject: 0 }).then(async (res) => {
                        qnloc[qnindex] = { ...qnloc[qnindex], ...newObj }
                        setQuantiativeSubmission(qnloc)
                        calculateAndCreateYearData(assignedlist, dcflist, qnloc, stdlist, { cat1: subcat1, cat2: subcat2, cat3: subcat3, cat4: subcat4 }).then((res) => {
                            setRefinedDataBk(res)
                            let expanded_indexs = refineddata.map((i, j) => (i.expanded === true) ? j : null).filter(i => i !== null).map((i) => i)
                            console.log(expanded_indexs)
                            let filteredData = res.filter((i) => { return (i.year === filter.year || filter.year === null) && (filter.entity.includes(getCoverageTextById(i, rawsitelist)) || filter.entity.length === 0) && (filter.form.includes(i.dcfId) || filter.form.length === 0) && (filter.status.includes(i.status) || filter.status.length === 0) })
                            filteredData.forEach((i, j) => {
                                if (expanded_indexs.includes(j)) {
                                    i.expanded = true
                                }
                            })
                            setRefinedData(filteredData);
                            setTempLoad(false)
                        }

                        )
                    }).catch((e) => {
                        setTempLoad(false)
                    })

                } else {
                    setTempLoad(false)
                }
            });
        }

    }

    const getValueByMonthlyHC3Sub = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr,
        dpnames,
        qn_submit_list, efstdlist, subcat, standardId, categoryId, self, refobj
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]

        let stdindx = efstdlist.findIndex(i => i.id === standardId)

        const [a, b, x] = dpnames

        let final_result = {};
        if (stdindx !== -1) {
            arr.forEach((obj) => {
                console.log(obj)
                let result = {
                    [obj]: [refobj],
                };
                let result_arr = [];
                let index = qn_submit_list.findIndex((i) => {
                    console.log(getRPTextFormat(i.reporting_period), obj);
                    return (
                        i.dcfId === dcfId && i.standard === standardId &&

                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(i.reporting_period) === obj
                    );
                });
                console.log(index);
                if (index !== -1) {
                    let index2 = qn_submit_list.findIndex((i) => {
                        console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                            i.reporting_period[i.reporting_period.length - 1]
                        ), obj)
                        return (
                            i.dcfId === dcfId && i.standard === standardId &&
                            (i.type == 0 ? i.reject === 1 : true) &&
                            i.tier0_id === tier0 &&
                            i.tier1_id === tier1 &&
                            i.tier2_id === tier2 &&
                            i.tier3_id === tier3 &&
                            getRPTextFormat(
                                [i.reporting_period[i.reporting_period.length - 1]]
                            ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                        );
                    });
                    console.log(index2, obj)
                    if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                        const { type1, reject1 } = qn_submit_list[index2];
                        const { type, reject } = qn_submit_list[index];
                        let value = null,
                            value2 = null;
                        result_arr = [];
                        let list_arr = []
                        let list_arr2 = []
                        qn_submit_list[index2].response.forEach((i) => {
                            let name1 = subcat['cat2'].find(h => h.id === i[a]) === undefined ? 'NA' : subcat['cat2'].find(h => h.id === i[a]).title
                            let name2 = subcat['cat3'].find(h => h.id === i[b]) === undefined ? 'NA' : subcat['cat3'].find(h => h.id === i[b]).title
                            let name3 = subcat['cat1'].find(h => h.id === i['DPA0130']) === undefined ? 'NA' : subcat['cat1'].find(h => h.id === i['DPA0130']).title
                            if (name1.trim().toLowerCase() === 'others') {
                                if (i['DPA0131B'] === 1) {
                                    name1 = i['DPA0131B1']
                                    name2 = i['DPA0131B2']

                                } else {
                                    name1 = 'Others'
                                    name2 = 'NA'
                                }

                            }

                            let ind = list_arr2.findIndex((j) => j['name3'].trim().toLowerCase() === name3.trim().toLowerCase() && j['name1'].trim().toLowerCase() === name1.trim().toLowerCase() && j['name2'].trim().toLowerCase() === name2.trim().toLowerCase())

                            if (ind !== -1) {
                                list_arr2[ind][x] += i[x]
                            } else {
                                list_arr2.push({ ...i, name1, name2, name3 })
                            }
                        })
                        qn_submit_list[index].response.forEach((i) => {
                            let name1 = subcat['cat2'].find(h => h.id === i[a]) === undefined ? 'NA' : subcat['cat2'].find(h => h.id === i[a]).title
                            let name2 = subcat['cat3'].find(h => h.id === i[b]) === undefined ? 'NA' : subcat['cat3'].find(h => h.id === i[b]).title
                            let name3 = subcat['cat1'].find(h => h.id === i['DPA0130']) === undefined ? 'NA' : subcat['cat1'].find(h => h.id === i['DPA0130']).title
                            if (name1.trim().toLowerCase() === 'others') {
                                if (i['DPA0131B'] === 1) {
                                    name1 = i['DPA0131B1']
                                    name2 = i['DPA0131B2']
                                } else {
                                    name1 = 'Others'
                                    name2 = 'NA'
                                }

                            }
                            let ind = list_arr.findIndex((j) => j['name3'].trim().toLowerCase() === name3.trim().toLowerCase() && j['name1'].trim().toLowerCase() === name1.trim().toLowerCase() && j['name2'].trim().toLowerCase() === name2.trim().toLowerCase())

                            if (ind !== -1) {
                                list_arr[ind][x] += i[x]
                                list_arr[ind].current = list_arr[ind][x]
                                let value = list_arr[ind][x], value2 = null
                                let oldIndex = list_arr2.findIndex((j) => j['name3'].trim().toLowerCase() === name3.trim().toLowerCase() && j['name1'].trim().toLowerCase() === name1.trim().toLowerCase() && j['name2'].trim().toLowerCase() === name2.trim().toLowerCase())
                                if (oldIndex !== -1) {
                                    value2 = list_arr2[oldIndex][x]
                                    list_arr[ind].percentage = calculatePercentage(value, value2)
                                    list_arr[ind].diff = value === value2 ? null : value > value2 ? false : true
                                    list_arr[ind].category = 2
                                }

                            } else {
                                let value = i[x], value2 = null
                                let oldIndex = list_arr2.findIndex((j) => j['name3'].trim().toLowerCase() === name3.trim().toLowerCase() && j['name1'].trim().toLowerCase() === name1.trim().toLowerCase() && j['name2'].trim().toLowerCase() === name2.trim().toLowerCase())
                                if (oldIndex !== -1) {
                                    value2 = list_arr2[oldIndex][x]
                                    list_arr.push({
                                        ...i, remarks: qn_submit_list[index].return_remarks, name1, name2, name3,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        category: 2,
                                        form_type: 2,
                                        diff: value === value2 ? null : value > value2 ? false : true,
                                        current: i[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: calculatePercentage(value, value2),
                                        type, self,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                } else {
                                    list_arr.push({
                                        ...i, remarks: qn_submit_list[index].return_remarks, name1, name2, name3,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        category: 1,
                                        form_type: 2,
                                        diff: false,
                                        current: i[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: '-',
                                        type, self,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                }

                            }
                        })
                        console.log(qn_submit_list[index], qn_submit_list[index2])
                        result = { [obj]: attachObj(list_arr, refobj) };
                    } else {
                        const { type, reject } = qn_submit_list[index];
                        let value = null;
                        result_arr = [];
                        let list_arr = []
                        if (qn_submit_list[index].response.length) {
                            qn_submit_list[index].response.forEach((i) => {
                                let name1 = subcat['cat2'].find(h => h.id === i[a]) === undefined ? 'NA' : subcat['cat2'].find(h => h.id === i[a]).title
                                let name2 = subcat['cat3'].find(h => h.id === i[b]) === undefined ? 'NA' : subcat['cat3'].find(h => h.id === i[b]).title
                                let name3 = subcat['cat1'].find(h => h.id === i['DPA0130']) === undefined ? 'NA' : subcat['cat1'].find(h => h.id === i['DPA0130']).title
                                if (name1.trim().toLowerCase() === 'others') {
                                    if (i['DPA0131B'] === 1) {
                                        name1 = i['DPA0131B1']
                                        name2 = i['DPA0131B2']
                                    } else {
                                        name1 = 'Others'
                                        name2 = 'NA'
                                    }

                                }

                                let ind = list_arr.findIndex((j) => j['name3'].trim().toLowerCase() === name3.trim().toLowerCase() && j['name1'].trim().toLowerCase() === name1.trim().toLowerCase() && j['name2'].trim().toLowerCase() === name2.trim().toLowerCase())

                                if (ind !== -1) {
                                    list_arr[ind][x] += i[x]
                                    list_arr[ind].current = list_arr[ind][x]
                                } else {
                                    list_arr.push(
                                        {
                                            ...i, remarks: qn_submit_list[index].return_remarks, name1, name2, name3,
                                            col: getNumberOfColumn(obj),
                                            id: qn_submit_list[index].id,
                                            form_type: 2,
                                            category: 1,
                                            diff: false,
                                            current: i[x],
                                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                            percentage: "-",
                                            type, self,
                                            text:
                                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                    (type === 0 && (reject === 1 || reject === 2))
                                                        ? "Unlocked for Resubmission"
                                                        : type === 1 && reject === 2
                                                            ? "Unlocked for Review Again"
                                                            : type === 1
                                                                ? "Pending Review"
                                                                : type === 2
                                                                    ? "Pending Approval"
                                                                    : type === 3
                                                                        ? "Approved & Locked"
                                                                        : "Pending Submission",
                                            status:
                                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                                    (type === 0 && (reject === 1 || reject === 2))
                                                        ? 1
                                                        : type === 1 && reject === 2
                                                            ? 2
                                                            : type === 1
                                                                ? 3
                                                                : type === 2
                                                                    ? 4
                                                                    : type === 3
                                                                        ? 5
                                                                        : null
                                        })
                                }
                            })
                        } else {
                            list_arr.push({
                                edit: 1,
                                remarks: qn_submit_list[index].return_remarks,
                                col: getNumberOfColumn(obj),
                                id: qn_submit_list[index].id,
                                form_type: 2,
                                category: 1,
                                diff: false,
                                current: '-',
                                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                percentage: "-",
                                type, self,
                                text:
                                    type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? "Unlocked for Resubmission"
                                            : type === 1 && reject === 2
                                                ? "Unlocked for Review Again"
                                                : type === 1
                                                    ? "Pending Review"
                                                    : type === 2
                                                        ? "Pending Approval"
                                                        : type === 3
                                                            ? "Approved & Locked"
                                                            : "Pending Submission",
                                status:
                                    type === 0 && (reject === 0 || reject === null) ? 0 :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? 1
                                            : type === 1 && reject === 2
                                                ? 2
                                                : type === 1
                                                    ? 3
                                                    : type === 2
                                                        ? 4
                                                        : type === 3
                                                            ? 5
                                                            : null
                            })
                        }

                        result = { [obj]: attachObj(list_arr, refobj) };
                    }
                }

                final_result = { ...final_result, ...result };
            });
        } else {
            arr.forEach((obj) => {
                let result = {
                    [obj]: [],
                };
                final_result = { ...final_result, ...result };
            })

        }

        Object.values(final_result).forEach((j) => {
            let find = Object.values(j).filter(i => i[a] !== undefined && i[b] !== undefined)
            console.log(find, j)
            Object.values(j).forEach((k) => {
                if (k.category !== null && find.length) {

                    k.dp = k.name3 + '/' + k.name1 + ' ^ ' + k.name2


                } else {
                    k.dp = null
                }
            })
        })
        console.log(final_result)
        return final_result;
    };
    const getValueByMonthlyElectricity287 = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr,
        dpnames,
        qn_submit_list, efstdlist, subcat, standardId, categoryId, self, refobj
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]

        let stdindx = efstdlist.findIndex(i => i.id === standardId)

        const [a, b, c, x] = dpnames
        console.log(stdindx, standardId)
        let final_result = {};

        arr.forEach((obj) => {
            console.log(obj)
            let result = {
                [obj]: [refobj],
            };
            let result_arr = [];
            let index = qn_submit_list.findIndex((i) => {
                console.log(getRPTextFormat(i.reporting_period), obj);
                return (
                    i.dcfId === dcfId && i.standard === standardId &&

                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(i.reporting_period) === obj
                );
            });
            console.log(index);
            if (index !== -1) {
                let index2 = qn_submit_list.findIndex((i) => {
                    console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                        i.reporting_period[i.reporting_period.length - 1]
                    ), obj)
                    return (
                        i.dcfId === dcfId && i.standard === standardId &&
                        (i.type == 0 ? i.reject === 1 : true) &&
                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(
                            [i.reporting_period[i.reporting_period.length - 1]]
                        ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                    );
                });
                console.log(index2, obj)
                if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                    const { type1, reject1 } = qn_submit_list[index2];
                    const { type, reject } = qn_submit_list[index];
                    let value = null,
                        value2 = null;
                    result_arr = [];
                    let list_arr = []
                    let list_arr2 = []
                    qn_submit_list[index2].response.forEach((i) => {
                        let name1 = subcat['cat1'].find(h => h.id === i[a]) === undefined ? 'NA' : subcat['cat1'].find(h => h.id === i[a]).title
                        let name2 = '', name3 = '', energyval = 0
                        if (!name1.trim().toLowerCase().includes('grid')) {
                            let name_1 = subcat['cat2'].find(h => h.id === i[b]) === undefined ? 'NA' : subcat['cat2'].find(h => h.id === i[b]).title
                            let name_2 = subcat['cat3'].find(h => h.id === i[c]) === undefined ? 'NA' : subcat['cat3'].find(h => h.id === i[c]).title
                            if (name_1.trim().toLowerCase().includes('others')) {
                                name2 = 'Others (' + i['DPAN095A1'] + ')'
                            } else {
                                name2 = name_1
                            }
                            if (name_2.trim().toLowerCase().includes('others')) {
                                name3 = 'Others (' + i['DPAN095B1'] + ')'
                            } else {
                                name3 = name_2
                            }
                            energyval = i['DPAN098B']
                        } else {
                            energyval = i['DPAN098A']
                        }


                        let ind = list_arr2.findIndex((j) => j['name3'].trim().toLowerCase() === name3.trim().toLowerCase() && j['name1'].trim().toLowerCase() === name1.trim().toLowerCase() && j['name2'].trim().toLowerCase() === name2.trim().toLowerCase())

                        if (ind !== -1) {
                            list_arr2[ind][x] += energyval
                        } else {
                            list_arr2.push({ ...i, name1, name2, name3, energyval })
                        }
                    })
                    qn_submit_list[index].response.forEach((i) => {
                        let name1 = subcat['cat1'].find(h => h.id === i[a]) === undefined ? 'NA' : subcat['cat1'].find(h => h.id === i[a]).title
                        let name2 = '', name3 = '', energyval = 0
                        if (!name1.trim().toLowerCase().includes('grid')) {
                            let name_1 = subcat['cat2'].find(h => h.id === i[b]) === undefined ? 'NA' : subcat['cat2'].find(h => h.id === i[b]).title
                            let name_2 = subcat['cat3'].find(h => h.id === i[c]) === undefined ? 'NA' : subcat['cat3'].find(h => h.id === i[c]).title
                            if (name_1.trim().toLowerCase().includes('others')) {
                                name2 = 'Others (' + i['DPAN095A1'] + ')'
                            } else {
                                name2 = name_1
                            }
                            if (name_2.trim().toLowerCase().includes('others')) {
                                name3 = 'Others (' + i['DPAN095B1'] + ')'
                            } else {
                                name3 = name_2
                            }
                            energyval = i['DPAN098B']
                        } else {
                            energyval = i['DPAN098A']
                        }
                        let ind = list_arr.findIndex((j) => j['name3'].trim().toLowerCase() === name3.trim().toLowerCase() && j['name1'].trim().toLowerCase() === name1.trim().toLowerCase() && j['name2'].trim().toLowerCase() === name2.trim().toLowerCase())

                        if (ind !== -1) {
                            list_arr[ind][x] += energyval
                            list_arr[ind].current = list_arr[ind][x]
                            let value = list_arr[ind][x], value2 = null
                            let oldIndex = list_arr2.findIndex((j) => j['name3'].trim().toLowerCase() === name3.trim().toLowerCase() && j['name1'].trim().toLowerCase() === name1.trim().toLowerCase() && j['name2'].trim().toLowerCase() === name2.trim().toLowerCase())
                            if (oldIndex !== -1) {
                                value2 = list_arr2[oldIndex][x]
                                list_arr[ind].percentage = calculatePercentage(value, value2)
                                list_arr[ind].diff = value === value2 ? null : value > value2 ? false : true
                                list_arr[ind].category = 2
                            }

                        } else {
                            let value = energyval, value2 = null
                            let oldIndex = list_arr2.findIndex((j) => j['name3'].trim().toLowerCase() === name3.trim().toLowerCase() && j['name1'].trim().toLowerCase() === name1.trim().toLowerCase() && j['name2'].trim().toLowerCase() === name2.trim().toLowerCase())
                            if (oldIndex !== -1) {
                                value2 = list_arr2[oldIndex][x]
                                list_arr.push({
                                    ...i, remarks: qn_submit_list[index].return_remarks, name1, name2, name3, energyval,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 2,
                                    form_type: 2,
                                    diff: value === value2 ? null : value > value2 ? false : true,
                                    current: energyval,
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: calculatePercentage(value, value2),
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            } else {
                                list_arr.push({
                                    ...i, remarks: qn_submit_list[index].return_remarks, name1, name2, name3, energyval,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 1,
                                    form_type: 2,
                                    diff: false,
                                    current: energyval,
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: '-',
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            }

                        }
                    })
                    console.log(qn_submit_list[index], qn_submit_list[index2])
                    result = { [obj]: attachObj(list_arr, refobj) };
                } else {
                    const { type, reject } = qn_submit_list[index];
                    let value = null;
                    result_arr = [];
                    let list_arr = []
                    if (qn_submit_list[index].response.length) {
                        qn_submit_list[index].response.forEach((i) => {
                            let name1 = subcat['cat1'].find(h => h.id === i[a]) === undefined ? 'NA' : subcat['cat1'].find(h => h.id === i[a]).title
                            let name2 = '', name3 = '', energyval = 0
                            if (!name1.trim().toLowerCase().includes('grid')) {
                                let name_1 = subcat['cat2'].find(h => h.id === i[b]) === undefined ? 'NA' : subcat['cat2'].find(h => h.id === i[b]).title
                                let name_2 = subcat['cat3'].find(h => h.id === i[c]) === undefined ? 'NA' : subcat['cat3'].find(h => h.id === i[c]).title
                                if (name_1.trim().toLowerCase().includes('others')) {
                                    name2 = 'Others (' + i['DPAN095A1'] + ')'
                                } else {
                                    name2 = name_1
                                }
                                if (name_2.trim().toLowerCase().includes('others')) {
                                    name3 = 'Others (' + i['DPAN095B1'] + ')'
                                } else {
                                    name3 = name_2
                                }
                                energyval = i['DPAN098B']
                            } else {
                                energyval = i['DPAN098A']
                            }
                            console.log(name1, name2, name3, energyval)
                            let ind = list_arr.findIndex((j) => j['name3'].trim().toLowerCase() === name3.trim().toLowerCase() && j['name1'].trim().toLowerCase() === name1.trim().toLowerCase() && j['name2'].trim().toLowerCase() === name2.trim().toLowerCase())

                            if (ind !== -1) {
                                list_arr[ind][x] += energyval
                                list_arr[ind].current = list_arr[ind][x]
                            } else {
                                list_arr.push(
                                    {
                                        ...i, remarks: qn_submit_list[index].return_remarks, name1, name2, name3, energyval,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        form_type: 2,
                                        category: 1,
                                        diff: false,
                                        current: energyval,
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: "-",
                                        type, self,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                            }
                        })
                    } else {
                        list_arr.push({
                            edit: 1,
                            remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            form_type: 2,
                            category: 1,
                            diff: false,
                            current: '-',
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: "-",
                            type, self,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? "Unlocked for Resubmission"
                                        : type === 1 && reject === 2
                                            ? "Unlocked for Review Again"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submission",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? 1
                                        : type === 1 && reject === 2
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        })
                    }

                    result = { [obj]: attachObj(list_arr, refobj) };
                }
            }

            final_result = { ...final_result, ...result };
        });


        Object.values(final_result).forEach((j) => {
            let find = Object.values(j).filter(i => i[a] !== undefined)
            console.log(find, j)
            Object.values(j).forEach((k) => {
                if (k.category !== null && find.length) {
                    if (k['name2'].trim().length) {
                        k.dp = k.name1 + '/' + k.name2 + '/' + k.name3 + ' ^ ' + 'kWh'
                    } else {
                        k.dp = k.name1 + ' ^ ' + 'kWh'
                    }



                } else {
                    k.dp = null
                }
            })
        })
        console.log(final_result)
        return final_result;
    };
    const getValueByMonthlyHC1Sub = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr,
        dpnames,
        qn_submit_list, efstdlist, subcat, standardId, unit, self, refobj
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]

        let stdindx = efstdlist.findIndex(i => i.id === standardId)

        const [a, x] = dpnames

        let final_result = {};
        if (stdindx !== -1) {
            arr.forEach((obj) => {
                console.log(obj)
                let result = {
                    [obj]: [refobj],
                };
                let result_arr = [];
                let index = qn_submit_list.findIndex((i) => {
                    console.log(getRPTextFormat(i.reporting_period), obj);
                    return (
                        i.dcfId === dcfId && i.standard === standardId &&
                        (i.type == 0 ? i.reject === 1 : true) &&
                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(i.reporting_period) === obj
                    );
                });
                console.log(index);
                if (index !== -1) {
                    let index2 = qn_submit_list.findIndex((i) => {
                        console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                            i.reporting_period[i.reporting_period.length - 1]
                        ), obj)
                        return (
                            i.dcfId === dcfId && i.standard === standardId &&
                            (i.type == 0 ? i.reject === 1 : true) &&
                            i.tier0_id === tier0 &&
                            i.tier1_id === tier1 &&
                            i.tier2_id === tier2 &&
                            i.tier3_id === tier3 &&
                            getRPTextFormat(
                                [i.reporting_period[i.reporting_period.length - 1]]
                            ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                        );
                    });
                    console.log(index2, obj)
                    if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                        const { type1, reject1 } = qn_submit_list[index2];
                        const { type, reject } = qn_submit_list[index];
                        let value = null,
                            value2 = null;
                        result_arr = [];
                        let list_arr = []
                        let list_arr2 = []
                        qn_submit_list[index2].response.forEach((i) => {
                            let ind = list_arr2.findIndex((j) => j[a] === i[a])
                            if (ind !== -1) {
                                list_arr2[ind][x] += i[x]
                            } else {
                                list_arr2.push(i)
                            }
                        })
                        qn_submit_list[index].response.forEach((i) => {
                            let ind = list_arr.findIndex((j) => j[a] === i[a])
                            if (ind !== -1) {
                                list_arr[ind][x] += i[x]
                                list_arr[ind].current = list_arr[ind][x]
                                let value = list_arr[ind][x], value2 = null
                                let oldIndex = list_arr2.findIndex((j) => j[a] === i[a])
                                if (oldIndex !== -1) {
                                    value2 = list_arr2[oldIndex][x]
                                    list_arr[ind].percentage = calculatePercentage(value, value2)
                                    list_arr[ind].diff = value === value2 ? null : value > value2 ? false : true
                                    list_arr[ind].category = 2
                                }

                            } else {
                                let value = i[x], value2 = null
                                let oldIndex = list_arr2.findIndex((j) => j[a] === i[a])
                                if (oldIndex !== -1) {
                                    value2 = list_arr2[oldIndex][x]
                                    list_arr.push({
                                        ...i, remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        category: 2,
                                        form_type: 2,
                                        diff: value === value2 ? null : value > value2 ? false : true,
                                        current: i[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: calculatePercentage(value, value2),
                                        type,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                } else {
                                    list_arr.push({
                                        ...i, remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        category: 1,
                                        form_type: 2,
                                        diff: false,
                                        current: i[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: '-',
                                        type,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                }

                            }
                        })
                        console.log(qn_submit_list[index], qn_submit_list[index2])
                        result = { [obj]: attachObj(list_arr, refobj) };
                    } else {
                        const { type, reject } = qn_submit_list[index];
                        let value = null;
                        result_arr = [];
                        let list_arr = []
                        if (qn_submit_list[index].response.length) {
                            qn_submit_list[index].response.forEach((i) => {
                                let ind = list_arr.findIndex((j) => j[a] === i[a])
                                if (ind !== -1) {
                                    list_arr[ind][x] += i[x]
                                    list_arr[ind].current = list_arr[ind][x]
                                } else {
                                    list_arr.push({
                                        ...i, remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        form_type: 2,
                                        category: 1,
                                        diff: false,
                                        current: i[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: "-",
                                        type,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                }
                            })
                        }
                        else {
                            list_arr.push({
                                edit: 1,
                                remarks: qn_submit_list[index].return_remarks,
                                col: getNumberOfColumn(obj),
                                id: qn_submit_list[index].id,
                                form_type: 2,
                                category: 1,
                                diff: false,
                                current: '-',
                                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                percentage: "-",
                                type,
                                text:
                                    type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? "Unlocked for Resubmission"
                                            : type === 1 && reject === 2
                                                ? "Unlocked for Review Again"
                                                : type === 1
                                                    ? "Pending Review"
                                                    : type === 2
                                                        ? "Pending Approval"
                                                        : type === 3
                                                            ? "Approved & Locked"
                                                            : "Pending Submission",
                                status:
                                    type === 0 && (reject === 0 || reject === null) ? 0 :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? 1
                                            : type === 1 && reject === 2
                                                ? 2
                                                : type === 1
                                                    ? 3
                                                    : type === 2
                                                        ? 4
                                                        : type === 3
                                                            ? 5
                                                            : null
                            })
                        }




                        result = { [obj]: attachObj(list_arr, refobj) };
                    }
                }

                final_result = { ...final_result, ...result };
            });
        } else {
            arr.forEach((obj) => {
                let result = {
                    [obj]: [],
                };
                final_result = { ...final_result, ...result };
            })

        }
        Object.values(final_result).forEach((j) => {
            let find = Object.values(j).filter(i => i[a] !== undefined)
            Object.values(j).forEach((k) => {
                if (k.category !== null && find.length) {
                    let name1 = subcat['cat1'].find(i => i.id === k[a])

                    k.dp = (name1 === undefined ? 'NA' : name1.title + ' ^ ' + unit)
                } else {
                    k.dp = null
                }
            })
        })
        return final_result;
    };
    const getValueByMonthlyWaterD286 = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr, qn_submit_list, unit, self, refobj
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]
        const [a, x] = ['DPAN0052', 'DPAN0053']
        const DPAN0052Options = [
            { name: "Surface water", id: 1 },
            { name: "Ground Water", id: 2 },
            { name: "Sea Water", id: 3 },
            { name: "Third-Party Water", id: 5 },
            { name: "Water Reuse", id: 6 },
            { name: "Water Recycled", id: 7 },
        ];


        let final_result = {};

        arr.forEach((obj) => {
            console.log(obj)
            let result = {
                [obj]: [refobj],
            };
            let result_arr = [];
            let index = qn_submit_list.findIndex((i) => {
                console.log(getRPTextFormat(i.reporting_period), obj);
                return (
                    i.dcfId === dcfId &&

                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(i.reporting_period) === obj
                );
            });
            console.log(index);
            if (index !== -1) {
                let index2 = qn_submit_list.findIndex((i) => {
                    console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                        i.reporting_period[i.reporting_period.length - 1]
                    ), obj)
                    return (
                        i.dcfId === dcfId &&
                        (i.type == 0 ? i.reject === 1 : true) &&
                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(
                            [i.reporting_period[i.reporting_period.length - 1]]
                        ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                    );
                });
                console.log(index2, obj)
                if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                    const { type1, reject1 } = qn_submit_list[index2];
                    const { type, reject } = qn_submit_list[index];
                    let value = null,
                        value2 = null;
                    result_arr = [];
                    let list_arr = []
                    let list_arr2 = []
                    qn_submit_list[index2].response.forEach((i) => {
                        let ind = list_arr2.findIndex((j) => j[a] === i[a])
                        if (ind !== -1) {
                            list_arr2[ind][x] += i[x]
                        } else {
                            list_arr2.push(i)
                        }
                    })
                    qn_submit_list[index].response.forEach((i) => {
                        let ind = list_arr.findIndex((j) => j[a] === i[a])
                        if (ind !== -1) {
                            list_arr[ind][x] += i[x]
                            list_arr[ind].current = list_arr[ind][x]
                            let value = list_arr[ind][x], value2 = null
                            let oldIndex = list_arr2.findIndex((j) => j[a] === i[a])
                            if (oldIndex !== -1) {
                                value2 = list_arr2[oldIndex][x]
                                list_arr[ind].percentage = calculatePercentage(value, value2)
                                list_arr[ind].diff = value === value2 ? null : value > value2 ? false : true
                                list_arr[ind].category = 2
                            }

                        } else {
                            let value = i[x], value2 = null
                            let oldIndex = list_arr2.findIndex((j) => j[a] === i[a])
                            if (oldIndex !== -1) {
                                value2 = list_arr2[oldIndex][x]
                                list_arr.push({
                                    ...i, remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 2,
                                    form_type: 2,
                                    diff: value === value2 ? null : value > value2 ? false : true,
                                    current: i[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: calculatePercentage(value, value2),
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            } else {
                                list_arr.push({
                                    ...i, remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 1,
                                    form_type: 2,
                                    diff: false,
                                    current: i[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: '-',
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            }

                        }
                    })
                    console.log(qn_submit_list[index], qn_submit_list[index2])
                    result = { [obj]: attachObj(list_arr, refobj) };
                } else {
                    const { type, reject } = qn_submit_list[index];
                    let value = null;
                    result_arr = [];
                    let list_arr = []
                    if (qn_submit_list[index].response.length) {
                        qn_submit_list[index].response.forEach((i) => {
                            let ind = list_arr.findIndex((j) => j[a] === i[a])
                            if (ind !== -1) {
                                list_arr[ind][x] += i[x]
                                list_arr[ind].current = list_arr[ind][x]
                            } else {
                                list_arr.push({
                                    ...i, remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    form_type: 2,
                                    category: 1,
                                    diff: false,
                                    current: i[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: "-",
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            }
                        })
                    }
                    else {
                        list_arr.push({
                            edit: 1,
                            remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            form_type: 2,
                            category: 1,
                            diff: false,
                            current: '-',
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: "-",
                            type, self,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? "Unlocked for Resubmission"
                                        : type === 1 && reject === 2
                                            ? "Unlocked for Review Again"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submission",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? 1
                                        : type === 1 && reject === 2
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        })
                    }



                    result = { [obj]: attachObj(list_arr, refobj) };
                }
            }

            final_result = { ...final_result, ...result };
        });

        Object.values(final_result).forEach((j) => {
            let find = Object.values(j).filter(i => i[a] !== undefined)
            Object.values(j).forEach((k) => {
                if (k.category !== null && find.length) {
                    let name1 = DPAN0052Options.find(i => i.id === k[a])

                    k.dp = (name1 === undefined ? 'NA' : 'Quantity of ' + name1.name + ' Discharge' + ' ^ ' + unit)
                } else {
                    k.dp = null
                }
            })
        })
        return final_result;
    };
    const getValueByMonthlyWaterW285 = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr, qn_submit_list, unit, self, refobj
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]

        const DPAN0048Options = [
            { name: "Self Generated", id: 6 },

            { name: "Third-Party Water", id: 5 },
        ];
        const DPAN1158Options = [
            { name: "Groundwater Wells Operated", id: 1 },

            { name: "Effluent/ Sewage Treatment Recycle", id: 2 },
            { name: 'Rainwater Harvesting', id: 3 },
            { name: 'Others', id: 99 }
        ];
        const DPAN1200Options = [
            { name: "Surface water", id: 1 },
            { name: "Ground Water", id: 2 },
            { name: "Sea Water", id: 3 },
            { name: 'Imported Water from Industrial District', id: 8 },
            { name: 'Unknown', id: 9 }
        ];

        let final_result = {};

        arr.forEach((obj) => {
            console.log(obj)
            let result = {
                [obj]: [refobj],
            };
            let result_arr = [];
            let index = qn_submit_list.findIndex((i) => {
                console.log(getRPTextFormat(i.reporting_period), obj);
                return (
                    i.dcfId === dcfId &&

                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(i.reporting_period) === obj
                );
            });
            console.log(index);
            if (index !== -1) {
                let index2 = qn_submit_list.findIndex((i) => {
                    console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                        i.reporting_period[i.reporting_period.length - 1]
                    ), obj)
                    return (
                        i.dcfId === dcfId &&
                        (i.type == 0 ? i.reject === 1 : true) &&
                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(
                            [i.reporting_period[i.reporting_period.length - 1]]
                        ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                    );
                });
                console.log(index2, obj)
                if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                    const { type1, reject1 } = qn_submit_list[index2];
                    const { type, reject } = qn_submit_list[index];
                    let value = null,
                        value2 = null;
                    result_arr = [];
                    let list_arr = []
                    let list_arr2 = []
                    qn_submit_list[index2].response.forEach((i) => {
                        if (i['DPAN0048'] === 6) {
                            let ind = list_arr2.findIndex((j) => j['DPAN1158'] === i['DPAN1158'])

                            if (ind !== -1) {
                                list_arr2[ind]['DPAN1159'] += i['DPAN1159']
                            } else {
                                list_arr2.push(i)
                            }
                        } else if (i['DPAN0048'] === 5) {
                            let ind = list_arr2.findIndex((j) => j['DPAN1200'] === i['DPAN1200'])

                            if (ind !== -1) {
                                list_arr2[ind]['DPAN1161'] += i['DPAN1161']
                            } else {
                                list_arr2.push(i)
                            }
                        }

                    })
                    qn_submit_list[index].response.forEach((i) => {
                        if (i['DPAN0048'] === 6) {
                            let ind = list_arr.findIndex((j) => j['DPAN1159'] === i['DPAN1159'])
                            if (ind !== -1) {
                                list_arr[ind]['DPAN1160'] += i['DPAN1160']
                                list_arr[ind].current = list_arr[ind]['DPAN1160']
                                let value = list_arr[ind]['DPAN1160'], value2 = null
                                let oldIndex = list_arr2.findIndex((j) => j['DPAN1159'] === i['DPAN1159'])
                                if (oldIndex !== -1) {
                                    value2 = list_arr2[oldIndex]['DPAN1160']
                                    list_arr[ind].percentage = calculatePercentage(value, value2)
                                    list_arr[ind].diff = value === value2 ? null : value > value2 ? false : true
                                    list_arr[ind].category = 2
                                }

                            } else {
                                let value = i['DPAN1160'], value2 = null
                                let oldIndex = list_arr2.findIndex((j) => j['DPAN1159'] === i['DPAN1159'])
                                if (oldIndex !== -1) {
                                    value2 = list_arr2[oldIndex]['DPAN1160']
                                    list_arr.push({
                                        ...i, remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        category: 2,
                                        form_type: 2,
                                        diff: value === value2 ? null : value > value2 ? false : true,
                                        current: i['DPAN1160'],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: calculatePercentage(value, value2),
                                        type, self,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                } else {
                                    list_arr.push({
                                        ...i, remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        category: 1,
                                        form_type: 2,
                                        diff: false,
                                        current: i['DPAN1160'],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: '-',
                                        type, self,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                }

                            }
                        } else if (i['DPAN0048'] === 5) {
                            let ind = list_arr.findIndex((j) => j['DPAN1200'] === i['DPAN1200'])
                            if (ind !== -1) {
                                list_arr[ind]['DPAN1161'] += i['DPAN1161']
                                list_arr[ind].current = list_arr[ind]['DPAN1161']
                                let value = list_arr[ind]['DPAN1161'], value2 = null
                                let oldIndex = list_arr2.findIndex((j) => j['DPAN1200'] === i['DPAN1200'])
                                if (oldIndex !== -1) {
                                    value2 = list_arr2[oldIndex]['DPAN1161']
                                    list_arr[ind].percentage = calculatePercentage(value, value2)
                                    list_arr[ind].diff = value === value2 ? null : value > value2 ? false : true
                                    list_arr[ind].category = 2
                                }

                            } else {
                                let value = i['DPAN1161'], value2 = null
                                let oldIndex = list_arr2.findIndex((j) => j['DPAN1200'] === i['DPAN1200'])
                                if (oldIndex !== -1) {
                                    value2 = list_arr2[oldIndex]['DPAN1161']
                                    list_arr.push({
                                        ...i, remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        category: 2,
                                        form_type: 2,
                                        diff: value === value2 ? null : value > value2 ? false : true,
                                        current: i['DPAN1161'],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: calculatePercentage(value, value2),
                                        type, self,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                } else {
                                    list_arr.push({
                                        ...i, remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        category: 1,
                                        form_type: 2,
                                        diff: false,
                                        current: i['DPAN1161'],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: '-',
                                        type, self,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                }

                            }
                        }

                    })
                    console.log(qn_submit_list[index], qn_submit_list[index2])
                    result = { [obj]: attachObj(list_arr, refobj) };
                } else {
                    const { type, reject } = qn_submit_list[index];
                    let value = null;
                    result_arr = [];
                    let list_arr = []
                    if (qn_submit_list[index].response.length) {
                        qn_submit_list[index].response.forEach((i) => {
                            if (i['DPAN0048'] === 6) {
                                let ind = list_arr.findIndex((j) => j['DPAN1159'] === i['DPAN1159'])
                                if (ind !== -1) {
                                    list_arr[ind]['DPAN1160'] += i['DPAN1160']
                                    list_arr[ind].current = list_arr[ind]['DPAN1160']
                                } else {
                                    list_arr.push({
                                        ...i, remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        form_type: 2,
                                        category: 1,
                                        diff: false,
                                        current: i['DPAN1160'],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: "-",
                                        type, self,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                }
                            } else if (i['DPAN0048'] === 5) {
                                let ind = list_arr.findIndex((j) => j['DPAN1200'] === i['DPAN1200'])
                                if (ind !== -1) {
                                    list_arr[ind]['DPAN1161'] += i['DPAN1161']
                                    list_arr[ind].current = list_arr[ind]['DPAN1161']
                                } else {
                                    list_arr.push({
                                        ...i, remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        form_type: 2,
                                        category: 1,
                                        diff: false,
                                        current: i['DPAN1161'],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: "-",
                                        type, self,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                }
                            }

                        })
                    }
                    else {
                        list_arr.push({
                            edit: 1,
                            remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            form_type: 2,
                            category: 1,
                            diff: false,
                            current: '-',
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: "-",
                            type, self,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? "Unlocked for Resubmission"
                                        : type === 1 && reject === 2
                                            ? "Unlocked for Review Again"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submission",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? 1
                                        : type === 1 && reject === 2
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        })
                    }



                    result = { [obj]: attachObj(list_arr, refobj) };
                }
            }

            final_result = { ...final_result, ...result };
        });

        Object.values(final_result).forEach((j) => {
            console.log(j)
            let find = Object.values(j).filter(i => (i['DPAN0048'] !== undefined));

            Object.values(j).forEach((k) => {
                if (k.category !== null && find.length) {
                    if (k['DPAN0048'] === 6) {
                        let name1 = DPAN1158Options.find(i => i.id === k['DPAN1158'])

                        k.dp = (name1 === undefined ? 'NA' : 'Quantity of ' + name1.name + ' withdrawal' + ' ^ ' + unit)

                    } else if (k['DPAN0048'] === 5) {
                        let name1 = DPAN1200Options.find(i => i.id === k['DPAN1200'])

                        k.dp = (name1 === undefined ? 'NA' : 'Quantity of ' + name1.name + ' withdrawal' + ' ^ ' + unit)

                    }

                } else {
                    k.dp = null
                }
            })
        })
        console.log(final_result)
        return final_result;
    };
    const getValueByMonthlyBTAir = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr, qn_submit_list, unit, self, refobj
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]

        const value_arr = ['a1', 'b1', 'c1']
        let final_result = {};
        const DPA0296Options = [
            { name: "Economy Class", id: 325 },
            { name: "Premium Economy Class", id: 324 },
            { name: "Business / First Class", id: 323 },
        ]
        arr.forEach((obj) => {
            console.log(obj)
            let result = {
                [obj]: [refobj],
            };
            let result_arr = [];
            let index = qn_submit_list.findIndex((i) => {
                console.log(getRPTextFormat(i.reporting_period), obj);
                return (
                    i.dcfId === dcfId &&

                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(i.reporting_period) === obj
                );
            });
            console.log(index, obj);
            if (index !== -1) {
                let index2 = qn_submit_list.findIndex((i) => {
                    console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                        i.reporting_period[i.reporting_period.length - 1]
                    ), obj)
                    return (
                        i.dcfId === dcfId &&
                        (i.type == 0 ? i.reject === 1 : true) &&
                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(
                            [i.reporting_period[i.reporting_period.length - 1]]
                        ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                    );
                });
                console.log(index2, obj)
                if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                    const { type1, reject1 } = qn_submit_list[index2];
                    const { type, reject } = qn_submit_list[index];
                    let value = null,
                        value2 = null;
                    result_arr = [];
                    let list_arr = []
                    let list_arr2 = []
                    qn_submit_list[index2].response.forEach((i) => {
                        let abc = {}
                        if (i['DPAN1304'] === 1) {
                            abc['a1'] = i['DPAN1300'];
                            abc['b1'] = i['DPAN1301'];
                            abc['c1'] = i['DPAN1302'];

                            value_arr.forEach((x) => {
                                list_arr2.push({
                                    ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 1,
                                    form_type: 2,
                                    diff: false,
                                    current: abc[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: '-',
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            })
                        } else if (i['DPAN1304'] === 2) {
                            let abc = {}
                            if (i.legs.length) {
                                i.legs.forEach((j) => {
                                    let sel = ''
                                    if (j['DPA0296'] === 323) {
                                        sel = 'a1'
                                    } else if (j['DPA0296'] === 324) {
                                        sel = 'b1'
                                    } else if (j['DPA0296'] === 325) {
                                        sel = 'c1'
                                    }


                                    if (abc[sel]) {
                                        abc[sel] += j.air_km

                                    } else {
                                        abc[sel] = j.air_km

                                    }
                                })
                            }

                            value_arr.forEach((x) => {
                                if (abc[x]) {
                                    list_arr2.push({
                                        ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        category: 1,
                                        form_type: 2,
                                        diff: false,
                                        current: abc[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: '-',
                                        type, self,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                }

                            })
                        }

                    })
                    qn_submit_list[index].response.forEach((i) => {
                        let abc = {}
                        if (i['DPAN1304'] === 1) {
                            abc['a1'] = i['DPAN1300'];
                            abc['b1'] = i['DPAN1301'];
                            abc['c1'] = i['DPAN1302'];

                            value_arr.forEach((x) => {
                                console.log(list_arr2)
                                let oldIndex = list_arr2.findIndex((j) => j[x] !== undefined)
                                if (oldIndex !== -1) {
                                    value2 = list_arr2[oldIndex][x]

                                    list_arr.push({
                                        ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        category: 2,
                                        form_type: 2,
                                        diff: abc[x] === value2 ? null : abc[x] > value2 ? false : true,
                                        current: abc[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: calculatePercentage(abc[x], value2),
                                        type, self,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })

                                } else {

                                    list_arr.push({
                                        ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        category: 2,
                                        form_type: 2,
                                        diff: false,
                                        current: abc[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: '100%',
                                        type, self,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                }
                            })



                        } else if (i['DPAN1304'] === 2) {
                            let list_air = []
                            i.legs.forEach((j) => {
                                let sel = ''
                                if (j['DPA0296'] === 323) {
                                    sel = 'a1'
                                } else if (j['DPA0296'] === 324) {
                                    sel = 'b1'
                                } else if (j['DPA0296'] === 325) {
                                    sel = 'c1'
                                }


                                if (abc[sel]) {
                                    abc[sel] += j.air_km

                                } else {
                                    abc[sel] = j.air_km

                                }
                            })
                            value_arr.forEach((x) => {
                                if (abc[x]) {
                                    let oldIndex = list_arr2.findIndex((j) => j[x] !== undefined)
                                    if (oldIndex !== -1) {
                                        value2 = list_arr2[oldIndex][x]
                                        list_arr.push({
                                            ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                            col: getNumberOfColumn(obj),
                                            id: qn_submit_list[index].id,
                                            category: 2,
                                            form_type: 2,
                                            diff: abc[x] === value2 ? null : abc[x] > value2 ? false : true,
                                            current: abc[x],
                                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                            percentage: calculatePercentage(abc[x], value2),
                                            type, self,
                                            text:
                                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                    (type === 0 && (reject === 1 || reject === 2))
                                                        ? "Unlocked for Resubmission"
                                                        : type === 1 && reject === 2
                                                            ? "Unlocked for Review Again"
                                                            : type === 1
                                                                ? "Pending Review"
                                                                : type === 2
                                                                    ? "Pending Approval"
                                                                    : type === 3
                                                                        ? "Approved & Locked"
                                                                        : "Pending Submission",
                                            status:
                                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                                    (type === 0 && (reject === 1 || reject === 2))
                                                        ? 1
                                                        : type === 1 && reject === 2
                                                            ? 2
                                                            : type === 1
                                                                ? 3
                                                                : type === 2
                                                                    ? 4
                                                                    : type === 3
                                                                        ? 5
                                                                        : null
                                        })

                                    } else {
                                        list_arr.push({
                                            ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                            col: getNumberOfColumn(obj),
                                            id: qn_submit_list[index].id,
                                            category: 2,
                                            form_type: 2,
                                            diff: false,
                                            current: abc[x],
                                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                            percentage: '100%',
                                            type, self,
                                            text:
                                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                    (type === 0 && (reject === 1 || reject === 2))
                                                        ? "Unlocked for Resubmission"
                                                        : type === 1 && reject === 2
                                                            ? "Unlocked for Review Again"
                                                            : type === 1
                                                                ? "Pending Review"
                                                                : type === 2
                                                                    ? "Pending Approval"
                                                                    : type === 3
                                                                        ? "Approved & Locked"
                                                                        : "Pending Submission",
                                            status:
                                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                                    (type === 0 && (reject === 1 || reject === 2))
                                                        ? 1
                                                        : type === 1 && reject === 2
                                                            ? 2
                                                            : type === 1
                                                                ? 3
                                                                : type === 2
                                                                    ? 4
                                                                    : type === 3
                                                                        ? 5
                                                                        : null
                                        })
                                    }
                                }
                            })
                        }

                    })


                    result = { [obj]: attachObj(list_arr, refobj) };
                } else {

                    const { type, reject } = qn_submit_list[index];
                    let value = null;
                    result_arr = [];
                    let list_arr = []
                    console.log(qn_submit_list[index])
                    if (qn_submit_list[index].response.length) {

                        qn_submit_list[index].response.forEach((i) => {
                            let abc = {}
                            if (i['DPAN1304'] === 1) {
                                abc['a1'] = i['DPAN1300'];
                                abc['b1'] = i['DPAN1301'];
                                abc['c1'] = i['DPAN1302'];
                                value_arr.forEach((x) => {
                                    list_arr.push({
                                        ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        form_type: 2,
                                        category: 1,
                                        diff: false,
                                        current: abc[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: "-",
                                        type, self,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                })


                            } else if (i['DPAN1304'] === 2) {
                                let list_air = []
                                if (i.legs.length) {
                                    i.legs.forEach((j) => {
                                        let sel = ''
                                        if (j['DPA0296'] === 323) {
                                            sel = 'a1'
                                        } else if (j['DPA0296'] === 324) {
                                            sel = 'b1'
                                        } else if (j['DPA0296'] === 325) {
                                            sel = 'c1'
                                        }
                                        console.log(i.legs, sel, i)

                                        if (i[sel]) {
                                            i[sel] += j.air_km

                                        } else {
                                            i[sel] = j.air_km

                                        }
                                    })
                                }
                                value_arr.forEach((x) => {
                                    if (i[x] || i[x] === 0) {
                                        list_arr.push({
                                            ...i, remarks: qn_submit_list[index].return_remarks,
                                            col: getNumberOfColumn(obj),
                                            id: qn_submit_list[index].id,
                                            form_type: 2,
                                            category: 1,
                                            diff: false,
                                            current: i[x],
                                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                            percentage: "-",
                                            type, self,
                                            text:
                                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                    (type === 0 && (reject === 1 || reject === 2))
                                                        ? "Unlocked for Resubmission"
                                                        : type === 1 && reject === 2
                                                            ? "Unlocked for Review Again"
                                                            : type === 1
                                                                ? "Pending Review"
                                                                : type === 2
                                                                    ? "Pending Approval"
                                                                    : type === 3
                                                                        ? "Approved & Locked"
                                                                        : "Pending Submission",
                                            status:
                                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                                    (type === 0 && (reject === 1 || reject === 2))
                                                        ? 1
                                                        : type === 1 && reject === 2
                                                            ? 2
                                                            : type === 1
                                                                ? 3
                                                                : type === 2
                                                                    ? 4
                                                                    : type === 3
                                                                        ? 5
                                                                        : null
                                        })
                                    }
                                })



                            }

                        })
                    }
                    else {
                        list_arr.push({
                            edit: 1,
                            remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            form_type: 2,
                            category: 1,
                            diff: false,
                            current: '-',
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: "-",
                            type, self,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? "Unlocked for Resubmission"
                                        : type === 1 && reject === 2
                                            ? "Unlocked for Review Again"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submission",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? 1
                                        : type === 1 && reject === 2
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        })
                    }



                    result = { [obj]: attachObj(list_arr, refobj) };
                }
            }

            final_result = { ...final_result, ...result };
        });

        Object.values(final_result).forEach((j) => {
            console.log(j, arr)
            let find = Object.values(j).filter(i => (i['a1'] !== undefined || i['b1'] !== undefined || i['c1'] !== undefined));

            Object.values(j).forEach((k) => {
                console.log(k)
                if (k.category !== null && find.length) {
                    let name1 = 'NA'
                    if (k['a1'] || k['a1'] === 0) {
                        name1 = DPA0296Options.find(i => i.id === 323)
                    } else if (k['b1'] || k['b1'] === 0) {
                        name1 = DPA0296Options.find(i => i.id === 324)
                    } else if (k['c1'] || k['c1'] === 0) {
                        name1 = DPA0296Options.find(i => i.id === 325)
                    }


                    k.dp = (name1 === undefined ? 'NA' : name1.name + ' ^ ' + unit)



                } else {
                    k.dp = null
                }
            })
        })
        console.log(final_result)
        return final_result;
    };
    const getValueByMonthlyBTRail = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr, qn_submit_list, unit, self, refobj
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]

        const value_arr = ['a1', 'b1', 'c1']
        let final_result = {};
        const DPAN1312Options = [{ name: 'Intercity Rail', id: 332 }, { name: 'Commuter Rail', id: 333 }, { name: 'Transit Rail', id: 334 }]
        console.log(tier0, tier1, tier2, tier3)
        arr.forEach((obj) => {
            console.log(obj)
            let result = {
                [obj]: [refobj],
            };
            let result_arr = [];
            let index = qn_submit_list.findIndex((i) => {
                console.log(getRPTextFormat(i.reporting_period) === obj, dcfId === i.dcfId, i.tier0_id === tier0,
                    i.tier1_id === tier1,
                    i.tier2_id === tier2,
                    i.tier3_id === tier3, i.id, i.tier3_id, tier3);
                return (
                    i.dcfId === dcfId &&
                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(i.reporting_period) === obj
                );
            });
            console.log(index, obj);
            if (index !== -1) {
                let index2 = qn_submit_list.findIndex((i) => {
                    console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                        i.reporting_period[i.reporting_period.length - 1]
                    ), obj)
                    return (
                        i.dcfId === dcfId &&
                        (i.type == 0 ? i.reject === 1 : true) &&
                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(
                            [i.reporting_period[i.reporting_period.length - 1]]
                        ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                    );
                });
                console.log(index2, obj)
                if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                    const { type1, reject1 } = qn_submit_list[index2];
                    const { type, reject } = qn_submit_list[index];
                    let value = null,
                        value2 = null;
                    result_arr = [];
                    let list_arr = []
                    let list_arr2 = []
                    qn_submit_list[index2].response.forEach((i) => {
                        let abc = {}
                        if (i['DPAN1313'] === 1) {
                            abc['a1'] = i['DPAN1306'];
                            abc['b1'] = i['DPAN1307'];
                            abc['c1'] = i['DPAN1308'];

                            value_arr.forEach((x) => {
                                list_arr2.push({
                                    ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 1,
                                    form_type: 2,
                                    diff: false,
                                    current: abc[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: '-',
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            })
                        } else if (i['DPAN1313'] === 2) {
                            let abc = {}
                            if (i.legs.length) {
                                i.legs.forEach((j) => {
                                    let sel = ''
                                    if (j['DPAN1312'] === 332) {
                                        sel = 'a1'
                                    } else if (j['DPAN1312'] === 333) {
                                        sel = 'b1'
                                    } else if (j['DPAN1312'] === 334) {
                                        sel = 'c1'
                                    }


                                    if (abc[sel]) {
                                        abc[sel] += j.rail_km

                                    } else {
                                        abc[sel] = j.rail_km

                                    }
                                })
                            }

                            value_arr.forEach((x) => {
                                if (abc[x]) {
                                    list_arr2.push({
                                        ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        category: 1,
                                        form_type: 2,
                                        diff: false,
                                        current: abc[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: '-',
                                        type, self,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                }

                            })
                        }

                    })
                    qn_submit_list[index].response.forEach((i) => {
                        let abc = {}
                        if (i['DPAN1313'] === 1) {
                            abc['a1'] = i['DPAN1306'];
                            abc['b1'] = i['DPAN1307'];
                            abc['c1'] = i['DPAN1308'];

                            value_arr.forEach((x) => {
                                console.log(list_arr2)
                                let oldIndex = list_arr2.findIndex((j) => j[x] !== undefined)
                                if (oldIndex !== -1) {
                                    value2 = list_arr2[oldIndex][x]

                                    list_arr.push({
                                        ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        category: 2,
                                        form_type: 2,
                                        diff: abc[x] === value2 ? null : abc[x] > value2 ? false : true,
                                        current: abc[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: calculatePercentage(abc[x], value2),
                                        type, self,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })

                                } else {

                                    list_arr.push({
                                        ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        category: 2,
                                        form_type: 2,
                                        diff: false,
                                        current: abc[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: '100%',
                                        type, self,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                }
                            })



                        } else if (i['DPAN1313'] === 2) {
                            let list_air = []
                            i.legs.forEach((j) => {
                                let sel = ''
                                if (j['DPAN1312'] === 332) {
                                    sel = 'a1'
                                } else if (j['DPAN1312'] === 333) {
                                    sel = 'b1'
                                } else if (j['DPAN1312'] === 334) {
                                    sel = 'c1'
                                }


                                if (abc[sel]) {
                                    abc[sel] += j.rail_km

                                } else {
                                    abc[sel] = j.rail_km

                                }
                            })
                            value_arr.forEach((x) => {
                                if (abc[x]) {
                                    let oldIndex = list_arr2.findIndex((j) => j[x] !== undefined)
                                    if (oldIndex !== -1) {
                                        value2 = list_arr2[oldIndex][x]
                                        list_arr.push({
                                            ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                            col: getNumberOfColumn(obj),
                                            id: qn_submit_list[index].id,
                                            category: 2,
                                            form_type: 2,
                                            diff: abc[x] === value2 ? null : abc[x] > value2 ? false : true,
                                            current: abc[x],
                                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                            percentage: calculatePercentage(abc[x], value2),
                                            type, self,
                                            text:
                                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                    (type === 0 && (reject === 1 || reject === 2))
                                                        ? "Unlocked for Resubmission"
                                                        : type === 1 && reject === 2
                                                            ? "Unlocked for Review Again"
                                                            : type === 1
                                                                ? "Pending Review"
                                                                : type === 2
                                                                    ? "Pending Approval"
                                                                    : type === 3
                                                                        ? "Approved & Locked"
                                                                        : "Pending Submission",
                                            status:
                                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                                    (type === 0 && (reject === 1 || reject === 2))
                                                        ? 1
                                                        : type === 1 && reject === 2
                                                            ? 2
                                                            : type === 1
                                                                ? 3
                                                                : type === 2
                                                                    ? 4
                                                                    : type === 3
                                                                        ? 5
                                                                        : null
                                        })

                                    } else {
                                        list_arr.push({
                                            ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                            col: getNumberOfColumn(obj),
                                            id: qn_submit_list[index].id,
                                            category: 2,
                                            form_type: 2,
                                            diff: false,
                                            current: abc[x],
                                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                            percentage: '100%',
                                            type, self,
                                            text:
                                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                    (type === 0 && (reject === 1 || reject === 2))
                                                        ? "Unlocked for Resubmission"
                                                        : type === 1 && reject === 2
                                                            ? "Unlocked for Review Again"
                                                            : type === 1
                                                                ? "Pending Review"
                                                                : type === 2
                                                                    ? "Pending Approval"
                                                                    : type === 3
                                                                        ? "Approved & Locked"
                                                                        : "Pending Submission",
                                            status:
                                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                                    (type === 0 && (reject === 1 || reject === 2))
                                                        ? 1
                                                        : type === 1 && reject === 2
                                                            ? 2
                                                            : type === 1
                                                                ? 3
                                                                : type === 2
                                                                    ? 4
                                                                    : type === 3
                                                                        ? 5
                                                                        : null
                                        })
                                    }
                                }
                            })
                        }

                    })


                    result = { [obj]: attachObj(list_arr, refobj) };
                } else {

                    const { type, reject } = qn_submit_list[index];
                    let value = null;
                    result_arr = [];
                    let list_arr = []
                    console.log(qn_submit_list[index])
                    if (qn_submit_list[index].response.length) {

                        qn_submit_list[index].response.forEach((i) => {
                            let abc = {}
                            if (i['DPAN1313'] === 1) {
                                abc['a1'] = i['DPAN1306'];
                                abc['b1'] = i['DPAN1307'];
                                abc['c1'] = i['DPAN1308'];
                                value_arr.forEach((x) => {
                                    list_arr.push({
                                        ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                        col: getNumberOfColumn(obj),
                                        id: qn_submit_list[index].id,
                                        form_type: 2,
                                        category: 1,
                                        diff: false,
                                        current: abc[x],
                                        month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                        percentage: "-",
                                        type, self,
                                        text:
                                            type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? "Unlocked for Resubmission"
                                                    : type === 1 && reject === 2
                                                        ? "Unlocked for Review Again"
                                                        : type === 1
                                                            ? "Pending Review"
                                                            : type === 2
                                                                ? "Pending Approval"
                                                                : type === 3
                                                                    ? "Approved & Locked"
                                                                    : "Pending Submission",
                                        status:
                                            type === 0 && (reject === 0 || reject === null) ? 0 :
                                                (type === 0 && (reject === 1 || reject === 2))
                                                    ? 1
                                                    : type === 1 && reject === 2
                                                        ? 2
                                                        : type === 1
                                                            ? 3
                                                            : type === 2
                                                                ? 4
                                                                : type === 3
                                                                    ? 5
                                                                    : null
                                    })
                                })


                            } else if (i['DPAN1313'] === 2) {
                                let list_air = []
                                if (i.legs.length) {
                                    i.legs.forEach((j) => {
                                        let sel = ''
                                        if (j['DPAN1312'] === 332) {
                                            sel = 'a1'
                                        } else if (j['DPAN1312'] === 333) {
                                            sel = 'b1'
                                        } else if (j['DPAN1312'] === 334) {
                                            sel = 'c1'
                                        }
                                        console.log(i.legs, sel, i)

                                        if (i[sel]) {
                                            i[sel] += j.rail_km

                                        } else {
                                            i[sel] = j.rail_km

                                        }
                                    })
                                }
                                value_arr.forEach((x) => {
                                    if (i[x] || i[x] === 0) {
                                        list_arr.push({
                                            ...i, remarks: qn_submit_list[index].return_remarks,
                                            col: getNumberOfColumn(obj),
                                            id: qn_submit_list[index].id,
                                            form_type: 2,
                                            category: 1,
                                            diff: false,
                                            current: i[x],
                                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                            percentage: "-",
                                            type, self,
                                            text:
                                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                                    (type === 0 && (reject === 1 || reject === 2))
                                                        ? "Unlocked for Resubmission"
                                                        : type === 1 && reject === 2
                                                            ? "Unlocked for Review Again"
                                                            : type === 1
                                                                ? "Pending Review"
                                                                : type === 2
                                                                    ? "Pending Approval"
                                                                    : type === 3
                                                                        ? "Approved & Locked"
                                                                        : "Pending Submission",
                                            status:
                                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                                    (type === 0 && (reject === 1 || reject === 2))
                                                        ? 1
                                                        : type === 1 && reject === 2
                                                            ? 2
                                                            : type === 1
                                                                ? 3
                                                                : type === 2
                                                                    ? 4
                                                                    : type === 3
                                                                        ? 5
                                                                        : null
                                        })
                                    }
                                })



                            }

                        })
                    }
                    else {
                        list_arr.push({
                            edit: 1,
                            remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            form_type: 2,
                            category: 1,
                            diff: false,
                            current: '-',
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: "-",
                            type, self,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? "Unlocked for Resubmission"
                                        : type === 1 && reject === 2
                                            ? "Unlocked for Review Again"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submission",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? 1
                                        : type === 1 && reject === 2
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        })
                    }



                    result = { [obj]: attachObj(list_arr, refobj) };
                }
            }

            final_result = { ...final_result, ...result };
        });

        Object.values(final_result).forEach((j) => {
            console.log(j, arr)
            let find = Object.values(j).filter(i => (i['a1'] !== undefined || i['b1'] !== undefined || i['c1'] !== undefined));

            Object.values(j).forEach((k) => {
                console.log(k)
                if (k.category !== null && find.length) {
                    let name1 = 'NA'
                    if (k['a1'] || k['a1'] === 0) {
                        name1 = DPAN1312Options.find(i => i.id === 332)
                    } else if (k['b1'] || k['b1'] === 0) {
                        console.log('xyz')
                        name1 = DPAN1312Options.find(i => i.id === 333)
                    } else if (k['c1'] || k['c1'] === 0) {
                        name1 = DPAN1312Options.find(i => i.id === 334)
                    }


                    k.dp = (name1 === undefined ? 'NA' : name1.name + ' ^ ' + unit)



                } else {
                    k.dp = null
                }
            })
        })
        console.log(final_result)
        return final_result;
    };
    const getValueByMonthlyBTLand = (
        dcfId,
        tier0,
        tier1,
        tier2,
        tier3,
        arr, qn_submit_list, unit, self, refobj
    ) => {
        // let months = ['Jan-' + yr, 'Feb-' + yr, 'Mar-' + yr, 'Apr-' + yr, 'May-' + yr, 'Jun-' + yr, 'Jul-' + yr, 'Aug-' + yr, 'Sep-' + yr, 'Oct-' + yr, 'Nov-' + yr, 'Dec-' + yr]
        let land_veh_list = ['Standard Taxi / Sedan', 'Hybrid Vehicle', 'Executive Taxi', 'Dual Purpose 4 x 4 (SUV)', 'Electric Vehicle (EV)', 'Van/ Coach']
        const value_arr = ['a1', 'b1', 'c1', 'd1', 'e1', 'f1']
        let final_result = {};

        console.log(tier0, tier1, tier2, tier3)
        arr.forEach((obj) => {
            console.log(obj)
            let result = {
                [obj]: [refobj],
            };
            let result_arr = [];
            let index = qn_submit_list.findIndex((i) => {
                console.log(getRPTextFormat(i.reporting_period) === obj, dcfId === i.dcfId, i.tier0_id === tier0,
                    i.tier1_id === tier1,
                    i.tier2_id === tier2,
                    i.tier3_id === tier3, i.id, i.tier3_id, tier3);
                return (
                    i.dcfId === dcfId &&
                    i.tier0_id === tier0 &&
                    i.tier1_id === tier1 &&
                    i.tier2_id === tier2 &&
                    i.tier3_id === tier3 &&
                    getRPTextFormat(i.reporting_period) === obj
                );
            });
            console.log(index, obj);
            if (index !== -1) {
                let index2 = qn_submit_list.findIndex((i) => {
                    console.log(getPreviousPeriod(obj.includes("to") ? 2 : 1, obj), getRPTextFormat(
                        i.reporting_period[i.reporting_period.length - 1]
                    ), obj)
                    return (
                        i.dcfId === dcfId &&
                        (i.type == 0 ? i.reject === 1 : true) &&
                        i.tier0_id === tier0 &&
                        i.tier1_id === tier1 &&
                        i.tier2_id === tier2 &&
                        i.tier3_id === tier3 &&
                        getRPTextFormat(
                            [i.reporting_period[i.reporting_period.length - 1]]
                        ) === getPreviousPeriod(obj.includes("to") ? 2 : 1, obj)
                    );
                });
                console.log(index2, obj)
                if (index2 !== -1 && qn_submit_list[index2].response.length && qn_submit_list[index].response.length) {
                    const { type1, reject1 } = qn_submit_list[index2];
                    const { type, reject } = qn_submit_list[index];
                    let value = null,
                        value2 = null;
                    result_arr = [];
                    let list_arr = []
                    let list_arr2 = []
                    qn_submit_list[index2].response.forEach((i) => {
                        let abc = {}

                        abc['a1'] = i['DPAN1314'];
                        abc['b1'] = i['DPAN1315'];
                        abc['c1'] = i['DPAN1316'];
                        abc['d1'] = i['DPAN1317'];
                        abc['e1'] = i['DPAN1318'];
                        abc['f1'] = i['DPAN1319'];
                        value_arr.forEach((x) => {
                            list_arr2.push({
                                ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                col: getNumberOfColumn(obj),
                                id: qn_submit_list[index].id,
                                category: 1,
                                form_type: 2,
                                diff: false,
                                current: abc[x],
                                month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                percentage: '-',
                                type, self,
                                text:
                                    type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? "Unlocked for Resubmission"
                                            : type === 1 && reject === 2
                                                ? "Unlocked for Review Again"
                                                : type === 1
                                                    ? "Pending Review"
                                                    : type === 2
                                                        ? "Pending Approval"
                                                        : type === 3
                                                            ? "Approved & Locked"
                                                            : "Pending Submission",
                                status:
                                    type === 0 && (reject === 0 || reject === null) ? 0 :
                                        (type === 0 && (reject === 1 || reject === 2))
                                            ? 1
                                            : type === 1 && reject === 2
                                                ? 2
                                                : type === 1
                                                    ? 3
                                                    : type === 2
                                                        ? 4
                                                        : type === 3
                                                            ? 5
                                                            : null
                            })
                        })


                    })
                    qn_submit_list[index].response.forEach((i) => {
                        let abc = {}

                        abc['a1'] = i['DPAN1306'];
                        abc['b1'] = i['DPAN1307'];
                        abc['c1'] = i['DPAN1308'];

                        value_arr.forEach((x) => {
                            console.log(list_arr2)
                            let oldIndex = list_arr2.findIndex((j) => j[x] !== undefined)
                            if (oldIndex !== -1) {
                                value2 = list_arr2[oldIndex][x]

                                list_arr.push({
                                    ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 2,
                                    form_type: 2,
                                    diff: abc[x] === value2 ? null : abc[x] > value2 ? false : true,
                                    current: abc[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: calculatePercentage(abc[x], value2),
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })

                            } else {

                                list_arr.push({
                                    ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    category: 2,
                                    form_type: 2,
                                    diff: false,
                                    current: abc[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: '100%',
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            }
                        })





                    })


                    result = { [obj]: attachObj(list_arr, refobj) };
                } else {

                    const { type, reject } = qn_submit_list[index];
                    let value = null;
                    result_arr = [];
                    let list_arr = []
                    console.log(qn_submit_list[index])
                    if (qn_submit_list[index].response.length) {

                        qn_submit_list[index].response.forEach((i) => {
                            let abc = {}

                            abc['a1'] = i['DPAN1314'];
                            abc['b1'] = i['DPAN1315'];
                            abc['c1'] = i['DPAN1316'];
                            abc['d1'] = i['DPAN1317'];
                            abc['e1'] = i['DPAN1318'];
                            abc['f1'] = i['DPAN1319'];
                            value_arr.forEach((x) => {
                                list_arr.push({
                                    ...i, [x]: abc[x], remarks: qn_submit_list[index].return_remarks,
                                    col: getNumberOfColumn(obj),
                                    id: qn_submit_list[index].id,
                                    form_type: 2,
                                    category: 1,
                                    diff: false,
                                    current: abc[x],
                                    month: getRPTextFormat(qn_submit_list[index].reporting_period),
                                    percentage: "-",
                                    type, self,
                                    text:
                                        type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? "Unlocked for Resubmission"
                                                : type === 1 && reject === 2
                                                    ? "Unlocked for Review Again"
                                                    : type === 1
                                                        ? "Pending Review"
                                                        : type === 2
                                                            ? "Pending Approval"
                                                            : type === 3
                                                                ? "Approved & Locked"
                                                                : "Pending Submission",
                                    status:
                                        type === 0 && (reject === 0 || reject === null) ? 0 :
                                            (type === 0 && (reject === 1 || reject === 2))
                                                ? 1
                                                : type === 1 && reject === 2
                                                    ? 2
                                                    : type === 1
                                                        ? 3
                                                        : type === 2
                                                            ? 4
                                                            : type === 3
                                                                ? 5
                                                                : null
                                })
                            })




                        })
                    }
                    else {
                        list_arr.push({
                            edit: 1,
                            remarks: qn_submit_list[index].return_remarks,
                            col: getNumberOfColumn(obj),
                            id: qn_submit_list[index].id,
                            form_type: 2,
                            category: 1,
                            diff: false,
                            current: '-',
                            month: getRPTextFormat(qn_submit_list[index].reporting_period),
                            percentage: "-",
                            type, self,
                            text:
                                type === 0 && (reject === 0 || reject === null) ? "Drafted" :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? "Unlocked for Resubmission"
                                        : type === 1 && reject === 2
                                            ? "Unlocked for Review Again"
                                            : type === 1
                                                ? "Pending Review"
                                                : type === 2
                                                    ? "Pending Approval"
                                                    : type === 3
                                                        ? "Approved & Locked"
                                                        : "Pending Submission",
                            status:
                                type === 0 && (reject === 0 || reject === null) ? 0 :
                                    (type === 0 && (reject === 1 || reject === 2))
                                        ? 1
                                        : type === 1 && reject === 2
                                            ? 2
                                            : type === 1
                                                ? 3
                                                : type === 2
                                                    ? 4
                                                    : type === 3
                                                        ? 5
                                                        : null
                        })
                    }



                    result = { [obj]: attachObj(list_arr, refobj) };
                }
            }

            final_result = { ...final_result, ...result };
        });

        Object.values(final_result).forEach((j) => {
            console.log(j, arr)
            let find = Object.values(j).filter(i => (i['a1'] !== undefined));

            Object.values(j).forEach((k) => {
                console.log(k)
                if (k.category !== null && find.length) {
                    let name1 = 'NA'
                    if (k['a1'] || k['a1'] === 0) {
                        name1 = land_veh_list[0]
                    } else if (k['b1'] || k['b1'] === 0) {
                        console.log('xyz')
                        name1 = land_veh_list[1]
                    } else if (k['c1'] || k['c1'] === 0) {
                        name1 = land_veh_list[2]
                    } else if (k['d1'] || k['d1'] === 0) {
                        name1 = land_veh_list[3]
                    }
                    else if (k['e1'] || k['e1'] === 0) {
                        name1 = land_veh_list[4]
                    }
                    else if (k['f1'] || k['f1'] === 0) {
                        name1 = land_veh_list[5]
                    }


                    k.dp = (name1 === undefined ? 'NA' : name1 + ' ^ ' + unit)



                } else {
                    k.dp = null
                }
            })
        })
        console.log(final_result)
        return final_result;
    };
    function arraysAreEqual(arr1, arr2) {
        // Check if the arrays have the same length
        if (arr1.length !== arr2.length) {
            return false;
        }

        // Check if each element in arr1 matches the corresponding element in arr2
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }

        // If all elements match and lengths are the same, arrays are equal
        return true;
    }
    function getUniqueYearsArray(array1, array2) {
        // Convert arrays to sets to ensure uniqueness
        const set1 = new Set(array1);
        const set2 = new Set(array2);

        // Merge the sets
        const mergedSet = new Set([...set1, ...set2]);

        // Convert the merged set back to an array
        const uniqueYearsArray = Array.from(mergedSet);

        return uniqueYearsArray;
    }

    function getYearsArray(start_date, end_date = null) {
        // Parse the start date
        const startDate = DateTime.fromISO(start_date);

        // Parse the end date or use current UTC local date if end_date is null
        const endDate = end_date ? DateTime.fromISO(end_date) : DateTime.utc();

        // Get the years between start and end dates
        const yearsArray = [];
        for (let year = startDate.year; year <= endDate.year; year++) {
            yearsArray.push(year.toString());
        }

        return yearsArray;
    }
    const checkEnity = (rowData, entity_list, rawsite, obj) => {
        let index = entity_list.findIndex((k) => k[obj] === rowData[obj]);
        if (index !== -1) {
            let entity = entity_list[index];
            console.log(entity);
            if (rowData.level === 0) {
                return entity.tier0_ids.includes(0);
            } else if (rowData.level === 1) {
                return (
                    entity.tier1_ids.includes(rowData.locationId) &&
                    getCoverageText(rowData, rawsite)
                );
            } else if (rowData.level === 2) {
                return (
                    entity.tier2_ids.includes(rowData.locationId) &&
                    getCoverageText(rowData, rawsite)
                );
            } else if (rowData.level === 3) {
                return (
                    entity.tier3_ids.includes(rowData.locationId) &&
                    getCoverageText(rowData, rawsite)
                );
            }
        } else {
            return false;
        }
    };
    const getDataPointUnit = (rowData, dcfId) => {
        console.log(rowData)
        let text = "Not Found";
        let filteredList = dplist
            .filter(
                (i) =>
                    i.suffix !== null &&
                    i.suffix.trim().toLowerCase() === rowData.name.trim().toLowerCase()
            )
            .findIndex(
                (i) => Array.isArray(i.data1)
            );
        console.log(rowData.dcfId, dplist
            .filter(
                (i) =>
                    i.suffix !== null &&
                    i.suffix.trim().toLowerCase() === rowData.name.trim().toLowerCase()
            ))
        if (filteredList !== -1) {
            text = dplist.filter(
                (i) =>
                    i.suffix !== null &&
                    i.suffix.trim().toLowerCase() === rowData.name.trim().toLowerCase()
            )[filteredList].data1[0].unit;
        }
        console.log(rowData, text);
        return text;
    };
    const getCoverageText = (rowData, rawsitelist) => {
        let text = "";
        console.log(rowData);
        if (rowData.level === 0) {
            text = "Corporate";
        } else if (rowData.level === 1) {
            let country_index = rawsitelist.findIndex(
                (i) => i.id === rowData.locationId
            );
            if (country_index !== -1) {
                text = rawsitelist[country_index].name;
            }
        } else if (rowData.level === 2) {
            let city_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.city_id === rowData.locationId;
                });
            if (city_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[city_index].city_name;
            }
        } else if (rowData.level === 3) {
            let site_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.site_id === rowData.locationId;
                });
            if (site_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[site_index].site_name;
            }
        }
        return text;
    };
    function getNumberOfColumn(monthString) {
        if (monthString.includes("to")) {
            const [startMonth, endMonth] = monthString.split(" to ");

            const startDate = DateTime.fromFormat(startMonth, "LLL-yyyy");
            let endDate = DateTime.local(); // Default to current month and year
            if (endMonth) {
                endDate = DateTime.fromFormat(endMonth, "LLL-yyyy");
            }

            // Calculate the difference in months
            const diffMonths = endDate.diff(startDate, "months").months + 1;

            return diffMonths;
        } else {
            return 1;
        }
    }

    const getCoverageTextById = (rowData, rawsitelist) => {
        let text = "";
        console.log(rowData);
        if (rowData.tier0_id !== null) {
            text = "Corporate";
        } else if (rowData.tier1_id !== null) {
            let country_index = rawsitelist.findIndex(
                (i) => i.id === rowData.tier1_id
            );
            if (country_index !== -1) {
                text = rawsitelist[country_index].name;
            }
        } else if (rowData.tier2_id !== null) {
            let city_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.city_id === rowData.tier2_id;
                });
            if (city_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[city_index].city_name;
            }
        } else if (rowData.tier3_id !== null) {
            let site_index = rawsitelist
                .flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )
                .findIndex((i) => {
                    return i.site_id === rowData.tier3_id;
                });
            if (site_index !== -1) {
                text = rawsitelist.flatMap((i) =>
                    i.locationTwos.flatMap((j) =>
                        j.locationThrees.map((k) => {
                            return {
                                site_id: k.id,
                                site_name: k.name,
                                city_id: j.id,
                                city_name: j.name,
                                country_id: i.id,
                                country_name: i.name,
                            };
                        })
                    )
                )[site_index].site_name;
            }
        }
        return text;
    };
    const titleTemplate = (rowData) => {
        let text = "Not Found";
        let dcf_index = dcflist.findIndex((i) => i.id === rowData.dcfId);
        if (dcf_index !== -1) {
            text = dcflist[dcf_index].title;
        }
        return (
            <div
                className="text-underline cur-pointer clr-navy fw-5"
                onClick={() => {
                    navigate.push({
                        pathname:
                            "/data_input_reviewer/" + rowData.dcfId + "/" + rowData.id,
                    });
                }}
            >
                {text}
            </div>
        );
    };
    const getFormTitle = (rowData) => {
        let text = "Not Found";
        let dcf_index = dcflist.findIndex((i) => i.id === rowData.dcfId);
        if (dcf_index !== -1) {
            text = dcflist[dcf_index].title;
        }
        return text;
    };
    const dpNameTemplate = (rowData) => {
        console.log(rowData)
        return (
            <>
                {rowData.label
                    .replace(/(<([^>]+)>)/gi, "")
                    .replace(/\n/g, " ")
                    .replace(/&nbsp;/g, " ")
                    .replace("&amp;", "&")}
            </>
        );
    };
    const coverageTemplate = (rowData) => {
        return <>{getCoverageText(rowData, rawsitelist)}</>;
    };
    const getUser = (uid) => {
        let user_name = 'Not Found'
        let index = userList.findIndex(i => i.id === uid)
        if (index !== -1) {
            user_name = userList[index].information.empname
        }
        return user_name
    };
    const submitterTemplate = (rowData) => {
        return <>{getUser(rowData.reporter_modified_by)}</>;
    };
    const submittedTemplate = (rowData) => {
        return (
            <>
                {DateTime.fromISO(rowData.last_modified_on, { zone: "utc" })
                    .toLocal()
                    .toFormat("dd-LLL-yyyy HH:mm")}
            </>
        );
    };
    const rpFrequencyTemplate = (rowData) => {
        return <span>{getFrequencyText(rowData.frequency)}</span>;
    };
    const janTemplate = (rowData) => {
        return <span>{rowData.locationId}</span>;
    };
    const getColumn = (rowData) => {
        console.log(rowData);
        return 1;
    };

    const getFrequencyText = (id) => {
        return frequency_list.find((i) => {
            return i.id === id;
        }).name;
    };
    const sortRP = (e) => {
        console.log(e.data);
        if (e.order === 1) {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromFormat(a.reporting_period, "MMM-yyyy");
                let dateB = DateTime.fromFormat(b.reporting_period, "MMM-yyyy");
                if (a.reporting_period.includes("to")) {
                    dateA = DateTime.fromFormat(
                        a.reporting_period.split("to")[0].trim(),
                        "MMM-yyyy"
                    );
                }
                if (b.reporting_period.includes("to")) {
                    dateB = DateTime.fromFormat(
                        b.reporting_period.split("to")[0].trim(),
                        "MMM-yyyy"
                    );
                }

                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            });
        } else {
            return e.data.sort((a, b) => {
                let dateA = DateTime.fromFormat(a.reporting_period, "MMM-yyyy");
                let dateB = DateTime.fromFormat(b.reporting_period, "MMM-yyyy");
                if (a.reporting_period.includes("to")) {
                    dateA = DateTime.fromFormat(
                        a.reporting_period.split("to")[0].trim(),
                        "MMM-yyyy"
                    );
                }
                if (b.reporting_period.includes("to")) {
                    dateB = DateTime.fromFormat(
                        b.reporting_period.split("to")[0].trim(),
                        "MMM-yyyy"
                    );
                }
                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            });
        }
    };
    const getStatusText = (val, rowData) => {
        let text = "NOT SUBMITTED";
        console.log(rowData);
        if (rowData !== undefined) {
            if (rowData.type === 1) {
                text = "Not Reviewed";
            } else if (rowData.type === 2) {
                if (rowData.self) {
                    text = "Self Reviewed";
                } else {
                    text = "Reviewed";
                }
            } else if (rowData.type === 3) {
                text = "APPROVED";
            }
        }
        return text;
    };
    const sortStatus_ = (e) => {
        if (e.order === 1) {
            return e.data.sort((a, b) => {
                const dateA = getStatusText("", a);
                const dateB = getStatusText("", b);
                console.log(dateA, dateB);
                // Compare the parsed dates
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            });
        } else {
            return e.data.sort((a, b) => {
                const dateA = getStatusText("", a);
                const dateB = getStatusText("", b);

                // Compare the parsed dates in descending order
                if (dateA > dateB) return -1;
                if (dateA < dateB) return 1;
                return 0;
            });
        }
    };
    const statusTemplate = (rowData) => {
        let type = 0;
        let text = "NOT SUBMITTED";
        let classtext = "status-tag-gray";
        if (rowData !== undefined) {
            if (rowData.type === 0) {
                if (rowData.reject === 1) {
                    type = 1;
                    text = "RETURNED";
                    classtext = "status-tag-red";
                } else {
                    type = 2;
                    text = "DRAFT";
                    classtext = "status-tag-orange";
                }
            } else if (rowData.type === 1) {
                if (rowData.reject === 1) {
                    type = 1;
                    text = "RETURNED";
                    classtext = "status-tag-red";
                } else {
                    type = 3;
                    text = "SUBMITTED";
                    classtext = "status-tag-green";
                }
            } else if (rowData.type === 2) {
                type = 3;
                if (rowData.self) {
                    text = "Self Reviewed";
                } else {
                    text = "Reviewed";
                }

                classtext = "status-tag-green";
            } else if (rowData.type === 3) {
                type = 4;
                text = "APPROVED";
                classtext = "status-tag-green";
            }
        }
        return (
            <Tag style={{ padding: "4px 8px" }} className={classtext}>
                {text}
            </Tag>
        );
    };
    const extractDPFromForms = (list) => {
        let form = JSON.parse(JSON.stringify(list));

        const updatedResponses = form.flatMap((submission) => {
            return submission.response
                .filter((response) => response.type === "number")
                .map((response) => ({
                    ...response,
                    colSpan: submission.reporting_period.length,
                    ...submission,
                }));
        });

        // setRefinedData(updatedResponses)
    };

    const columns = [
        {
            field: "",
            title: "Coverage",
            render: (cell, row) => {
                return coverageTemplate(cell);
            },
        },
        {
            field: "",
            title: "Form",
            render: (cell, row) => {
                return titleTemplate(cell);
            },
        },

        {
            field: "",
            title: "Form",
            render: (cell, row) => {
                return dpNameTemplate(cell);
            },
        },
        {
            field: "",
            title: "Frequency",
            render: (cell, row) => {
                return rpFrequencyTemplate(cell);
            },
        },
        {
            field: "",
            title: "JAN",

            render: (cell, row, a, b) => {
                return <>1</>;
            },
        },
        {
            field: "",
            title: "FEB",
            render: (cell, row) => {
                return <></>;
            },
        },
        {
            field: "",
            title: "MAR",
            render: (cell, row) => {
                return <></>;
            },
        },
        {
            field: "",
            title: "APR",
            render: (cell, row) => {
                return <></>;
            },
        },
        {
            field: "",
            title: "MAY",
            render: (cell, row) => {
                return <></>;
            },
        },
        {
            field: "",
            title: "JUN",
            render: (cell, row) => {
                return <></>;
            },
        },
        {
            field: "",
            title: "JUL",
            render: (cell, row) => {
                return <></>;
            },
        },
        {
            field: "",
            title: "AUG",
            render: (cell, row) => {
                return <></>;
            },
        },
        {
            field: "",
            title: "SEP",
            render: (cell, row) => {
                return <></>;
            },
        },
        {
            field: "",
            title: "OCT",
            render: (cell, row) => {
                return <></>;
            },
        },
        {
            field: "",
            title: "NOV",
            render: (cell, row) => {
                return <></>;
            },
        },
        {
            field: "",
            title: "DEC",
            render: (cell, row) => {
                return <></>;
            },
        },
    ];
    const updateSelectedItem = (item, index) => {
        let list_loc = JSON.parse(JSON.stringify(refineddata));
        list_loc.forEach((item, j) => {
            if (index === j) {
                item.expanded = item.expanded === undefined ? true : !item.expanded;
            }
        });
        setRefinedData(list_loc);
        console.log(list_loc);
        forceUpdate();
    };
    function filterAndSortByYear(data, year) {
        const keys = Object.keys(data)
            .filter((key) => key.includes(year))
            .sort((a, b) => {
                const [monthA] = a.split("-");
                const [monthB] = b.split("-");
                return (
                    DateTime.fromFormat(monthA, "LLL").month -
                    DateTime.fromFormat(monthB, "LLL").month
                );
            });

        return keys.reduce((result, key) => {
            result[key] = data[key];
            return result;
        }, {});
    }
    const getMonthKey = (key) => {
        if (key.includes("to")) {
            const [from, to] = key.split(" to ");

            return from.split("-")[0] + " to " + to.split("-")[0];
        } else {
            return key.split("-")[0];
        }
    };
    const updateRefindedDataByFilter = (val) => {
        let loc = JSON.parse(JSON.stringify(refineddatabk));
        if (val) {

            setFilter((prev) => ({ ...prev, [val]: [] }));
            if (val === 'entity') {
                setRefinedData(loc.filter((i) => { return (i.year === filter.year || filter.year === null) && (filter.form.includes(i.dcfId) || filter.form.length === 0) && (filter.status.includes(i.status) || filter.status.length === 0) }));
            } else if (val === 'form') {
                setRefinedData(loc.filter((i) => { return (i.year === filter.year || filter.year === null) && (filter.entity.includes(getCoverageTextById(i, rawsitelist)) || filter.entity.length === 0) && (filter.status.includes(i.status) || filter.status.length === 0) }));
            } else {
                setRefinedData(loc.filter((i) => { return (i.year === filter.year || filter.year === null) && (filter.entity.includes(getCoverageTextById(i, rawsitelist)) || filter.entity.length === 0) && (filter.form.includes(i.dcfId) || filter.form.length === 0) }));
            }

        } else {
            setRefinedData(loc.filter((i) => { return (i.year === filter.year || filter.year === null) && (filter.entity.includes(getCoverageTextById(i, rawsitelist)) || filter.entity.length === 0) && (filter.form.includes(i.dcfId) || filter.form.length === 0) && (filter.status.includes(i.status) || filter.status.length === 0) }));
        }



    }
    const onFilterChange = (obj, val) => {

        setFilter((prev) => ({ ...prev, [obj]: val }));
    };
    const onSelectedChangeIntial = (obj, val, refloc) => {
        let loc = JSON.parse(JSON.stringify(refloc));
        console.log(val)
        if (obj === 'year') {

            setRefinedData(loc.filter((i) => { return (i.year === val || val === null) && (filter.entity.includes(getCoverageTextById(i, rawsitelist)) || filter.entity.length === 0) && (filter.form.includes(i.dcfId) || filter.form.length === 0) && (filter.status.includes(i.status) || filter.status.length === 0) }));
        }


        setLoading(true);

        setFilter((prev) => ({ ...prev, [obj]: val }));
    };
    const onSelectedChange = (obj, val) => {
        let loc = JSON.parse(JSON.stringify(refineddatabk));
        console.log(val)
        if (obj === 'year') {

            setRefinedData(loc.filter((i) => { return (i.year === val || val === null) && (filter.entity.includes(getCoverageTextById(i, rawsitelist)) || filter.entity.length === 0) && (filter.form.includes(i.dcfId) || filter.form.length === 0) && (filter.status.includes(i.status) || filter.status.length === 0) }));
        }




        setFilter((prev) => ({ ...prev, [obj]: val }));
    };
    const sortStatus = (a, b) => {
        if (filter.status === 0) {
            return a.status - b.status
        }
        else if (filter.status === 1) {
            return b.status - a.status
        } else {
            console.log('sort')
            return true
        }
    }
    const getRoles = (info) => {
        if (info.role !== undefined || info.role !== null) {
            return Object.entries(info.role).filter(i => i[1] === true).map(i => i[0]).join('/ ')
        } else {
            return 'Unknown'
        }
    }
    return (
        <div className="bg-smoke font-lato">
            <div className="col-12">
                <div>
                    <div className="col-12 flex align-items-center" >
                        <span className="text-big-one"> Welcome {login_data.information.empname} !   </span> <span className="ml-1">{`<${login_data.email}>`} </span>   <Tag className="ml-3 p-tag-blue" > {getRoles(login_data.information)} </Tag>
                    </div>
                    <div
                        className="col-12"
                        style={{
                            justifyContent: "center",
                        }}
                    >
                        <label className="text-big-one clr-navy flex fs-16 flex justify-content-start">

                            Your Reviews
                        </label>
                    </div>
<ReadMoreComponent paragraph={`As a Reviewer, your primary role is to ensure the quality and accuracy of the submitted data. You will thoroughly review the data for errors, inconsistencies, and compliance with established guidelines. If you identify any discrepancies or missing information, you will request clarifications or corrections from the Submitter. Finally, you will provide feedback to both the Submitter and Approver regarding the data's overall quality and any required actions.`} />

                    <div className="col-12 grid">
                        <div className="col-6 grid align-items-center">
                            <div className="col-3">
                                <label className="fs-16 fw-5">Select Year</label>
                            </div>
                            <div className="col-8">
                                <Dropdown
                                    optionLabel="title"
                                    optionValue="value"
                                    style={{ width: 200 }}
                                    options={yearoption.sort((a, b) => { return a.value - b.value }, 0)}
                                    value={filter.year}
                                    onChange={(e) => {
                                        onSelectedChange("year", e.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>


                    {load ? <div >
                        <div
                            className="col-12 flex justify-content-between"
                            style={{ flexDirection: "row", background: "#31597530" }}
                        >
                            <div className="col-11 flex" style={{ flexDirection: "row" }}>
                                <div className="col-2">
                                    <div className="grid m-0" >
                                        <div className=" fw-7 fs-16">Reporting Entity    </div>
                                        <div className="ml-1" > <i style={{ background: filter.entity.length !== 0 && 'aliceblue' }} className="material-icons cur-pointer" onClick={(e) => entityref.current.toggle(e)} >{filter.entity.length !== 0 ? 'filter_alt_off' : 'filter_alt'}</i> </div>
                                        <OverlayPanel ref={entityref} style={{ width: 250 }} >

                                            <MultiSelect value={filter.entity} onChange={(e) => onFilterChange('entity', e.value)} options={entityoption} optionLabel="title" optionValue="id"
                                                filterBy="title" filter maxSelectedLabels={3} placeholder="Select Entity" className="w-full md" panelClassName={'hidefilter'} />

                                            <div className="col-12 mt-2 flex justify-content-between ">
                                                <div >
                                                    <Button label='Clear' outlined onClick={() => { updateRefindedDataByFilter('entity') }} />
                                                </div>
                                                <div >
                                                    <Button label='Apply' disabled={filter.entity.length === 0} onClick={() => { updateRefindedDataByFilter() }} />
                                                </div>
                                            </div>
                                        </OverlayPanel>
                                    </div>
                                </div>
                                <div className="col-8">
                                    <div className="grid m-0">
                                        <div className=" fw-7 fs-16">Form </div>
                                        <div className="ml-1" > <i style={{ background: filter.form.length !== 0 && 'aliceblue' }} className="material-icons cur-pointer" onClick={(e) => formref.current.toggle(e)} >{filter.form.length !== 0 ? 'filter_alt_off' : 'filter_alt'}</i> </div>
                                        <OverlayPanel ref={formref} style={{ width: 250 }} >

                                            <MultiSelect value={filter.form} onChange={(e) => onFilterChange('form', e.value)} options={formoption} optionLabel="title" optionValue="id"
                                                filterBy="title" filter maxSelectedLabels={3} placeholder="Select Form" className="w-full md" panelClassName={'hidefilter'} />

                                            <div className="col-12 mt-2 flex justify-content-between ">
                                                <div >
                                                    <Button label='Clear' outlined onClick={() => { updateRefindedDataByFilter('form') }} />
                                                </div>
                                                <div >
                                                    <Button label='Apply' disabled={filter.form.length === 0} onClick={() => { updateRefindedDataByFilter() }} />
                                                </div>
                                            </div>
                                        </OverlayPanel>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="grid m-0">
                                        <div className=" fw-7 fs-16" >Review Status </div>
                                        <div className="ml-1" > <i style={{ background: filter.status.length !== 0 && 'aliceblue' }} className="material-icons cur-pointer" onClick={(e) => statusref.current.toggle(e)} >{filter.status.length !== 0 ? 'filter_alt_off' : 'filter_alt'}</i> </div>
                                        <OverlayPanel ref={statusref} style={{ width: 250 }} >

                                            <MultiSelect value={filter.status} onChange={(e) => onFilterChange('status', e.value)} options={[{ title: 'Review', id: 1 }, { title: 'None', id: 0 }]} optionLabel="title" optionValue="id"
                                                placeholder="Select Status" className="w-full md" panelClassName={'hidefilter'} />

                                            <div className="col-12 mt-2 flex justify-content-between ">
                                                <div >
                                                    <Button label='Clear' outlined onClick={() => { updateRefindedDataByFilter('status') }} />
                                                </div>
                                                <div >
                                                    <Button label='Apply' disabled={filter.status.length === 0} onClick={() => { updateRefindedDataByFilter() }} />
                                                </div>
                                            </div>
                                        </OverlayPanel>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {refineddata.length ?
                            <BlockUI blocked={tempload}>
                                <div style={{ height: 'calc(100vh - 300px)', overflow: "auto" }}>

                                    {
                                        refineddata.filter((x) => x.dp_array.length > 1).map((item, index) => {
                                            console.log(item)
                                            return (
                                                <div >
                                                    <div className="m-1">
                                                        <div className="p-card">
                                                            <div>
                                                                <div
                                                                    className="col-12 flex justify-content-between cur-pointer"
                                                                    onClick={() => {
                                                                        updateSelectedItem(item, index);
                                                                    }}
                                                                    style={{
                                                                        flexDirection: "row",
                                                                        background: item.expanded && 'rgba(18, 52, 75, 0.063)',
                                                                        borderBottom:
                                                                            item.expanded === true
                                                                                ? "1px solid gray"
                                                                                : "unset",
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="col-11 flex"
                                                                        style={{ flexDirection: "row" }}
                                                                    >
                                                                        <div className="col-2">
                                                                            <div className="col-12 fw-7 fs-16 text-three-dot">
                                                                                {getCoverageTextById(item, rawsitelist)}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-8">
                                                                            <div className="col-12 fw-7 fs-16 text-three-dot">
                                                                                {getFormTitle(item)}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-2 ">
                                                                            {item.status === 1 &&
                                                                                <Tag className="status-tag-blue">

                                                                                    Review Pending
                                                                                </Tag>}
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-1 ">
                                                                        {item.expanded === true ? (
                                                                            <i className="material-icons">
                                                                                expand_less
                                                                            </i>
                                                                        ) : (
                                                                            <i className="material-icons">
                                                                                expand_more
                                                                            </i>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                {item.expanded === true && (
                                                                    <div
                                                                        className="col-12 flex "
                                                                        style={{ flexDirection: "row" }}
                                                                    >
                                                                        <div className="col-3 p-0" >
                                                                            <div
                                                                                className="p-card m-1 p-1  flex "
                                                                                style={{
                                                                                    flexDirection: "column",
                                                                                    width: '100%',
                                                                                }}
                                                                            >
                                                                                <div className='flex col-12 p-0 m-1' style={{ flexDirection: 'row', height: item.form_type !== 3 ? 30 : 25, borderBottom: '1px solid gray' }}>

                                                                                    <div className="fw-7 fs-14 flex col-6 p-0 m-1 " >
                                                                                        Datapoint(s)
                                                                                    </div>
                                                                                    <hr
                                                                                        style={{
                                                                                            borderWidth: 2,
                                                                                            margin: 1,
                                                                                        }}
                                                                                    />
                                                                                    <div className="fw-7 fs-14 flex col-6 p-0 m-1 " >
                                                                                        Unit of Measure
                                                                                    </div>
                                                                                </div>
                                                                                {item.dp_array.map((dp, index2) => {
                                                                                    console.log(index2);
                                                                                    if (index2 === 0) {
                                                                                        {
                                                                                            return dp.name.map((x, index3) => {

                                                                                                return (
                                                                                                    <div>
                                                                                                        <div className=" justify-content-center">
                                                                                                            <div className="col-12 flex p-0 m-1" style={{ flexDirection: 'row', height: 30 }}>
                                                                                                                <div
                                                                                                                    className="col-6 fw-4 fs-14 p-0 text-three-dot block m-1 align-items-center cur-pointer"
                                                                                                                    onClick={(e) => { dpnamerefs[index3].current.toggle(e) }}
                                                                                                                >

                                                                                                                    {item.form_type === 2 ? x.label.split(' ^ ')[0] : dpNameTemplate(x)}
                                                                                                                </div>
                                                                                                                <hr
                                                                                                                    style={{
                                                                                                                        borderWidth: 1,
                                                                                                                        margin: 1,
                                                                                                                    }}
                                                                                                                />
                                                                                                                <div
                                                                                                                    className="col-6 fw-4 fs-14 p-0 block m-1 text-three-dot align-items-center"

                                                                                                                >

                                                                                                                    {item.form_type === 2 ? x.label.split(' ^ ')[1] : getDataPointUnit(x, item.dcfId)}
                                                                                                                </div>

                                                                                                            </div>
                                                                                                            <OverlayPanel key={index} ref={(ref) => { dpnamerefs[index3].current = ref }}>
                                                                                                                {dpNameTemplate(x)}
                                                                                                            </OverlayPanel>


                                                                                                            <hr
                                                                                                                style={{
                                                                                                                    borderWidth: 2,
                                                                                                                    margin: 1,
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                );
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                })}

                                                                                <div style={{ marginTop: 3 }}>
                                                                                    <div className="text-right">
                                                                                        <div className="flex align-items-center fs-16 clr-navy justify-content-end"

                                                                                            style={{ height: 30 }}

                                                                                        >
                                                                                            Current Status
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="text-right">
                                                                                        <div className="flex align-items-center fs-16 clr-navy justify-content-end"

                                                                                            style={{ height: 30 }}

                                                                                        >
                                                                                            Remarks (if any )
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="text-right">
                                                                                        <div className="flex align-items-center fs-16 clr-navy justify-content-end"

                                                                                            style={{ height: 30 }}

                                                                                        >
                                                                                            Action
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="text-right">
                                                                                        <div className="flex align-items-center fs-16 clr-navy justify-content-end"

                                                                                            style={{ height: 30 }}

                                                                                        >
                                                                                            Assignee(s)
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-9 p-0" >
                                                                            <div
                                                                                style={{
                                                                                    flexDirection: "row",
                                                                                    overflowX: "auto",
                                                                                    display: "-webkit-box", justifyContent: 'space-between'
                                                                                }}
                                                                            >
                                                                                {item.dp_array.map((dp, index2) => {
                                                                                    if (index2 !== 0) {
                                                                                        {
                                                                                            return Object.entries(dp).map((x) => {
                                                                                                console.log(x);
                                                                                                return (
                                                                                                    <div
                                                                                                        className="p-card m-1 p-1  flex"
                                                                                                        style={{
                                                                                                            flexDirection: "column",
                                                                                                            width: 180,
                                                                                                        }}
                                                                                                    >
                                                                                                        <div className="status-tag-gray flex justify-content-center p-0 align-items-center m-1" style={{ height: 30, fontSize: 14, fontWeight: 700 }}>
                                                                                                            {getMonthKey(x[0])}
                                                                                                        </div>
                                                                                                        {x[1].map((z) => {
                                                                                                            console.log(z, item.tier2_id)
                                                                                                            if ((z.form_type === 1 || z.form_type === 2) && (z.dp !== undefined ? z.dp !== null : true)) {


                                                                                                                return (
                                                                                                                    <div>
                                                                                                                        <div className="">
                                                                                                                            <div
                                                                                                                                className="fw-5 fs-14 flex m-1 justify-content-center align-items-center"
                                                                                                                                style={{ height: 30 }}
                                                                                                                            >
                                                                                                                                {z.current}
                                                                                                                                {z.category === 2 && z.diff !== null &&
                                                                                                                                    <label className="flex ml-2">
                                                                                                                                        (
                                                                                                                                        <span className="flex align-items-center" style={{ marginLeft: 1, marginRight: 1 }}>
                                                                                                                                            {z.diff ?
                                                                                                                                                <>
                                                                                                                                                    <small style={{ color: 'green' }}>{z.percentage}</small>
                                                                                                                                                    <i
                                                                                                                                                        className="material-icons"
                                                                                                                                                        style={{
                                                                                                                                                            color: z.current
                                                                                                                                                                ? "green"
                                                                                                                                                                : "white",
                                                                                                                                                            fontSize: 14,
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        arrow_downward
                                                                                                                                                    </i>
                                                                                                                                                </>
                                                                                                                                                :
                                                                                                                                                <>
                                                                                                                                                    <small style={{ color: 'red' }}>{z.percentage}</small>
                                                                                                                                                    <i
                                                                                                                                                        className="material-icons"
                                                                                                                                                        style={{
                                                                                                                                                            color: z.current
                                                                                                                                                                ? "red"
                                                                                                                                                                : "white",
                                                                                                                                                            fontSize: 14,
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        arrow_upward
                                                                                                                                                    </i>
                                                                                                                                                </>
                                                                                                                                            }
                                                                                                                                        </span>
                                                                                                                                        )
                                                                                                                                    </label>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <hr
                                                                                                                            style={{
                                                                                                                                borderWidth: 2,
                                                                                                                                margin: 1,
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                );
                                                                                                            }
                                                                                                        })}

                                                                                                        {x[1].length !== 0 && x[1][0].id !== undefined
                                                                                                            ? (
                                                                                                                <div style={{ marginTop: 3 }}>
                                                                                                                    <div className="flex justify-content-center" style={{ height: 30 }}>
                                                                                                                        <Tag
                                                                                                                            className={((x[1][0].status === null || x[1][0].status === 0) ? 'status-tag-red'
                                                                                                                                : x[1][0].status === 1 ? 'status-tag-orange'
                                                                                                                                    : (x[1][0].status === 2 || x[1][0].status === 3) ? 'status-tag-blue'
                                                                                                                                        : x[1][0].status === 3 ? "status-tag-green" : "status-tag-green") + ' flex align-items-center'
                                                                                                                            } >


                                                                                                                            {x[1][0].status === null ? 'Pending Submissions' : x[1][0].status === 0 ? 'Drafted' : x[1][0].status === 1 ? 'Requires Resubmissions' : x[1][0].status === 2 ? 'Requires Re-review' : x[1][0].status === 3 ? 'Pending Review' : x[1][0].status === 4 ? 'Pending Approval' : 'Approved'}
                                                                                                                        </Tag>
                                                                                                                    </div>
                                                                                                                    {x[1][0].remarks !== null ?
                                                                                                                        <div className="flex justify-content-center">
                                                                                                                            <div
                                                                                                                                className="cur-pointer text-underline flex align-items-center"
                                                                                                                                style={{ height: 30 }}
                                                                                                                                onClick={() => {
                                                                                                                                    setRemarksData(x[1][0].remarks)
                                                                                                                                    setRemarksDialog(true)
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                View Remarks
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        <div className="flex justify-content-center">
                                                                                                                            <div
                                                                                                                                style={{ height: 30 }}
                                                                                                                            >

                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    }
                                                                                                                    {(x[1][0].status === 2 || x[1][0].status === 3 || x[1][0].status === 4 || x[1][0].status === 5) ?
                                                                                                                        <div className="flex justify-content-center">
                                                                                                                            <div
                                                                                                                                className=" fw-5 fs-16 col-12 clr-navy flex justify-content-evenly align-items-center"
                                                                                                                                style={{ height: 30 }}

                                                                                                                            >
                                                                                                                                <div className="cur-pointer text-underline" onClick={() => {
                                                                                                                                    window.open(
                                                                                                                                        window.location
                                                                                                                                            .origin +
                                                                                                                                        "/data_input_reviewer/" +
                                                                                                                                        item.dcfId +
                                                                                                                                        "/" +
                                                                                                                                        x[1][0].id
                                                                                                                                    );
                                                                                                                                }}> View   </div>
                                                                                                                                <div className="cur-pointer text-underline"
                                                                                                                                    onClick={() => {
                                                                                                                                        if (x[1][0].status === 2) {
                                                                                                                                            window.open(
                                                                                                                                                window.location
                                                                                                                                                    .origin +
                                                                                                                                                "/data_input_reviewer/" +
                                                                                                                                                item.dcfId +
                                                                                                                                                "/" +
                                                                                                                                                x[1][0].id
                                                                                                                                            );
                                                                                                                                        } else if (x[1][0].status === 3) {
                                                                                                                                            reviewAction(item.dcfId, x[1][0].id, index, index2, item.year)
                                                                                                                                        } else if (x[1][0].status === 4) {
                                                                                                                                            recall(item.dcfId, x[1][0].id, index, index2, item.year)
                                                                                                                                        }

                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    {x[1][0].status === 2 ? 'Re-Submit' : x[1][0].status === 3 ? 'Submit' : (x[1][0].status === 4) ? 'Recall' : ''} </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        :

                                                                                                                        <div className="flex justify-content-center">
                                                                                                                            <div

                                                                                                                                style={{ height: 30 }}

                                                                                                                            >

                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    }

                                                                                                                    <div className="flex justify-content-center">
                                                                                                                        <div class="flex align-items-center"
                                                                                                                            onClick={() => { setAssigneeDialog(true); setAssigneeObj(x[1][0].refobj) }}
                                                                                                                            style={{ height: 30 }}

                                                                                                                        >
                                                                                                                            View Assignees
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            ) :
                                                                                                            (
                                                                                                                <div style={{ marginTop: 3 }}>
                                                                                                                    <div className="flex justify-content-center" style={{ height: 30 }}>
                                                                                                                        <Tag
                                                                                                                            className="status-tag-red"
                                                                                                                        >

                                                                                                                            {'Pending Submission'}
                                                                                                                        </Tag>
                                                                                                                    </div>
                                                                                                                    <div className="flex justify-content-center">
                                                                                                                        <div

                                                                                                                            style={{ height: 30 }}

                                                                                                                        >

                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                    <div className="flex justify-content-center">
                                                                                                                        <div
                                                                                                                            style={{ height: 30 }}
                                                                                                                        >

                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                    <div className="flex justify-content-center">
                                                                                                                        <div class="flex align-items-center"
                                                                                                                            onClick={() => { setAssigneeDialog(true); setAssigneeObj(x[1][0].refobj) }}
                                                                                                                            style={{ height: 30 }}

                                                                                                                        >
                                                                                                                            View Assignees
                                                                                                                        </div>
                                                                                                                    </div>


                                                                                                                </div>
                                                                                                            )


                                                                                                        }
                                                                                                    </div>
                                                                                                );
                                                                                            });
                                                                                        }
                                                                                    }
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })


                                    }

                                </div >
                            </BlockUI>
                            :
                            <div className="col-12 flex justify-content-center">

                                No Data Found
                            </div>
                        }
                    </div> :
                        <div>
                            <div className="col-12 flex justify-content-center">
                                <i className="pi pi-spin pi-spinner fs-24" />
                            </div>
                        </div>
                    }

                </div>
            </div>
            <Dialog visible={remarksdialog} modal
                className="p-fluid"
                onHide={() => {
                    setRemarksDialog(false);
                }} style={{ width: '65%' }} header={'Remarks'} >
                <div>
                    {
                        remarksdata.map((cmnt) => {
                            return (
                                <div className="col-12 grid " style={{ marginBottom: 10, borderBottom: '1px solid gray' }}>
                                    <div className="col-5">
                                        <div>   {cmnt.user_id === login_data.id ? 'You' : getUser(cmnt.user_id)}</div>
                                        <div className="mt-2" > {DateTime.fromISO(cmnt.created_on, { zone: 'utc' }).toLocal().toFormat('dd-LLL-yyyy')} </div>
                                    </div>
                                    <div className="col-5">
                                        {cmnt.remarks}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </Dialog>
            <Dialog visible={assigneedialog} modal
                className="p-fluid"
                onHide={() => {
                    setAssigneeDialog(false);
                }} style={{ width: '65%' }} header={'Assignees '} >
                <div>
                    <Accordion multiple activeIndex={[0]}>
                        <AccordionTab header={"Submitter " + (assigneeobj.reporter_ids ? `(${assigneeobj.reporter_ids.length})` : '')}>
                            {userList.filter(i => assigneeobj.reporter_ids.includes(i.id)).map((i, index) => {
                                return (
                                    <p>{index + 1} . {i.information.empname } </p>
                                )
                            }
                            )

                            }
                        </AccordionTab>
                        {assigneeobj.reviewer_ids && assigneeobj.reviewer_ids.length !== 0 &&
                            <AccordionTab header={"Reviewer " + (assigneeobj.reviewer_ids ? `(${assigneeobj.reviewer_ids.length})` : '')}>
                                {userList.filter(i => assigneeobj.reviewer_ids.includes(i.id)).map((i, index) => {
                                    return (
                                        <p>{index + 1} . {i.information.empname} </p>
                                    )
                                }
                                )

                                }
                            </AccordionTab>}
                        <AccordionTab header={"Approver " + (assigneeobj.approver_ids ? `(${assigneeobj.approver_ids.length})` : '')}>
                            {userList.filter(i => assigneeobj.approver_ids.includes(i.id)).map((i, index) => {
                                console.log(i)
                                return (
                                    <p>{index + 1} . {i.information.empname ? i.information.empname : 'Admin'} </p>
                                )
                            }
                            )

                            }
                        </AccordionTab>
                    </Accordion>
                </div>
            </Dialog>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(QuantitativeReviewerOverall, comparisonFn);

//table
// refineddata.map((item, index) => {
//     return (
//         <div>
//             <div className="m-1">
//                 <div className="p-card" >
//                     <div  >
//                         <div className="col-12 flex justify-content-between" onClick={() => { updateSelectedItem(item, index) }} style={{ flexDirection: 'row', borderBottom: item.expanded === true ? '1px solid gray' : 'unset' }}>
//                             <div className="col-11 flex" style={{ flexDirection: 'row' }} >
//                                 <div className="col-2">

//                                     <div className="col-12 fw-7 fs-16 text-three-dot">{getCoverageTextById(item, rawsitelist)}</div>
//                                 </div>
//                                 <div className="col-8" >

//                                     <div className="col-12 fw-7 fs-16 text-three-dot">{getFormTitle(item)}</div>
//                                 </div>

//                             </div>
//                             <div className="col-1">
//                                 <div className="col-1 "></div>
//                                 <div className="col-1 ">{item.expanded === true ? <i className="material-icons">expand_less</i> : <i className="material-icons">expand_more</i>}</div>
//                             </div>
//                         </div>
//                         {item.expanded === true &&
//                             <div className="col-12 flex justify-content-between" style={{ flexDirection: 'row', overflow: 'auto' }}>

//                                 <table className='col-12'>
//                                     <tr  >

//                                         <th colSpan={2} className="text-center fw-7 fs-18" >Variable Units</th>
//                                         <th colSpan={2} className="text-center fw-7 fs-18" >Jan</th>
//                                         <th colSpan={2} className="text-center fw-7 fs-18" >Feb</th>
//                                         <th colSpan={2} className="text-center fw-7 fs-18" >Mar</th>
//                                         <th colSpan={2} className="text-center fw-7 fs-18" >Apr</th>
//                                         <th colSpan={2} className="text-center fw-7 fs-18" >May</th>
//                                         <th colSpan={2} className="text-center fw-7 fs-18" >Jun</th>
//                                         <th colSpan={2} className="text-center fw-7 fs-18" >Jul</th>
//                                         <th colSpan={2} className="text-center fw-7 fs-18"  >Aug</th>
//                                         <th colSpan={2} className="text-center fw-7 fs-18" >Sep</th>
//                                         <th colSpan={2} className="text-center fw-7 fs-18" >Oct</th>
//                                         <th colSpan={2} className="text-center fw-7 fs-18" >Nov</th>
//                                         <th colSpan={2} className="text-center fw-7 fs-18" >Dec</th>
//                                     </tr>
//                                     {item.dp_array.map((dp, index2) => {
//                                         console.log(item.dp_array2[index2])
//                                         return (
//                                             <tr>
//                                                 <td colSpan={2} className=" fw-7 fs-16 text-three-dot">{dpNameTemplate(dp)}</td>
//                                                 {Object.values(item.dp_array2[index2]).map((y) => {
//                                                     console.log(y.col)
//                                                     return (
//                                                         <td colSpan={y.col * 2} style={{ textDecoration: y.id && 'underline' }} onClick={() => { y.id && navigate.push({ pathname: '/data_input_reviewer/' + item.dcfId + ' ^ ' + y.id }) }} className="fw-5 fs-14 text-center">{y.current}</td>
//                                                     )
//                                                 })

//                                                 }
//                                             </tr>
//                                         )
//                                     })

//                                     }
//                                     <tr>
//                                         <td colSpan={2} className="text-center fw-7 fs-16 ">Status</td>

//                                     </tr>

//                                 </table>
//                             </div>

//                         }
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// })
